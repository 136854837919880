import { IAuth } from '../types/CognitoAuth';

export const checkIfHasSub = (auth: IAuth) =>
  auth && auth.data && auth.data.attributes && auth.data.attributes.sub;

export const checkIfHasSignInSub = (auth: IAuth) =>
  auth &&
  auth.data &&
  auth.data.signInUserSession &&
  auth.data.signInUserSession.accessToken['payload'];
