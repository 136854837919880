import { GetTribeByIdDto } from './../types/Tribe'
import {
  Tribe,
  TribeStaffed,
  CreateTribeDto,
  UpdateTribeDto,
} from '../types/Tribe'
import { timeoffApi } from './timeoff.api'
import { BaseGetForTablePayload } from '../types/Common'

const tribeEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getAllTribes: build.query<Tribe[], BaseGetForTablePayload>({
      query: payload =>
        `/tribe/${payload.companyId}?pageSize=10&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&search=${payload.searchQuery}`,
      providesTags: ['TribeData'],
    }),
    getAllTribesStaffed: build.query<TribeStaffed[], BaseGetForTablePayload>({
      query: payload =>
        `/tribe/${payload.companyId}/staffed?pageSize=10&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&search=${payload.searchQuery}`,
      providesTags: ['TribeData'],
    }),
    getAllTribesOrg: build.query<any[], BaseGetForTablePayload>({
      query: payload =>
        `/tribe/${payload.companyId}/tribeorg?pageSize=10&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&search=${payload.searchQuery}`,
      providesTags: ['TribeOrgData'],
    }),
    getTribeById: build.query<Tribe, GetTribeByIdDto>({
      query: data => `/tribe/${data.tribeId}/${data.companyId}`,
      providesTags: ['TribeData'],
    }),
    createTribe: build.mutation<Tribe, CreateTribeDto>({
      query: data => ({
        url: `/tribe/${data.companyId}`,
        method: 'POST',
        body: data,
      }),
    }),
    updateTribe: build.mutation<Tribe, UpdateTribeDto>({
      query: data => ({
        url: `/tribe/${data.id}/${data.companyId}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const {
  useGetAllTribesQuery,
  useGetAllTribesStaffedQuery,
  useGetAllTribesOrgQuery,
  useGetTribeByIdQuery,
  useCreateTribeMutation,
  useUpdateTribeMutation,
  useLazyGetAllTribesQuery,
  useLazyGetAllTribesStaffedQuery,
  useLazyGetAllTribesOrgQuery,
  useLazyGetTribeByIdQuery,
} = tribeEndpoints
