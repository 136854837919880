import { compose, configureStore } from '@reduxjs/toolkit'
import { timeoffApi } from './timeoff.api'

const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = configureStore({
  devTools: composeEnhancers,
  reducer: {
    [timeoffApi.reducerPath]: timeoffApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(timeoffApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
