import React from 'react'
import { User } from '../../types/User'

const Footer = ({ user }: { user: User | undefined }): JSX.Element => {
  return user ? (
    <div className="footer mt-2">
      <div className="container">
        <>
          <ul className="navbar nav nav-pills my-1 d-flex justify-content-between align-items-center">
            <li className="nav-item">
              <p className="fw-normal m-0">
                © All rights reserved. Acklen Avenue.
              </p>
            </li>
            <li className="nav-item">
              Powered by{' '}
              <img src="/images/acklen.png" className="ms-2 acklen-logo" />
            </li>
          </ul>
        </>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Footer
