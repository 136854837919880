import { Role,  CreateGuildDto, UpdateGuildDto, GetGuildByIdDto } from '../types/Roles'
import { timeoffApi } from './timeoff.api'

const roleEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getAllRoles: build.query<Role[], void>({
      query: () => `/role/allroles`,
      providesTags: ['Role'],
    }),
    getGuildById: build.query<Role, GetGuildByIdDto>({
      query: data => `/role/${data.id}`,
      providesTags: ['Role'],
    }),
    createGuild: build.mutation<Role, CreateGuildDto>({
      query: data => ({
        url: `/role/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Role'],
    }),
    updateGuild: build.mutation<Role, UpdateGuildDto>({
      query: data => ({
        url: `/role/update/${data.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Role'],
    }),
  }),
})

export const {
  useGetAllRolesQuery, 
  useLazyGetAllRolesQuery,
  useGetGuildByIdQuery,
  useLazyGetGuildByIdQuery,
  useCreateGuildMutation,
  useUpdateGuildMutation
} = roleEndpoints
