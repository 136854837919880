import React, { createContext } from 'react'
import { FeatureFlagsInterface, features } from './FeatureFlags'

export const featureFlags = createContext({
  isCompanyCreationEnabled: false,
} as FeatureFlagsInterface)

const FeatureFlagsProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <featureFlags.Provider value={features}>{children}</featureFlags.Provider>
  )
}

export default FeatureFlagsProvider
