import React, { useEffect, useState } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router'
import { Team } from '../../types/Team'

interface Props {
  handleTeam: (
    name: string,
    description: string,
    isActive: boolean,
    clientId: number,
    startDate: Date,
    endDate: Date | undefined | null,
  ) => Promise<void>
  teamData?: Team
  tribeId: number
}

export const TeamForm = ({ handleTeam, teamData }: Props) => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [clientId, setClientId] = useState(0)
  const [endDate, setEndDate] = useState<Date | null>()
  const [startDate, setStartDate] = useState<Date>()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (name && startDate) {
      handleTeam(name, description, isActive, clientId, startDate, endDate)
    }
  }

  useEffect(() => {
    if (teamData) {
      setName(teamData.name)
      setDescription(teamData.description)
      setIsActive(teamData.isActive)
      setClientId(teamData.clientId || 0)
      setStartDate(teamData.startDate)
      setEndDate(teamData.endDate)
    }
  }, [teamData])

  const parseDateAndSetToStartOfDay = (dateLike: any) => {
    if (!dateLike) return null
    const mDate = moment(dateLike)

    return mDate.startOf('day').toDate()
  }

  const handleOnBack = () => {
    navigate(-1)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group py-3">
          <label
            htmlFor="name font-weight-bold timeoff-grey "
            className="login-form-label-style"
          >
            Team Name
          </label>
          <input
            type="text"
            className={`form-control timeoff-input`}
            id="loginNameInput"
            value={name}
            onChange={e => {
              setName(e.target.value)
            }}
            aria-label="login-name-input"
            maxLength={25}
          />
        </div>
        <div className="form-group py-3">
          <label
            htmlFor="name font-weight-bold timeoff-grey "
            className="login-form-label-style"
          >
            Description
          </label>
          <input
            type="text"
            className={`form-control timeoff-input`}
            id="loginNameInput"
            value={description}
            onChange={e => {
              setDescription(e.target.value)
            }}
            aria-label="login-name-input"
            maxLength={50}
          />
        </div>

        <div className="form-group py-3 d-flex justify-content-between">
          <div className="mb-3 form-check hover-dark-blue">
            <input
              type="checkbox"
              className="form-check-input"
              id="rememberMeCheck"
              defaultChecked
              checked={isActive}
              onClick={() => setIsActive(!isActive)}
              role="button"
            />
            <label
              className="form-check-label not-selectable font-weight-bold timeoff-grey remember-me-label cursor-pointer text-decoration-none"
              htmlFor="rememberMeCheck"
            >
              Is Active?
            </label>
          </div>
        </div>
        <div className="d-flex row form-group mb-4">
          <div className="col-6">
            <label htmlFor="timeoffStartDate">
              Start date <span className="text-danger">*</span>
            </label>
            <DatePicker
              selected={parseDateAndSetToStartOfDay(startDate)}
              maxDate={endDate}
              className={`form-control timeoff-input`}
              placeholderText={'Select a date'}
              onChange={date => {
                if (date) {
                  setStartDate(date)
                }
              }}
            />
          </div>
          <div className="col-6">
            <label htmlFor="timeoffEndDate">End date</label>
            <DatePicker
              selected={parseDateAndSetToStartOfDay(endDate)}
              minDate={startDate}
              className={`form-control timeoff-input`}
              placeholderText={'Select a date'}
              isClearable
              onChange={date => {
                if (date) {
                  setEndDate(date)
                } else {
                  setEndDate(null)
                }
              }}
            />
          </div>
        </div>
        <div className="py-2 px-lg-4 mx-lg-4 d-flex justify-content-center">
          <button
            className="btn btn-secondary me-4 px-4"
            onClick={handleOnBack}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bg-timeoff-blue py-1 py-md-2 shadow-box"
            style={{ fontWeight: '500' }}
            disabled={!name || !startDate}
            aria-label="login-submit-button"
            onClick={handleOnBack}
          >
            Save
          </button>
        </div>
      </form>
    </>
  )
}
