import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import TimeOffForm from '../../components/TimeOffForm/TimeOffForm'
import { useGetAllUsersQuery } from '../../redux/user.endpoints'
import { User } from '../../types/User'
import { useNavigate } from 'react-router'
import { useCreateTimeOffMutation } from '../../redux/timeoff.endpoints'
import { TimeOffDto } from '../../types/TimeOff' 
import { toast } from 'react-toastify'
import moment from 'moment'

interface Props {
  user: User
}

const AddTimeOffs = ({ user }: Props) => {
  const [timeOffs, setTimeOffs] = useState<any[]>([])
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const navigate = useNavigate()

  const { data: usersData, refetch } = useGetAllUsersQuery({
    companyId: user.company.id,
    pageSize: 0,
    pageNumber: 0,
    sortBy: 'firstName',
    searchQuery: '',
    sortType: 'ASC',
  })
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (usersData) setUsers(usersData)
  }, [usersData])

  const addNewTimeOffCard = () => {
    setTimeOffs(oldTimeoffs => [
      ...oldTimeoffs,
      {
        index: timeOffs.length + 1,
        name: '',
        description: '',
        approved: false,
        userId: user.id,
        startDate: null,
        endDate: null,
      },
    ])
  }

  const handleOnChange = (
    index: number,
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const changedTimeOff = timeOffs
    changedTimeOff[index][event.target.name] = event.target.value
    setTimeOffs(changedTimeOff)
  }

  const handleDateOnChange = (index: number, fieldName: string, date: Date) => {
    if (fieldName === 'startDate') setStartDate(date)
    else setEndDate(date)

    const changedTimeOff = timeOffs
    changedTimeOff[index][fieldName] = date
    setTimeOffs(changedTimeOff)
  }

  const handleUserSelectOnChange = (index: number, value: number) => {
    const changedTimeOff = timeOffs
    changedTimeOff[index]['userId'] = value
    setTimeOffs(changedTimeOff)
  }

  const removeTimeOff = (index: number) => {
    let changedTimeOff = timeOffs
    const valueToRemove = [changedTimeOff[index]]
    changedTimeOff = changedTimeOff.filter(
      element => !valueToRemove.includes(element),
    )
    setTimeOffs(changedTimeOff)
  }

  const [createTimeOff, createTimeOffResult] = useCreateTimeOffMutation()

  const submitCreateTimeOff = () => {
    const array: TimeOffDto[] = [] 

    if (
      timeOffs.filter(
        item =>
          parseInt(item.userId) === 0 ||
          item.startDate === null ||
          item.endDate === null,
      ).length > 0
    ) {
      toast.error(
        'Time Offs are not completed, please check if there is an empty field.',
        {
          toastId: 'create-timeoff-error',
        },
      )
    } else {
      let isOverlapping = false

      timeOffs.forEach(element => {
        const e1start = new Date(element.startDate).getTime()
        const e1end = new Date(element.endDate).getTime()

        timeOffs.forEach(timeoff => {
          if (
            timeoff.index !== element.index &&
            timeoff.userId === element.userId
          ) {
            const e2start = timeoff.startDate.getTime()
            const e2end = timeoff.endDate.getTime()

            if (
              (e1start >= e2start && e1start <= e2end) ||
              (e2start >= e1start && e2start <= e1end)
            ) {
              toast.warning(
                `Two Time Offs are overlapping, ${element.startDate.toLocaleDateString()}-${element.endDate.toLocaleDateString()} 
                  and ${timeoff.startDate.toLocaleDateString()}-${timeoff.endDate.toLocaleDateString()}`,
                {
                  toastId: 'create-timeoff-warning',
                },
              )
              isOverlapping = true
            }
          }
        })
      })

      if (
        timeOffs.filter(item => moment(item.startDate) > moment(item.endDate))
          .length > 0
      ) {
        toast.error('The date ranges are incorrect, please check them.', {
          toastId: 'create-timeoff-error',
        })
      } else if (!isOverlapping) {
        timeOffs.forEach(object => {
          array.push({
            name: object.name,
            description: object.description,
            approved: object.approved,
            userId: object.userId,
            startDate: moment(object.startDate).format('YYYY-MM-DD'), 
            endDate: moment(object.endDate).format('YYYY-MM-DD'), 
          })
        })

        try {
          createTimeOff({userIdWhoCreatedTimeoff: user.id, timeoffs: array })
        } catch (err) {
          toast.error('Something went wrong', {
            toastId: 'create-timeoff-error',
          })
        }
      }
    }
  }

  useEffect(() => {
    if (createTimeOffResult.isError) {
      toast.error(
        'Server Error, something happen when trying to create Time Offs',
        {
          toastId: 'create-timeoff-error',
        },
      )
    } else if (timeOffs.length > 0) {
      if (createTimeOffResult.data?.filter(item => item.status).length === 0) {
        setTimeOffs([])
        toast.success('Time Offs created succesfully', {
          toastId: 'create-timeoff-success',
        })
        navigate('/')
      } else {
        createTimeOffResult.data?.forEach((element, index) => {
          if (element.response) {
            toast.warning(element.response.error, {
              toastId: 'create-timeoff-warning' + index,
            })
          }
        })
        removeTimes(createTimeOffResult.data || [])
      }
    }
  }, [createTimeOffResult])

  const removeTimes = (responses: any[]) => {
    const indexes: number[] = []

    if (responses) {
      responses.forEach(element => {
        if (!element.status) indexes.push(responses.indexOf(element))
      })
    }

    const newTimeOffs = timeOffs.filter(
      element =>
        indexes.filter(value => value === element.index - 1).length === 0,
    )

    setTimeOffs(newTimeOffs)
  }

  return (
    <div className="container">
      <div className="my-5">
        <h1>Add New Time Off</h1>
        <p>
          (<span className="text-danger">*</span>)<strong>Required</strong>
        </p>
      </div>
      <div className="timeoffs d-flex">
        {timeOffs.map((timeOff, index) => {
          return (
            <div key={index} className="timeoff-card me-2">
              <TimeOffForm
                index={index}
                users={user.isAdmin ? users : [user]}
                handleOnChange={handleOnChange}
                handleDateOnChange={handleDateOnChange}
                timeOffs={timeOffs}
                startDate={startDate}
                endDate={endDate}
                removeTimeOff={removeTimeOff}
                handleUserSelectOnChange={handleUserSelectOnChange}
                currentUser={user}
              />
            </div>
          )
        })}
        <div className="card-button d-flex flex-column">
          <div className="m-auto text-center">
            <div className="add-button-circle">
              <FontAwesomeIcon
                onClick={() => addNewTimeOffCard()}
                size="2x"
                className="circle-plus-button pointer-cursor"
                icon={faCirclePlus}
              />
            </div>
            <div className="card-button-text">
              <span>Add New Time Off</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 text-center mt-4">
        <button
          onClick={() => navigate('/')}
          className="btn btn-secondary me-2"
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => submitCreateTimeOff()}
          disabled={timeOffs.length == 0}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default AddTimeOffs
