import React from 'react'
import { Member } from '../../../types/Tribe'
import { useNavigate } from 'react-router-dom'

interface Props {
  role: string
  leaders: Array<Member>
  guildName: string
}

const ProjectLeadershipContainer = ({ role, leaders, guildName }: Props) => {
  const navigate = useNavigate()

  const handleMemberClick = (memberId: number) => {
    if (memberId !== undefined && memberId !== -1) {
      navigate(`/members/${memberId}`)
    }
  }

  return (
    <div className="tribe-leadership-title-container">
      <div className="leadership-title">{guildName}</div>
      {leaders.map((leader, index) => {
        return (
          <div
            key={index}
            className="lead-name"
            style={{ cursor: 'pointer' }}
            onClick={() => handleMemberClick(leader.id)}
          >
            {`${leader.firstName} ${leader.lastName}`}{' '}
          </div>
        )
      })}
    </div>
  )
}

export default ProjectLeadershipContainer
