import { Auth } from 'aws-amplify'
import { IAuth } from '../types/CognitoAuth'
import { NavigateFunction } from 'react-router'

export const getCurrentUser = async () => {
  return await Auth.currentAuthenticatedUser()
}

export const checkSessionStillActive = async (
  auth: IAuth,
  setAuthData: (data: IAuth | null) => void,
  navigate: NavigateFunction,
) => {
  try {
    if (auth && auth.data) {
      await Auth.currentSession()
      return true
    }
    return false
  } catch (err) {
    setAuthData(null)
    await Auth.signOut()
    navigate('/')
    return false
  }
}