import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import isEmail from 'validator/lib/isEmail'
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider'

export const PasswordRecovery = () => {
  const { passwordRecovery } = useContext(authContext)
  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (email) {
      setHasError(false)
      await passwordRecovery(email)
      setCodeSent(true)
    } else {
      setHasError(true)
    }
  }

  return (
    <>
      <Helmet>
        <title>Time Off - Password Recovery</title>
      </Helmet>
      <main className="container-fluid">
        <div className="row full-height">
          <div className="px-3 px-md-4 px-lg-0 col-12 d-flex align-items-center justify-content-center bg-timeoff-white">
            {!codeSent ? (
              <form className="min-w-15" onSubmit={handleSubmit}>
                <div className="col-12 my-3 px-4 d-flex flex-row text-center align-items-center justify-content-center">
                  <p className="timeoff-grey forgot-password-description-txt">
                    Forgot your password?
                  </p>
                </div>
                <div className="d-flex flex-column pb-1">
                  <div className="form-group py-1 my-1">
                    <label
                      htmlFor="email font-weight-bold timeoff-grey "
                      className="login-form-label-style"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className={`form-control timeoff-input ${
                        hasError ? 'error' : ''
                      }`}
                      id="loginEmailInput"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                      aria-label="forgot-password-email-input"
                    />
                  </div>
                </div>
                <div className="py-1 d-flex align-items-center justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block bg-timeoff-blue shadow-box"
                    style={{ fontWeight: '500' }}
                    disabled={!isEmail(email)}
                    aria-label="forgot-password-submit-button"
                  >
                    Send
                  </button>
                </div>
              </form>
            ) : (
              <div className="d-flex h-100 align-items-center justify-content-center flex-column col-lg-10">
                <div className="h-25 my-4 px-5 d-flex col-sm-12 col-lg-8 flex-row text-center align-items-center justify-content-center text-wrap">
                  <p className="timeoff-grey font-14 reset-confirmation-text">
                    We have sent password reset instructions to your email.
                  </p>
                </div>
                <div className="col-sm-12 w-100 col-lg-7 h-25 my-4 px-5 d-flex flex-row text-center align-items-center justify-content-center text-wrap">
                  <p className="timeoff-grey font-weight-bold">
                    Did not receive the email? Check your spam folder or{' '}
                    <button
                      className="btn btn-link px-0 font-weight-bold"
                      onClick={() => setCodeSent(false)}
                    >
                      try again.
                    </button>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  )
}
