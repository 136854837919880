/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CreateCompanyForm } from '../../components/CreateCompanyForm/CreateCompanyForm'
import { useCreateCompanyMutation } from '../../redux/company.endpoints'
import { CreateCompanyDto } from '../../types/Company'
import { BackendError } from '../../types/Errors'

const CreateCompany = () => {
  const navigate = useNavigate()

  const [createCompany, createCompanyResult] = useCreateCompanyMutation()
  const [companyName, setCompanyName] = useState('')
  const handleCreateCompany = async (args: CreateCompanyDto) => {
    setCompanyName(args.companyName)
    createCompany(args)
  }

  useEffect(() => {
    if (createCompanyResult && createCompanyResult.isError) {
      const createCompanyError = createCompanyResult.error as BackendError
      if (createCompanyError.data.error) {
        toast.error(createCompanyError.data.error, {
          toastId: 'create-company-error',
        })  
      } else {
        toast.error(`An error occurred when creating the company`, {
          toastId: 'create-company-error',
        })
      }
      setCompanyName('')
    } else if (createCompanyResult && createCompanyResult.isSuccess) {
      toast.success(
        `Company ${companyName} created successfully! You can now login with your credentials.`,
        {
          toastId: 'create-company-success',
        },
      )
      navigate('/login')
    }
  }, [createCompanyResult])

  return (
    <>
      <Helmet>
        <title>Time Off - Create Company</title>
      </Helmet>
      <main className="container-fluid scrollable">
        <div className={`row ${isDesktop || isTablet ? 'h-100' : ''}`}>
          <div className="col-12 d-flex align-items-center justify-content-center bg-lazuli-white pb-5">
            <CreateCompanyForm
              handleCreateCompany={handleCreateCompany}
              displayErrorBanner={
                createCompanyResult && createCompanyResult.isError
              }
              loading={createCompanyResult && createCompanyResult.isLoading}
            />
          </div>
        </div>
      </main>
    </>
  )
}

export default CreateCompany
