export interface Role {
  id: number
  role: string
  salary: number
  type: string
}

export interface CreateGuildDto {
  role: string
  salary: number
  type: string
}

export interface GetGuildByIdDto {
  id: number
}

export interface UpdateGuildDto {
  id: number
  role: string
  salary: number
  type: string
}

export const AllGuildTypes = {
  ADM: "Agile_Delivery_Manager",
  BA: "Business_Analyst",
  Design: "Designer",
  Developer: "Developer",
  DevOps: "Developer_Operations",
  QA: "Quality_Assurance",
  SM: "Scrum_Master"
} as const

export const AllGuildTribeHeader = {
  ADM: "Agile Delivery Manager",
  BA: "Business Analyst",
  Design: "Design",
  Developer: "Development",
  DevOps: "OPs",
  QA: "QA",
  SM: "Scrum Master"
} as const

export const TeamRoles = {
  [AllGuildTypes.QA]: ['QA Lead'],
  [AllGuildTypes.Design]: ['Design Lead'],
  [AllGuildTypes.Developer]: ['Dev Lead'],
  [AllGuildTypes.DevOps]: ['Ops Backup'],
}

export const LeadershipRoleTypes = [
  AllGuildTypes.SM,
  AllGuildTypes.BA,
  AllGuildTypes.ADM
]
