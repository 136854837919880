import React from 'react'
import ColorGuide from '../ColorGuide/ColorGuide'

interface Props {
  tribeName: string
  tribeUpdate: Date
  guildsInfo: Array<{ name: string; color: string; guildId: number }>
}

const TribeHeaderContainer = ({
  tribeName,
  tribeUpdate,
  guildsInfo,
}: Props) => {
  const sortedGuildsInfo = [...guildsInfo].sort((a, b) =>
    a.name.localeCompare(b.name),
  ) // Sort guildsInfo alphabetically by name

  return (
    <div className="tribe-header-container">
      <div className="tribe-header-left">
        <div className="tribe-logo">
          <div className="letter">{tribeName[0]}</div>
        </div>
        <div className="tribe-title">{tribeName}</div>
      </div>
      <div className="tribe-header-right">
        <div className="tribe-org-tags-container">
          {sortedGuildsInfo.map((guild, index) => (
            <ColorGuide
              key={index}
              style={{ background: `${guild.color}` }}
              text={guild.name}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TribeHeaderContainer
