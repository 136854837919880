import React from 'react'
import './loadingComponent.css'

const LoadingComponent: React.FC = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-container">
        <div className="loading-text">
          LOADING<span className="loading-dots">...</span>
        </div>
      </div>
    </div>
  )
}

export default LoadingComponent
