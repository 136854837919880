import React from 'react'

interface Props {
  style: any
  text: string
}

const ColorGuide = ({ style, text }: Props) => {
  return (
    <div className="tribe-org-header-tag">
      <div className="tag-color" style={style} />
      {text}
    </div>
  )
}

export default ColorGuide
