import { Team, TeamStaffed } from './Team'

export interface Tribe {
  id: number
  name: string
  isActive: boolean
  isDeliveryTribe: boolean
  description: string
  created_at: string
  teams: Team[]
  color?: string
}

export interface TribeStaffed extends Tribe {
  teams: TeamStaffed[]
}

export interface CreateTribeDto {
  name: string
  isActive: boolean
  isDeliveryTribe: boolean
  description: string
  companyId: number
}

export interface GetTribeByIdDto {
  tribeId: number
  companyId: number
}

export interface UpdateTribeDto {
  id: number
  name: string
  isActive: boolean
  isDeliveryTribe: boolean
  description: string
  companyId: number
}

export interface Member {
  id: number
  firstName: string
  lastName: string
  guildId: number
  email: string
  role?: string
}

export function parseTribes(tribes: Tribe[]) {
  return (
    tribes
      ?.map(tribe => tribe.name || '')
      .filter(val => !!val)
      .join(', ') || ''
  )
}

export interface LeadershipGroup {
  "ADM": Array<Member>,
  "BA": Array<Member>,
  "Scrum Master": Array<Member>,
}