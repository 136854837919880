import React from 'react'
import { Link } from 'react-router-dom'
import { User } from '../../types/User'
import Navbar from '../Navbar/Navbar'

const Header = ({ user }: { user: User | undefined }): JSX.Element => {
  return (
    <div className="header">
      <div className="container">
        <Link className="no-decoration text-white header-title" to={user && user.isAdmin ? "/tribeOrg" : "/"}>
          <img src='/images/matrixmanaged-white.png' height='auto' width='150px'/>
        </Link>
        {user && <Navbar isAdmin={user.isAdmin} name={user.firstName} userId={user.id} />}
      </div>
    </div>
  )
}

export default Header
