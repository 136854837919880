import React from 'react'
import { concatClientAndProjectName } from '../../../types/Engagement'
import Tippy from '@tippyjs/react'

interface Props {
  projectNumber: string
  projectName: string
  clientName: string
  teamName: string
  handleTeamClick: (event: React.MouseEvent) => void
  handleEngagementClick: (event: React.MouseEvent) => void
}

const ProjectName = ({
  projectNumber,
  projectName,
  clientName,
  teamName,
  handleTeamClick,
  handleEngagementClick,
}: Props) => {
  return (
    <div className="project-name-container">
      <div className="project-badge">{projectNumber}</div>
      <div className="flex flex-col m-1">
        <Tippy
          content={teamName}
          disabled={teamName && teamName.length > 30 ? false : true}
        >
          <div
            className="team-name"
            style={{
              cursor: teamName ? 'pointer' : 'default',
            }}
            onClick={handleTeamClick}
          >
            {teamName}
          </div>
        </Tippy>
        <Tippy
          content={concatClientAndProjectName(clientName, projectName)}
          disabled={
            concatClientAndProjectName(clientName, projectName).length > 30
              ? false
              : true
          }
        >
          <div
            className="project-name"
            style={{
              cursor: projectName ? 'pointer' : 'default',
            }}
            onClick={handleEngagementClick}
          >
            {concatClientAndProjectName(clientName, projectName)}
          </div>
        </Tippy>
      </div>
    </div>
  )
}

export default ProjectName
