import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ModalContainer from '../../components/ModalContainer/ModalContainer'
import {
  useGetClientByIdQuery,
  useUpdateClientMutation,
} from '../../redux/client.endpoints'
import { Client } from '../../types/Client'
import { User } from '../../types/User'
import { useLazyGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { Engagement } from '../../types/Engagement'
interface Props {
  user: User
}
const ClientDetails = ({ user }: Props) => {
  const [client, setClient] = useState({} as Client)
  const { clientId } = useParams()
  const [isModalOpen, setModalOpen] = useState(false)
  const [editedClientName, setEditedClientName] = useState('')
  const [editedClientDescription, setEditedClientDescription] = useState('')
  const [editedClientIsActive, setEditedClientIsActive] = useState(true)
  const openModal = () => {
    setEditedClientName(client.name)
    setEditedClientDescription(client.description)
    setEditedClientIsActive(client.isActive)
    setModalOpen(true)
  }

  const navigate = useNavigate()

  const handleEngagementClick = (engagementId: number) => {
    navigate(`/engagements/edit/` + engagementId)
  }

  const { data, isLoading } = useGetClientByIdQuery({
    companyId: user.company.id,
    clientId: clientId ? parseInt(clientId) : 0,
  })
  const [engagements, setEngagements] = useState([] as Engagement[])
  const [getEngagements, { data: engagementsData }] =
    useLazyGetAllEngagementsQuery()
  const [updateClient, updateClientResult] = useUpdateClientMutation()
  useEffect(() => {
    setClient(data || ({} as Client))
  }, [isLoading])
  useEffect(() => {
    if (client && client.company) {
      getEngagements({
        companyId: client.company.id,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery: '',
        sortType: 'DESC',
      })
    }
  }, [client])
  useEffect(() => {
    if (updateClientResult.isSuccess) {
      setClient(updateClientResult.data)
      setModalOpen(false)
    } else if (updateClientResult.error) {
      toast.error(
        `An error occurred when updating the client: ${updateClientResult.error}`,
        {
          toastId: 'update-client-error',
        },
      )
    }
  }, [updateClientResult])
  useEffect(() => {
    console.log('engagementsData', engagementsData)
    if (engagementsData) {
      setEngagements([])
      console.log('client.id:', client.id)
      console.log('Engagements data:', engagementsData)
      const currentEngagementsForClient = engagementsData.filter(
        engagement => engagement.client.id === client.id,
      )
      console.log(
        'Filtered engagements for client:',
        currentEngagementsForClient,
      )
      setEngagements(currentEngagementsForClient)
    }
  }, [engagementsData])
  if (isLoading || !client) {
    return <div>Loading...</div>
  }
  return (
    <div className="container pt-4">
      <ModalContainer
        isModalOpen={isModalOpen}
        hideModal={() => setModalOpen(false)}
      >
        <div className="modal-body">
          <div
            onClick={() => setModalOpen(false)}
            className="text-end pe-auto"
            role="button"
          >
            <FontAwesomeIcon icon={faClose} />
          </div>
          <h2 className="text-center">Edit Client</h2>
          <div className="d-grid">
            <label className="my-2">
              Name
              <input
                className="d-block form-control timeoff-input"
                type="text"
                name="client-name"
                value={editedClientName}
                onChange={e => setEditedClientName(e.target.value)}
                maxLength={25}
              />
            </label>
            <label className="my-2">
              Description
              <input
                className="d-block form-control timeoff-input"
                type="text"
                name="client-description"
                value={editedClientDescription}
                onChange={e => setEditedClientDescription(e.target.value)}
              />
            </label>
            <label className="my-2">
              <input
                className="me-2"
                type="checkbox"
                checked={editedClientIsActive}
                onChange={() => setEditedClientIsActive(prev => !prev)}
              />
              Set client as active
            </label>
          </div>
          <div className="action-control text-center">
            <button
              className="btn btn-secondary me-2"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                updateClient({
                  id: clientId ? parseInt(clientId) : 0,
                  name: editedClientName,
                  isActive: editedClientIsActive,
                  description: editedClientDescription,
                  companyId: user.company.id,
                })
              }
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
      </ModalContainer>
      <div className="d-flex justify-content-between">
        <h1>{client.name}&apos;s Details</h1>
        <button className="btn btn-primary" onClick={() => openModal()}>
          Edit client
        </button>
      </div>
      <div className="client-details mt-4">
        <p>
          <strong>Description: </strong>
          {client.description}
        </p>
        <p>
          <strong>Status: </strong>
          {client.isActive ? 'Active' : 'Inactive'}
        </p>
        <p>
          <strong>Created At: </strong>
          {moment(client.created_at).format('MM/DD/YYYY')}
        </p>
        <h2>Engagements</h2>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Status</th>
              <th>Team ID</th>
              <th>Team Code Name</th>
              <th>SOW Start Date</th>
              <th>SOW End Date</th>
            </tr>
          </thead>
          <tbody>
            {engagements &&
              engagements.map((engagement, index) => (
                <tr
                  onClick={() => navigate(`/engagements/${engagement.id}`)}
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{index + 1}</td>
                  <td>{!engagement.archived ? 'Active' : 'Inactive'}</td>
                  <td>{engagement.team.name}</td>
                  <td>{engagement.projectName}</td>
                  <td>{moment(engagement.startDate).format('MM/DD/YYYY')}</td>
                  <td>{moment(engagement.endDate).format('MM/DD/YYYY')}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default ClientDetails
