import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import CognitoContextProvider from './components/CognitoContextProvider/CognitoContextProvider'
import FeatureFlagsProvider from './components/FeatureFlagsContextProvider/FeatureFlagsContextProvider'
import { store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import './auth/Amplify'
import './styles/_globals.scss'
import './css/bootstrap.scss'
import 'bootstrap'
import '@popperjs/core'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CognitoContextProvider>
        <FeatureFlagsProvider>
          <div>
            <App />
          </div>
        </FeatureFlagsProvider>
      </CognitoContextProvider>
    </BrowserRouter>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
