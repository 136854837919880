import { Client } from './Client'
import { Team } from './Team'

export interface Engagement {
  id: number
  projectName: string
  techStack: string[]
  industries: string[]
  weeklyRate: number
  startDate: Date
  endDate: Date
  client: Client
  team: Team
  description: string
  archived: boolean
}

export interface CreateEngagementDto {
  projectName: string
  techStack: string[]
  industries: string[]
  weeklyRate: number
  startDate: Date
  endDate: Date | null
  clientId: number
  teamId: number
  companyId: number
  description: string
}

export interface GetEngagementByIdDto {
  id: number
  companyId: number
}

export interface UpdateEngagementDto extends CreateEngagementDto {
  id: number
}

export interface EngagementTribeOrg {
  id: number
  endDate: string
  projectName: string
  startDate: string
  client?: Client
}

export const concatClientAndProjectName = (
  clientName: string,
  projectName: string,
) => {
  if (!clientName) {
    return projectName
  } else if (!projectName) {
    return clientName
  } else {
    return `${clientName} - ${projectName}`
  }
}
