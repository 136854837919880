import React from 'react'

interface Props {
  style: string
  text: string
  role: string
  memberId: number
  handleMemberClick: (event: React.MouseEvent, memberId: number) => void
}

const ProjectTeamBox = ({
  style,
  text,
  role,
  memberId,
  handleMemberClick,
}: Props) => {
  const handleMemberNameClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (memberId) {
      handleMemberClick(event, memberId)
    }
  }

  return (
    <div className={`${style} member-box text-style`}>
      <div
        className="member-name"
        style={{
          cursor: memberId ? 'pointer' : 'default',
        }}
        onClick={handleMemberNameClick}
      >
        {text}
      </div>
      <div className="badge pt-1">{role}</div>
    </div>
  )
}

export default ProjectTeamBox
