import React, { useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  handleResetPassword: (matches: boolean, password: string) => Promise<void>
  displayErrorBanner: boolean
}

export const ConfirmResetPasswordForm = ({
  handleResetPassword,
  displayErrorBanner,
}: Props) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password && confirmPassword) {
      if(password === confirmPassword){
        handleResetPassword(password === confirmPassword, password)
      }else{
        toast.error(
          'The passwords must match',
          {
            toastId: 'setting-new-password-error',
          },
        )
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group py-3 px-lg-4 mx-lg-4">
          <label
            htmlFor="password font-weight-bold timeoff-grey"
            className="login-form-label-style"
          >
            Password
          </label>
          <input
            type="password"
            className={`form-control timeoff-input ${
              displayErrorBanner ? 'error' : ''
            }`}
            id="loginPasswordInput"
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
            aria-label="reset-password-input"
          />
          <div id="passwordHelp" className="form-text">
            At least 8 characters, an uppercase letter, a number and one special
            character (e.g., ! @ # ?){' '}
          </div>
        </div>
        <div className="form-group py-3 px-lg-4 mx-lg-4">
          <label
            htmlFor="password font-weight-bold timeoff-grey"
            className="login-form-label-style"
          >
            Confirm Password
          </label>
          <input
            type="password"
            className={`form-control timeoff-input ${
              displayErrorBanner ? 'error' : ''
            }`}
            id="loginConfirmPasswordInput"
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value)
            }}
            aria-label="reset-confirm-password-input"
          />
          <div id="confirmPasswordHelp" className="form-text">
            Both passwords must match.
          </div>
        </div>
        <div className="py-3 px-lg-4 mx-lg-4">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bg-timeoff-light-blue py-2 py-md-3 shadow-box"
            style={{ fontWeight: '500' }}
            disabled={
              !confirmPassword ||
              !password ||
              password.length < 8 ||
              confirmPassword.length < 8
            }
            aria-label="reset-password-submit-button"
          >
            Reset Password
          </button>
        </div>
      </form>
    </>
  )
}
