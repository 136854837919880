/* eslint-disable  @typescript-eslint/no-non-null-assertion */

import React, { Suspense, useContext, useEffect, useState } from 'react'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { authContext } from './components/CognitoContextProvider/CognitoContextProvider'
import { checkIfHasSignInSub, checkIfHasSub } from './utils/cognito-utils'
import axios from 'axios'
import { backendUrl } from './utils/env'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import Loader from './components/Loader/Loader'
import Login from './containers/Login/Login'
import { User } from './types/User'
import Home from './containers/Home/Home'
import Header from './components/Header/Header'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CreateCompany from './containers/CreateCompany/CreateCompany'
import TribesView from './containers/Tribes/TribesView'
import GuildView from './containers/Guilds/GuildView'
import CreateTeam from './containers/Teams/TeamCreate'
import TeamEdit from './containers/Teams/TeamEdit'
import ClientsView from './containers/ClientsView/ClientsView'
import ClientDetails from './containers/ClientDetails/ClientDetails'
import CreateEmployee from './containers/CreateEmployee/CreateEmployee'
import EditEmployee from './containers/EditEmployee/EditEmployee'
import EmployeesView from './containers/EmployeesView/EmployeesView'
import { PasswordRecovery } from './containers/PasswordRecovery/PasswordRecovery'
import { ConfirmResetPassword } from './containers/ConfirmResetPassword/ConfirmResetPassword'
import EditTribe from './containers/Tribes/EditTribe'
import AddTimeOffs from './containers/TimeOff/AddTimeOff'
import EngagementsView from './containers/Engagements/Engagements'
import EngagementCreate from './containers/Engagements/EngagementCreate'
import EngagementEdit from './containers/Engagements/EngagementEdit'
import TimeOffReport from './containers/TimeOffReport/TimeOffReport'
import Footer from './components/Footer/Footer'
import TribeOrgPage from './containers/TribeOrg/TribeOrg'
import TeamUtilizationBase from './containers/TeamUtilization/TeamUtilizationBase'
import ReleasePlans from './components/ReleasePlans'

function App() {
  const [loggedProfile, setLoggedProfile] = useState<User | undefined>(
    undefined,
  )
  const [profileLoading, setProfileLoading] = useState(true)
  const navigate = useNavigate()
  const isAdmin = !profileLoading && loggedProfile && loggedProfile.isAdmin

  const { auth, logOut } = useContext(authContext)
  const fetchProfile = async () => {
    const cognitoId = checkIfHasSub(auth)
      ? auth.data!.attributes.sub!
      : checkIfHasSignInSub(auth)
      ? auth.data!.signInUserSession.accessToken.payload.sub
      : ''
    if (cognitoId) {
      try {
        const profile = await axios.get(
          `${backendUrl}/user/byCognitoId/${cognitoId}`,
        )
        setLoggedProfile(profile.data)
      } catch (err) {
        toast.error(
          'There was a problem logging in. Contact an administrator.',
          {
            toastId: 'get-profile-error',
          },
        )
        await logOut()
        setLoggedProfile(undefined)
        setProfileLoading(false)
        navigate('/')
      }
    }
    setProfileLoading(false)
  }

  useEffect(() => {
    if (!auth.loading && auth.data) {
      setProfileLoading(true)
      fetchProfile()
    } else if (!auth.loading && !auth.data) {
      setLoggedProfile(undefined)
      setProfileLoading(false)
    }
  }, [auth])

  return (
    <>
      <div className="main-container">
        <Header user={loggedProfile} />
        <Routes>
          <Route
            path="/password-recovery"
            element={
              <Suspense fallback={<Loader />}>
                <PasswordRecovery />
              </Suspense>
            }
          />
          <Route
            path="/confirm-reset"
            element={
              <Suspense fallback={<Loader />}>
                <ConfirmResetPassword />
              </Suspense>
            }
          />
          {profileLoading && <Route path="*" element={<Loader />} />}
          {!profileLoading && !loggedProfile && (
            <>
              <Route
                path="/login"
                element={
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="/register-company"
                element={
                  <Suspense fallback={<Loader />}>
                    <CreateCompany />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          {isAdmin && (
            <>
              <Route path="/members">
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loader />}>
                      <EmployeesView companyId={loggedProfile.company.id} />
                    </Suspense>
                  }
                />
                <Route
                  path="/members/create"
                  element={
                    <CreateEmployee companyId={loggedProfile.company.id} />
                  }
                />
                <Route
                  path="/members/:userId"
                  element={
                    <EditEmployee companyId={loggedProfile.company.id} />
                  }
                />
              </Route>
              <Route path="/engagements">
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loader />}>
                      <EngagementsView companyId={loggedProfile.company.id} />
                    </Suspense>
                  }
                />
                <Route
                  path="/engagements/create"
                  element={
                    <EngagementCreate companyId={loggedProfile.company.id} />
                  }
                />
                <Route
                  path="/engagements/:engagementId"
                  element={
                    <EngagementEdit companyId={loggedProfile.company.id} />
                  }
                />
              </Route>
              <Route
                path="/tribes"
                element={<TribesView user={loggedProfile} />}
              />
              <Route
                path="/tribes/:tribeId"
                element={<EditTribe user={loggedProfile} />}
              />
              <Route path="/guilds" element={<GuildView />} />
              <Route
                path="/clients"
                element={<ClientsView user={loggedProfile} />}
              />
              <Route
                path="/clients/:clientId"
                element={<ClientDetails user={loggedProfile} />}
              />
              <Route
                path="/tribes/:tribeId/create-team"
                element={
                  <Suspense fallback={<Loader />}>
                    <CreateTeam companyId={loggedProfile.company.id} />
                  </Suspense>
                }
              />
              <Route
                path="/tribe/team/:teamId"
                element={
                  <Suspense fallback={<Loader />}>
                    <TeamEdit user={loggedProfile} />
                  </Suspense>
                }
              />
              <Route
                path="/reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <TimeOffReport
                      profile={loggedProfile}
                      companyId={loggedProfile.company.id}
                    />
                  </Suspense>
                }
              />
              <Route path="/releaseplans" element={<ReleasePlans />} />
              <Route path="/tribeOrg">
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loader />}>
                      <TribeOrgPage
                        profile={loggedProfile}
                        companyId={loggedProfile.company.id}
                      />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="/teamUtilization">
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loader />}>
                      <TeamUtilizationBase
                        profile={loggedProfile}
                        companyId={loggedProfile.company.id}
                      />
                    </Suspense>
                  }
                />
              </Route>
            </>
          )}
          {loggedProfile && (
            <>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Loader />}>
                    <Home profile={loggedProfile} />
                  </Suspense>
                }
              />
              <Route
                path="/add-timeoff"
                element={<AddTimeOffs user={loggedProfile} />}
              />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer user={loggedProfile} />
      <ToastContainer />
    </>
  )
}

export default App
