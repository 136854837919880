import React from 'react'

export const timelineItemRenderer = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  return (
    <div {...getItemProps(item.itemProps)}>
      {/* Commented out to test if it is truly necessary */}
      {/*itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''*/}

      <div
        className="rct-item-content"
        style={{ maxHeight: `${itemContext.dimensions.height}` }}
      >
        {item.title}
      </div>

      {/*itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''*/}
    </div>
  )
}
