import {
  CreateTimeOffDto,
  TimeOff,
  TimeOffDto,
  UsersTimesOff,
  UsersTribeOrg,
} from '../types/TimeOff'
import { User } from '../types/User'
import { timeoffApi } from './timeoff.api'

const timeoffEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getAllTimeoffs: build.query<TimeOff[], string>({
      query: userId => `/timeoff?userId=${userId}`,
      providesTags: ['TimeOffData', 'TimeOffList'],
    }),
    getAllUsersTimesOff: build.query<UsersTimesOff[], string>({
      query: userId => `/timeoff?userId=${userId}`,
      providesTags: ['TimeOffData', 'TimeOffList'],
    }),
    getAllUsersTimesOffReport: build.query<
      UsersTimesOff[],
      {
        userId: string
        clientId: string
        tribeId: string
        teamId: string
        from: string
        to: string
      }
    >({
      query: payload =>
        `/timeoff/report?userId=${payload.userId}&clientId=${payload.clientId}&tribeId=${payload.tribeId}&teamId=${payload.teamId}&from=${payload.from}&to=${payload.to}`,
      providesTags: ['TimeOffData', 'TimeOffList'],
    }),
    getAllUsersTribeOrgReport: build.query<
      UsersTribeOrg[],
      {
        userId: string
        clientId: string
        tribeId: string
        teamId: string
        snapshotDate: string
      }
    >({
      query: payload =>
        `/timeoff/TribeOrg-report?userId=${payload.userId}&clientId=${payload.clientId}&tribeId=${payload.tribeId}&teamId=${payload.teamId}&snapshotDate=${payload.snapshotDate}`,
      providesTags: ['TribeOrg', 'TribeData', 'Allocation'],
    }),
    createTimeOff: build.mutation<any[], CreateTimeOffDto>({
      query: data => ({
        url: `/timeoff`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['TimeOffData'],
    }),
    updateTimeOff: build.mutation<
      UsersTimesOff,
      { updatedTimeOff: TimeOffDto; updatedBy: User }
    >({
      query: data => ({
        url: `/timeoff/${data.updatedTimeOff.id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    archiveTimeOff: build.mutation<
      TimeOff,
      { selectedTimeoff: TimeOff; archivedBy: User }
    >({
      query: data => ({
        url: `/timeoff/archive/${data.selectedTimeoff.id}`,
        method: 'PUT',
        body: data.archivedBy,
      }),
      invalidatesTags: ['TimeOffData'],
    }),
    getIntegrationStatus: build.query<
      { isActive: boolean; name: string },
      any
    >({
      query: integrationName => ({
        url: `/timeoff/integration/status/${integrationName}`,
        method: 'GET',
      }),
      providesTags: ['Integration'],
    }),
    updateIntegrationStatus: build.mutation<
      string,
      { integrationName: string; isActive: boolean }
    >({
      query: data => ({
        url: `/timeoff/integration/status/${data.integrationName}`,
        method: 'PUT',
        body: { isActive: data.isActive },
      }),
      invalidatesTags: ['Integration'],
    }),
  }),
})

export const {
  useCreateTimeOffMutation,
  useGetAllTimeoffsQuery,
  useLazyGetAllTimeoffsQuery,
  useLazyGetAllUsersTimesOffQuery,
  useLazyGetAllUsersTimesOffReportQuery,
  useUpdateTimeOffMutation,
  useArchiveTimeOffMutation,
  useLazyGetAllUsersTribeOrgReportQuery,
  useGetIntegrationStatusQuery,
  useUpdateIntegrationStatusMutation,
} = timeoffEndpoints
