import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useLazyGetTeamByIdQuery,
  useUpdateTeamMutation,
} from '../../redux/team.endpoints'
import { TeamForm } from '../../components/TeamForm/TeamForm'
import { User } from '../../types/User'
import { Team } from '../../types/Team'
import { useGetTribeByIdQuery } from '../../redux/tribe.endpoints'
interface Props {
  user: User
}

const TeamEdit = ({ user }: Props) => {
  const navigate = useNavigate()
  const { teamId } = useParams()
  const [getTeam, getTeamResponse] = useLazyGetTeamByIdQuery()
  const [team, setTeam] = useState<Team>({} as Team)
  const { data: tribeData } = useGetTribeByIdQuery({
    tribeId: team?.tribeId || 0,
    companyId: user.company.id,
  })
  const [editTeam, editTeamResult] = useUpdateTeamMutation()

  useEffect(() => {
    getTeam({
      id: parseInt(teamId || '0'),
      companyId: user.company.id,
    })
  }, [])

  useEffect(() => {
    if (getTeamResponse.data) {
      setTeam(getTeamResponse.data)
    }
    if (getTeamResponse.error) {
      navigate('')
    }
  }, [getTeamResponse])

  useEffect(() => {
    if (editTeamResult && editTeamResult.isSuccess) {
      navigate(`/tribes/${team.tribeId}`)
    } else if (editTeamResult && editTeamResult.error) {
      const error: any = editTeamResult.error
      toast.error(`Error while editing team: ${error.data.error}`)
    }
  }, [editTeamResult])

  const handleEdit = async (
    name: string,
    description: string,
    isActive: boolean,
    clientId: number,
    startDate: Date,
    endDate: Date | undefined | null,
  ) => {
    try {
      const newTeam = {
        id: parseInt(teamId || '0'),
        name,
        description,
        isActive,
        tribeId: team.tribeId,
        tribeName: team.tribe?.name,
        clientId,
        startDate,
        endDate,
      }

      editTeam(newTeam)
    } catch (err: any) {
      toast.error('There was a problem when trying to update Team.')
    }
  }

  return (
    <div className="container">
      <div className="mt-4">
        <h1>{`Edit ${team.name} Team`}</h1>
        <p className="mt-2">
          <strong>Tribe:</strong> {tribeData && tribeData.name}
        </p>
        <TeamForm
          handleTeam={handleEdit}
          teamData={team}
          tribeId={team.tribeId}
        />
      </div>
    </div>
  )
}

export default TeamEdit
