import React from 'react'

interface Props {
  startDate: Date | string
  endDate: Date | string
}

const ProjectDateRange = ({ startDate, endDate }: Props) => {
  return (
    <div className="projects-date-range-container">
      <div className="date-range-start">
        <div className="date-text">
          {' '}
          SOW Start Date: {startDate.toLocaleString().split(',')[0]}
        </div>
      </div>
      <div className="date-range-end">
        <div className="date-text">
          {' '}
          SOW End Date: {endDate.toLocaleString().split(',')[0]}
        </div>
      </div>
    </div>
  )
}

export default ProjectDateRange
