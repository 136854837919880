import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCreateTeamMutation } from '../../redux/team.endpoints'
import { TeamForm } from '../../components/TeamForm/TeamForm'
import { useGetTribeByIdQuery } from '../../redux/tribe.endpoints'
import { Tribe } from '../../types/Tribe'

interface Props {
  companyId: number
}

const CreateTeam = ({ companyId }: Props) => {
  const navigate = useNavigate()
  const { tribeId } = useParams()
  const [createTeam, createTeamResult] = useCreateTeamMutation()
  const { data, isLoading } = useGetTribeByIdQuery({
    tribeId: parseInt(tribeId || '0'),
    companyId,
  })
  const [tribe, setTribe] = useState<Tribe>({
    id: 0,
    name: '',
    description: '',
    isActive: false,
    teams: [],
    created_at: '',
    isDeliveryTribe: false
  })

  useEffect(() => {
    if (data) {
      setTribe(data)
    }
  }, [data])

  useEffect(() => {
    if (createTeamResult && createTeamResult.isSuccess) {
      navigate(`/tribes/${tribe.id}`, { replace: true })
    } else if (createTeamResult && createTeamResult.error) {
      const error: any = createTeamResult.error
      toast.error(`Error creating team: ${error.data.error}`)
    }
  }, [createTeamResult])

  const handleCreate = async (
    name: string,
    description: string,
    isActive: boolean,
    clientId: number,
    startDate: Date,
    endDate: Date | undefined | null,
  ) => {
    try {
      const newTeam = {
        name,
        description,
        isActive,
        companyId,
        tribeId: tribe.id,
        clientId,
        startDate,
        endDate,
      }

      await createTeam(newTeam)
    } catch (err) {
      toast.error('There was a problem when trying to create Team.')
    }
  }

  return (
    <div className="container">
      <div className="mt-4">
        <div className="align-self-start font-30 fw-bold">Add New Team</div>
        <p className="mt-2">
          <strong>Tribe:</strong> { tribe.name }
        </p>
        <p className="mt-4 mb-0">
          <strong>General Information</strong>
        </p>
        <p>
          <span>
            (<span className="text-danger">*</span>)
          </span>
          <strong>Required</strong>
        </p>
      </div>
      <TeamForm handleTeam={handleCreate} tribeId={parseInt(tribeId || '0')} />
    </div>
  )
}

export default CreateTeam
