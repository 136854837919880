import { GetClientByIdDto } from './../types/Client'
import { Client, CreateClientDto, UpdateClientDto } from '../types/Client'
import { timeoffApi } from './timeoff.api'

const clientEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getAllClients: build.query<Client[], string>({
      query: companyId => `/client/${companyId}`,
      providesTags: ['ClientData'],
    }),
    getClientById: build.query<Client, GetClientByIdDto>({
      query: data => `/client/${data.clientId}/${data.companyId}`,
      providesTags: ['ClientData'],
    }),
    createClient: build.mutation<Client, CreateClientDto>({
      query: data => ({
        url: `/client/${data.companyId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ClientData'],
    }),
    updateClient: build.mutation<Client, UpdateClientDto>({
      query: data => ({
        url: `/client/${data.id}/${data.companyId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ClientData'],
    }),
  }),
})

export const {
  useGetAllClientsQuery,
  useGetClientByIdQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
} = clientEndpoints
