/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { IAuth } from '../../types/CognitoAuth'
import { useNavigate } from 'react-router'
import { checkSessionStillActive } from '../../auth/userUtils'
import axios from 'axios'
import { backendUrl } from '../../utils/env'

export const authContext = createContext({
  auth: null as unknown as IAuth,
  setAuthData: null as unknown as (data: IAuth | null) => void,
  signIn: null as unknown as (email: string, password: string) => Promise<any>,
  logOut: null as unknown as () => Promise<any>,
  getCurrentAuthenticatedUser: null as unknown as () => Promise<any>,
  passwordRecovery: null as unknown as (
    email: string,
  ) => Promise<{ hasErrors: boolean; errorMessage: string }>,
  passwordReset: null as unknown as (
    email: string,
    resetCode: string,
    password: string,
  ) => Promise<{ hasErrors: boolean; errorMessage: string }>,
  completePasswordChallenge: null as unknown as (
    user: any,
    password: string,
  ) => Promise<any>,
})

const CognitoContextProvider = ({ children }: { children: JSX.Element }) => {
  const [auth, setAuth] = useState({ loading: true, data: null })
  const navigate = useNavigate()

  useEffect(() => {
    verifyUser()
  }, [])

  const verifyUser = async () => {
    setAuth({ loading: true, data: null })
    try {
      const user = await getCurrentAuthenticatedUser()
      setAuthData(user)
      await checkSessionStillActive(auth, setAuthData, navigate)
    } catch (err) {
      setAuthData({
        loading: false,
        data: null,
      })
    }
  }

  const passwordRecovery = async (email: string) => {
    try {
      await Auth.forgotPassword(email)
    } catch (error) {
      console.error(error)
      return {
        hasErrors: true,
        errorMessage: 'An error occurred while sending the email.',
      }
    }
    return { hasErrors: false, errorMessage: '' }
  }

  const signIn = async (email: string, password: string) => {
    const user = await Auth.signIn(email, password)
    setAuthData(user)
    return user
  }

  const logOut = async () => {
    await Auth.signOut()
    setAuthData(null)
  }

  const getCurrentAuthenticatedUser = async () => {
    return await Auth.currentAuthenticatedUser()
  }

  const setAuthData = (data: any) => {
    setAuth({ loading: false, data: data })
  }

  const completePasswordChallenge = async (user: any, password: string) => {
    try {
      await Auth.completeNewPassword(user, password)
      await axios.post(`${backendUrl}/user/verifyUser/${user.username}`)
      await Auth.signOut()
      return { hasErrors: false, errorMessage: '' }
    } catch (err: any) {
      return { hasErrors: true, errorMessage: err.message }
    }
  }

  const passwordReset = async (
    email: string,
    resetCode: string,
    password: string,
  ): Promise<{ hasErrors: boolean; errorMessage: string }> => {
    try {
      await Auth.forgotPasswordSubmit(email, resetCode, password)
      const user = await Auth.signIn(email, password)
      setAuthData(user)
      return { hasErrors: false, errorMessage: '' }
    } catch (err: any) {
      if (err.name === 'CodeMismatchException') {
        await passwordRecovery(email)
        return {
          hasErrors: true,
          errorMessage:
            'Reset link expired, a new link has been sent to your email.',
        }
      } else if (err.name === 'InvalidPasswordException') {
        return { hasErrors: true, errorMessage: err.message }
      }
      console.error(err)
      return {
        hasErrors: true,
        errorMessage:
          'An error occurred when attempting to reset your password, please try again later.',
      }
    }
  }

  return (
    <authContext.Provider
      value={{
        auth,
        setAuthData,
        signIn,
        logOut,
        getCurrentAuthenticatedUser,
        passwordRecovery,
        passwordReset,
        completePasswordChallenge,
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export default CognitoContextProvider
