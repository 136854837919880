import React, { useEffect, useState } from 'react'
import '../../css/releasePlans.scss'
import { backendUrl } from '../../utils/env'

const ReleasePlans: React.FC = () => {
  const [boards, setBoards] = useState([])
  const [selectedBoard, setSelectedBoard] = useState('')
  const onSubmit = async () => {
    if (!selectedBoard) return
    const response = await fetch(
      `${backendUrl}/engagement/releaseplan/${selectedBoard}`,
    )
    const arrayBuffer = await response.arrayBuffer()
    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const downButton = document.createElement('a')
    downButton.setAttribute('href', url)
    downButton.setAttribute('download', `${selectedBoard}.xlsx`)
    document.body.appendChild(downButton)
    downButton.click()
    document.body.removeChild(downButton)
    window.URL.revokeObjectURL(url)
  }

  useEffect(() => {
    const fetchBoards = async () => {
      const response = await fetch(`${backendUrl}/engagement/boards`)
      const boards = await response.json()
      setBoards(boards)
    }

    fetchBoards()
  }, [])

  const onSelectBoard = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBoard(e.target.value)
  }
  return (
    <div className="release-plans">
      <div className="container">
        <h1 className="title">Release Plan</h1>
        <div className="select-container">
          <select className="select-board" onChange={onSelectBoard}>
            <option value="">Select Board</option>
            {boards.length &&
              boards.map((board: { id: string; name: string }) => (
                <option key={board.id} value={board.name}>
                  {board.name}
                </option>
              ))}
          </select>
        </div>
        <button className="generate-button" onClick={onSubmit}>
          Generate Release Plan
        </button>
      </div>{' '}
    </div>
  )
}

export default ReleasePlans
