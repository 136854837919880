import {
  BaseGetActiveInactiveCountResponse,
  BaseGetForTablePayload,
} from '../types/Common'
import { CreateUserDto, EditUserDto, User } from '../types/User'
import { timeoffApi } from './timeoff.api'

const userEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getAllUsers: build.query<User[], BaseGetForTablePayload>({
      query: payload =>
        `/user?pageSize=10&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&search=${payload.searchQuery}&companyId=${payload.companyId}`,
      providesTags: ['Users'],
    }),
    getUsersCount: build.query<
      BaseGetActiveInactiveCountResponse,
      { search: string }
    >({
      query: payload => `/user/count?search=${payload.search}`,
      providesTags: ['Users'],
    }),
    getUserById: build.query<User, string>({
      query: id => `/user/byId/${id}`,
      providesTags: ['UserData'],
    }),
    getAuthenticatedUserProfile: build.query<User, string>({
      query: id => `/user/byCognitoId/${id}`,
      providesTags: ['LoggedInUser'],
    }),
    createUser: build.mutation<User, CreateUserDto>({
      query: data => ({
        url: `/user`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    editUser: build.mutation<boolean, EditUserDto>({
      query: data => ({
        url: `/user`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Users', 'UserData', 'LoggedInUser', 'TribeOrgData'],
    }),
  }),
})

export const {
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useGetUsersCountQuery,
  useGetUserByIdQuery,
  useGetAuthenticatedUserProfileQuery,
  useCreateUserMutation,
  useEditUserMutation,
} = userEndpoints
