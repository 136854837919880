import React, { useEffect, useState, forwardRef } from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import AverageBenchCostTimeline, {
  TeamUtilizationTimelineGroup,
  TeamUtilizationTimelineItemBase,
} from '../../components/AverageBenchCostTimeline/AverageBenchCostTimeline'
import { User } from '../../types/User'
import { useLazyGetAllTribesStaffedQuery } from '../../redux/tribe.endpoints'
import moment from 'moment'
import { SelectOption } from '../../types/SelectOption'
import { Tribe, TribeStaffed } from '../../types/Tribe'
import CustomDateInput from '../../components/CustomDateInput/CustomDateInput'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role } from '../../types/Roles'
import { UserTeam } from '../../types/UserTeam'
import { useLazyGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { Team, TeamStaffed } from '../../types/Team'
import { dayInUnixTime } from '../../utils/general'

interface Props {
  profile: User
  companyId: number
  generalTitle: string
  dashboardTitle: string
  totalOrganizationTitle: string
  totalPerTribeNumberTitle: string
  tableTitle: string
  selectedKey: number
}

const AverageBenchCost = ({
  companyId,
  generalTitle,
  dashboardTitle,
  totalOrganizationTitle,
  totalPerTribeNumberTitle,
  tableTitle,
  selectedKey,
}: Props) => {
  const [getTribesStaffed, { data: tribesData }] =
    useLazyGetAllTribesStaffedQuery()
  const [getEngagements, { data: engagementsData }] =
    useLazyGetAllEngagementsQuery()

  const currentYear = new Date().getFullYear()
  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = currentYear - i
    return { value: year.toString(), label: year.toString() }
  })
  const [selectedInterval, setSelectedInterval] = useState<SelectOption>({
    value: 'YTD',
    label: 'YTD',
  })
  const [selectedYear, setSelectedYear] = useState<SelectOption>({
    value: currentYear.toString(),
    label: currentYear.toString(),
  })

  const [availableTribes, setAvailableTribes] = useState<SelectOption[]>([])
  const [availableTeams, setAvailableTeams] = useState<SelectOption[]>([])
  const [selectedTribe, setSelectedTribe] = useState<SelectOption>()
  const [selectedTeam, setSelectedTeam] = useState<SelectOption>()

  const [timelineGroups, setTimelineGroups] = useState<
    TeamUtilizationTimelineGroup[]
  >([])
  const [timelineItems, setTimelineItems] = useState<
    TeamUtilizationTimelineItemBase[]
  >([])

  const timelineStartDefaultDate = moment().startOf('day').toDate()
  const [timelineStart, setTimelineStart] = useState<Date>(
    timelineStartDefaultDate,
  )
  const timelineEndDefaultDate = moment().add(65, 'day').endOf('day').toDate()
  const [timelineEnd, setTimelineEnd] = useState<Date>(timelineEndDefaultDate)

  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  useEffect(() => {
    getTribesStaffed({
      companyId: companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    }),
      getEngagements({
        companyId,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery: '',
        sortType: 'DESC',
      })
  }, [])

  useEffect(() => {
    if (tribesData && engagementsData) {
      buildTeamUtilizationTimelineGroups(getFilteredTribes())
      buildTeamUtilizationTimelineItems(getFilteredTribes())
    }
  }, [tribesData, engagementsData, selectedTribe, selectedTeam, timelineItems])

  useEffect(() => {
    if (selectedYear) {
      setTimelineStart(
        moment().year(parseInt(selectedYear.value)).startOf('year').toDate(),
      )
      setTimelineEnd(
        moment().year(parseInt(selectedYear.value)).endOf('year').toDate(),
      )
    } else {
      setTimelineStart(timelineStartDefaultDate)
      setTimelineEnd(timelineEndDefaultDate)
    }
  }, [selectedYear])

  useEffect(() => {
    const teamOptions: SelectOption[] = [{ label: 'All Teams', value: '' }]
    const tribes = getFilteredTribes()
    tribes?.forEach(tribe => {
      tribe.teams.forEach(team => {
        teamOptions.push({ label: team.name, value: `${team.id}` })
      })
    })
    setSelectedTeam(undefined)
    setAvailableTeams(teamOptions)
  }, [selectedTribe, availableTribes])

  useEffect(() => {
    if (tribesData) {
      const tribeOptions: SelectOption[] = tribesData.map(tribe => {
        return { label: tribe.name, value: `${tribe.id}` }
      })
      setAvailableTribes(tribeOptions)
    }
  }, [tribesData])

  const CustomDateInputRef = forwardRef((props: any, ref) => (
    <CustomDateInput {...props} forwardedRef={ref} />
  ))
  CustomDateInputRef.displayName = 'Date Input'

  const getFilteredTribes = () => {
    let tribes = tribesData || []
    if (selectedTribe) {
      tribes = tribes?.filter(
        tribe => tribe.id === parseInt(selectedTribe.value),
      )
    }
    if (selectedTeam) {
      const teamFilteredTribe: TribeStaffed[] = []
      tribes.forEach(tribe =>
        tribe.teams.forEach(team => {
          if (team.id === parseInt(selectedTeam.value)) {
            const modifiedTribe: TribeStaffed = {
              ...tribe,
              teams: [team],
            }
            teamFilteredTribe.push(modifiedTribe)
          }
        }),
      )
      tribes = teamFilteredTribe
    }
    return tribes
  }

  const isBetweenDateRange = (team: Team | TeamStaffed) => {
    const timelineStartWithoutTimezoneOffset =
      timelineStart.valueOf() - timelineStart.getTimezoneOffset() * 60 * 1000
    const timelineEndWithoutTimezoneOffset =
      timelineEnd.valueOf() - timelineEnd.getTimezoneOffset() * 60 * 1000
    if (
      timelineStart.valueOf() !== timelineStartDefaultDate.valueOf() &&
      timelineEnd.valueOf() !== timelineEndDefaultDate.valueOf()
    ) {
      return (
        new Date(team.startDate).valueOf() <
          timelineEndWithoutTimezoneOffset.valueOf() &&
        (!team.endDate ||
          new Date(team.endDate).valueOf() >
            timelineStartWithoutTimezoneOffset.valueOf())
      )
    } else {
      return true
    }
  }

  const buildTeamUtilizationTimelineGroups = (tribes: TribeStaffed[]) => {
    //Build timeline groups
    const groups: TeamUtilizationTimelineGroup[] = []

    groups.push({
      id: `Total-Cost`,
      title: 'Org Total',
      type: 'team',
      bgColor: '',
    })

    const sortedTribes = [...tribes].sort((a, b) => (a.name > b.name ? 1 : -1))
    sortedTribes.forEach(tribe => {
      groups.push({
        id: `Tribe-${tribe.id}`,
        title: 'Tribe',
        type: 'tribe',
        bgColor: tribe.color,
      })
      const sortedTribeTeams = [...tribe.teams].sort((a, b) =>
        a.name > b.name ? 1 : -1,
      )
      sortedTribeTeams.forEach(team => {
        if (isBetweenDateRange(team)) {
          groups.push({
            id: `Team-${team.id}-Cost`,
            title: team.name,
            type: 'team',
          })
        }
      })
    })
    setTimelineGroups(groups)
  }

  const getSlightlyDifferentColor = (color, amount) => {
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(
          /../g,
          color =>
            `${parseInt(color, 16) >= 10 ? '' : '0'}` +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16,
            ),
        )
    )
  }

  const buildTeamUtilizationTimelineItems = (tribes: TribeStaffed[]) => {
    const items: TeamUtilizationTimelineItemBase[] = []
    const weeksSinceEpoch = Math.floor(
      (moment.utc(new Date().toDateString()).valueOf() + 3 * dayInUnixTime) /
        (7 * dayInUnixTime),
    )
    const weeksOffsetForCalculation = 100
    const weeklyValueOfAllTeams = Array(weeksOffsetForCalculation * 2 + 1).fill(
      0,
    )
    tribes.forEach(tribe => {
      items.push({
        id: `tribe-${tribe.id}`,
        group: `Tribe-${tribe.id}`,
        title: `${tribe.name}`,
        start_time: -1e15,
        end_time: 1e15,
        type: 'costOfBench',
        bgColor: `${tribe.color}`,
        selectedBgColor: `${getSlightlyDifferentColor(tribe.color ?? '', 10)}`,
        className: 'item-with-text-align',
      })
      for (
        let i = weeksSinceEpoch - weeksOffsetForCalculation;
        i < weeksSinceEpoch + weeksOffsetForCalculation;
        i++
      ) {
        const momentWeekStarts = moment(
          7 * dayInUnixTime * i + 3 * dayInUnixTime,
        ).endOf('day')
        const momentWeekEnds = moment(
          7 * dayInUnixTime * (i + 1) - 1 + 3 * dayInUnixTime,
        ).endOf('day')
        tribe.teams.forEach(team => {
          const weeklyValue = Array<number>(
            weeksOffsetForCalculation * 2 + 1,
          ).fill(0)
          const calculatedValue = calculateWeeklyValue(
            team,
            i,
            weeksSinceEpoch,
            weeksOffsetForCalculation,
          )
          weeklyValue[i - weeksSinceEpoch + weeksOffsetForCalculation] +=
            calculatedValue.value
          weeklyValueOfAllTeams[
            i - weeksSinceEpoch + weeksOffsetForCalculation
          ] += calculatedValue.value
          items.push({
            id: `team-${team.id}-cost-week${i}`,
            group: `Team-${team.id}-Cost`,
            title: `$${weeklyValue[
              i - weeksSinceEpoch + weeksOffsetForCalculation
            ].toLocaleString()}`,
            start_time: momentWeekStarts.valueOf(),
            end_time: momentWeekEnds.valueOf(),
            type: 'costOfBench',
            bgColor: '#01000000',
            selectedBgColor: '#00000000',
            className: 'item-with-text-align fw-normal',
            engagementName: calculatedValue.engagementName,
          })
        })
      }
    })
    for (
      let i = weeksSinceEpoch - weeksOffsetForCalculation;
      i < weeksSinceEpoch + weeksOffsetForCalculation;
      i++
    ) {
      const momentWeekStarts = moment(
        7 * dayInUnixTime * i + 3 * dayInUnixTime,
      ).endOf('day')
      const momentWeekEnds = moment(
        7 * dayInUnixTime * (i + 1) - 1 + 3 * dayInUnixTime,
      ).endOf('day')
      items.push({
        id: `total-cost-week${i}`,
        group: `Total-Cost`,
        title: `$${weeklyValueOfAllTeams[
          i - weeksSinceEpoch + weeksOffsetForCalculation
        ].toLocaleString()}`,
        start_time: momentWeekStarts.valueOf(),
        end_time: momentWeekEnds.valueOf(),
        type: 'costOfBench',
        bgColor: '#01000000',
        selectedBgColor: '#00000000',
        className: 'item-with-text-align fw-bold',
      })
    }
    if (
      items.length != timelineItems.length ||
      (items.at(0) &&
        timelineItems.at(0) &&
        items.at(0)?.id != timelineItems.at(0)?.id)
    )
      setTimelineItems(items)
  }

  const calculateWeeklyValue = (
    team: TeamStaffed,
    i: number,
    weeksSinceEpoch: number,
    weeksOffsetForCalculation: number,
  ) => {
    const weeksSinceEpochSinceTeamStart = Math.floor(
      moment(new Date(team.startDate).toDateString()).valueOf() /
        (7 * dayInUnixTime),
    )
    const weeksSinceEpochSinceTeamEnd = Math.floor(
      moment(
        (team.endDate != null
          ? new Date(team.endDate)
          : moment(new Date()).add(weeksOffsetForCalculation, 'w').toDate()
        ).toDateString(),
      ).valueOf() /
        (7 * dayInUnixTime),
    )
    let value = 0
    let engagementName: string | undefined
    switch (selectedKey) {
      case 1:
        if (
          weeksSinceEpochSinceTeamStart <= i &&
          weeksSinceEpochSinceTeamEnd >= i
        ) {
          team.teamAllocations?.forEach(allocation => {
            value += getTeamMemberCostWithAllocationPercent(allocation)
          })
        }
        //Here the cost is reduced by the weeks in engagement
        engagementsData?.forEach(engagement => {
          const termToSubtractIfDifferentYears = moment()
            .startOf('y')
            .diff(moment(new Date(engagement.startDate)).startOf('y'), 'w')
          const termToReachWeekOfEngagementStartDate =
            moment().week() -
            moment(new Date(engagement.startDate).toDateString()).week()
          const weeksSinceEpochSinceEngagementStart =
            weeksSinceEpoch -
            1 -
            termToSubtractIfDifferentYears -
            termToReachWeekOfEngagementStartDate
          const weeksSinceEpochSinceEngagementEnd =
            weeksSinceEpochSinceEngagementStart +
            moment(new Date(engagement.endDate).toDateString()).diff(
              moment(new Date(engagement.startDate).toDateString()),
              'w',
            )
          if (
            value > 0 &&
            engagement.team.id === team.id &&
            i >= weeksSinceEpochSinceEngagementStart &&
            i <= weeksSinceEpochSinceEngagementEnd
          ) {
            team.teamAllocations?.forEach(allocation => {
              value -= getTeamMemberCostWithAllocationPercent(allocation)
            })
          }
        })

        break
      case 2:
        engagementsData?.forEach(engagement => {
          const termToSubtractIfDifferentYears = moment()
            .startOf('y')
            .diff(moment(new Date(engagement.startDate)).startOf('y'), 'w')
          const termToReachWeekOfEngagementStartDate =
            moment().week() -
            moment(new Date(engagement.startDate).toDateString()).week()
          const weeksSinceEpochSinceEngagementStart =
            weeksSinceEpoch -
            1 -
            termToSubtractIfDifferentYears -
            termToReachWeekOfEngagementStartDate
          const weeksSinceEpochSinceEngagementEnd =
            weeksSinceEpochSinceEngagementStart +
            moment(new Date(engagement.endDate).toDateString()).diff(
              moment(new Date(engagement.startDate).toDateString()),
              'w',
            )
          if (
            engagement.team.id === team.id &&
            i >= weeksSinceEpochSinceEngagementStart &&
            i <= weeksSinceEpochSinceEngagementEnd
          ) {
            value += engagement.weeklyRate
            engagementName = engagement.projectName
          }
        })
        break
      default:
        //Add the cost as negative
        if (
          weeksSinceEpochSinceTeamStart <= i &&
          weeksSinceEpochSinceTeamEnd >= i
        ) {
          team.teamAllocations?.forEach(allocation => {
            value -= getTeamMemberCostWithAllocationPercent(allocation)
          })
        }
        //Here the cost is reduced by the weeks in engagement
        engagementsData?.forEach(engagement => {
          const termToSubtractIfDifferentYears = moment()
            .startOf('y')
            .diff(moment(new Date(engagement.startDate)).startOf('y'), 'w')
          const termToReachWeekOfEngagementStartDate =
            moment().week() -
            moment(new Date(engagement.startDate).toDateString()).week()
          const weeksSinceEpochSinceEngagementStart =
            weeksSinceEpoch -
            1 -
            termToSubtractIfDifferentYears -
            termToReachWeekOfEngagementStartDate
          const weeksSinceEpochSinceEngagementEnd =
            weeksSinceEpochSinceEngagementStart +
            moment(new Date(engagement.endDate).toDateString()).diff(
              moment(new Date(engagement.startDate).toDateString()),
              'w',
            )
          if (
            engagement.team.id === team.id &&
            i >= weeksSinceEpochSinceEngagementStart &&
            i <= weeksSinceEpochSinceEngagementEnd
          ) {
            value += engagement.weeklyRate
            engagementName = engagement.projectName
          }
        })
    }
    return { value: value, engagementName: engagementName }
  }

  const getTeamMemberCostWithAllocationPercent = (allocation: UserTeam) => {
    if (allocation.user.guild === null) {
      return 0
    }
    return allocation.user.guild.salary * (allocation.allocation / 100)
  }

  const getDeliveryBenchCostYearToDate = () => {
    let sumOfTeamCostWhileInEngagement = 0
    let sumOfTotalCostOfAllTeams = 0
    tribesData?.forEach(tribe => {
      sumOfTeamCostWhileInEngagement += getTribeCostWhileInEngagementYTD(tribe)
      sumOfTotalCostOfAllTeams += getTribeTotalCostYTD(tribe)
    })
    return sumOfTotalCostOfAllTeams - sumOfTeamCostWhileInEngagement
  }

  const getDeliveryRevenueYearToDate = () => {
    let sumOfTotalRevenue = 0
    tribesData?.forEach(tribe => {
      sumOfTotalRevenue += getTribeTotalRevenueFromEngagements(tribe)
    })
    return sumOfTotalRevenue
  }

  const getDeliveryNetRevenueYearToDate = () => {
    let sumOfTotalRevenue = 0
    let sumOfTotalCostOfAllTeams = 0
    tribesData?.forEach(tribe => {
      sumOfTotalRevenue += getTribeTotalRevenueFromEngagements(tribe)
      sumOfTotalCostOfAllTeams += getTribeTotalCostYTD(tribe)
    })
    return sumOfTotalRevenue - sumOfTotalCostOfAllTeams
  }

  const getTribeCostWhileInEngagementYTD = (tribe: TribeStaffed) => {
    let allTeamIds: number[] = []
    tribe.teams.forEach(team => {
      allTeamIds = allTeamIds.concat(team.id)
    })
    const today = new Date()
    const uniqueTeamWeekIds: string[] = []
    let weeksInYearInEngagement = 0
    let totalCostOfTeamsInEngagement = 0
    engagementsData?.forEach(engagement => {
      if (
        moment(engagement.endDate).year() >= moment(today).year() &&
        moment(engagement.startDate) <= moment(today)
      ) {
        if (moment(engagement.startDate).year() < moment(today).year()) {
          const maxNumberOfWeeks =
            moment(engagement.endDate).week() > moment(today).week()
              ? moment(today).week()
              : moment(engagement.endDate).week()
          for (let index = 0; index < maxNumberOfWeeks; index++) {
            const uniqueString = `${engagement.team.id.toString()}-${index.toString()}`
            if (
              !uniqueTeamWeekIds.includes(uniqueString) &&
              allTeamIds.includes(engagement.team.id)
            ) {
              uniqueTeamWeekIds.push(uniqueString)
              weeksInYearInEngagement += 1
              const team = tribe.teams.find(
                team => team.id === engagement.team.id,
              )
              team?.teamAllocations?.forEach(allocation => {
                totalCostOfTeamsInEngagement +=
                  getTeamMemberCostWithAllocationPercent(allocation)
              })
            }
          }
        } else {
          const maxNumberOfWeeks =
            moment(engagement.endDate).week() > moment(today).week()
              ? moment(today).week()
              : moment(engagement.endDate).week()
          for (
            let index = moment(engagement.startDate).week();
            index <= maxNumberOfWeeks;
            index++
          ) {
            const uniqueString = `${engagement.team.id.toString()}-${index.toString()}`
            if (
              !uniqueTeamWeekIds.includes(uniqueString) &&
              allTeamIds.includes(engagement.team.id)
            ) {
              uniqueTeamWeekIds.push(uniqueString)
              weeksInYearInEngagement += 1
              const team = tribe.teams.find(
                team => team.id === engagement.team.id,
              )
              team?.teamAllocations?.forEach(allocation => {
                totalCostOfTeamsInEngagement +=
                  getTeamMemberCostWithAllocationPercent(allocation)
              })
            }
          }
        }
      }
    })
    return totalCostOfTeamsInEngagement
  }

  const getTribeTotalCostYTD = (tribe: TribeStaffed) => {
    let totalTribeBenchCostCurrently = 0
    tribe.teams.forEach(team => {
      let startDate = moment(new Date(team.startDate))
      let endDate: moment.Moment
      if (startDate.year() < moment().year()) {
        startDate = moment().startOf('y')
      }
      if (team.endDate != null && moment(new Date(team.endDate)) < moment()) {
        endDate = moment(new Date(team.endDate))
      } else {
        endDate = moment(new Date())
      }
      for (let i = startDate.week(); i <= endDate.week(); i++) {
        team.teamAllocations?.forEach(allocation => {
          totalTribeBenchCostCurrently +=
            getTeamMemberCostWithAllocationPercent(allocation)
        })
      }
    })
    return totalTribeBenchCostCurrently
  }

  const getTribeTotalRevenueFromEngagementsDEPRECATED = (
    tribe: TribeStaffed,
  ) => {
    let allTeamIds: number[] = []
    tribe.teams.forEach(team => {
      allTeamIds = allTeamIds.concat(team.id)
    })
    const today = new Date()
    const uniqueTeamWeekIds: string[] = []
    let weeksInYearInEngagement = 0
    let totalRevenueofTeamInEngagement = 0
    engagementsData?.forEach(engagement => {
      if (
        moment(engagement.endDate).year() >= moment(today).year() &&
        moment(engagement.startDate) <= moment(today)
      ) {
        if (moment(engagement.startDate).year() < moment(today).year()) {
          const maxNumberOfWeeks =
            moment(engagement.endDate).week() > moment(today).week()
              ? moment(today).week()
              : moment(engagement.endDate).week()
          for (let index = 0; index < maxNumberOfWeeks; index++) {
            const uniqueString = `${engagement.team.id.toString()}-${index.toString()}`
            if (
              !uniqueTeamWeekIds.includes(uniqueString) &&
              allTeamIds.includes(engagement.team.id)
            ) {
              uniqueTeamWeekIds.push(uniqueString)
              weeksInYearInEngagement += 1
              totalRevenueofTeamInEngagement += engagement.weeklyRate
            }
          }
        } else {
          const maxNumberOfWeeks =
            moment(engagement.endDate).week() > moment(today).week()
              ? moment(today).week()
              : moment(engagement.endDate).week()
          for (
            let index = moment(engagement.startDate).week();
            index <= maxNumberOfWeeks;
            index++
          ) {
            const uniqueString = `${engagement.team.id.toString()}-${index.toString()}`

            if (
              !uniqueTeamWeekIds.includes(uniqueString) &&
              allTeamIds.includes(engagement.team.id)
            ) {
              uniqueTeamWeekIds.push(uniqueString)
              weeksInYearInEngagement += 1
              totalRevenueofTeamInEngagement += engagement.weeklyRate
            }
          }
        }
      }
    })

    return totalRevenueofTeamInEngagement
  }

  const getTribeTotalRevenueFromEngagements = (tribe: TribeStaffed) => {
    const allTeamIds: number[] = []
    const interval = selectedInterval.value
    const filteredYear = parseInt(selectedYear.value)
    const uniqueEngagementIds = new Set<number>()
    let totalRevenueofTeamInQuarter = 0

    tribe.teams.forEach(team => {
      allTeamIds.push(team.id)
    })

    engagementsData?.forEach(engagement => {
      if (
        !uniqueEngagementIds.has(engagement.team.id) &&
        allTeamIds.includes(engagement.team.id)
      ) {
        const engagementYear = moment(engagement.startDate).year()

        if (
          engagementYear === filteredYear &&
          (interval === 'YTD' || interval === 'Year')
        ) {
          const weeks = getWeeksInRange(
            engagement.startDate,
            engagement.endDate,
          )
          totalRevenueofTeamInQuarter += engagement.weeklyRate * weeks
        } else {
          const weeks = getWeeksInQuarterOfYear(
            engagement.startDate,
            engagement.endDate,
            interval,
            filteredYear,
          )
          totalRevenueofTeamInQuarter += engagement.weeklyRate * weeks
        }

        uniqueEngagementIds.add(engagement.team.id)
      }
    })

    return totalRevenueofTeamInQuarter
  }

  /**
   * Calculate the number of weeks in a specific quarter of a selected year.
   * @param {Date} startDate - The start date of the period.
   * @param {Date} endDate - The end date of the period.
   * @param {string} quarter - The selected quarter (e.g., 'Q1', 'Q2', 'Q3', 'Q4').
   * @param {number} selectedYear - The selected year.
   * @returns {number} - The number of weeks within the specified quarter and year.
   */
  const getWeeksInQuarterOfYear = (
    startDate,
    endDate,
    quarter,
    selectedYear,
  ) => {
    const startMoment = moment(startDate)
    const endMoment = moment.utc(endDate)
    const startYear = startMoment.year()
    const endYear = endMoment.year()

    if (startYear > selectedYear || endYear < selectedYear) {
      return 0 // Dates are outside the selected year
    }

    const startQuarter = getQuarterOfYear(startMoment)
    const endQuarter = getQuarterOfYear(endMoment)

    if (
      startQuarter === quarter &&
      endQuarter === quarter &&
      startYear === selectedYear
    ) {
      // Start and end dates are within the selected quarter and year
      return getWeeksInRange(startMoment, endMoment)
    } else if (
      startQuarter < quarter &&
      endQuarter > quarter &&
      startYear === selectedYear
    ) {
      // Dates span the entire quarter in the selected year
      return getWeeksInRange(startMoment, endMoment)
    } else if (
      startQuarter === quarter &&
      endQuarter > quarter &&
      startYear === selectedYear
    ) {
      // Dates start in the quarter and end after in the selected year
      const endOfStartQuarter = moment(startMoment).endOf('quarter')
      return getWeeksInRange(startMoment, endOfStartQuarter)
    } else if (
      startQuarter < quarter &&
      endQuarter === quarter &&
      startYear === selectedYear
    ) {
      // Dates start before the quarter and end within the quarter in the selected year
      const startOfEndQuarter = moment(endMoment).startOf('quarter')
      return getWeeksInRange(startOfEndQuarter, endMoment)
    }

    return 0 // In all other cases, there are no weeks in the selected quarter and year
  }

  const getQuarterOfYear = date => {
    const momentDate = moment(date)
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4']
    const quarterIndex = Math.floor(momentDate.month() / 3)
    return quarters[quarterIndex]
  }

  const getWeeksInRange = (startDate, endDate) => {
    const startMoment = moment(startDate)
    const endMoment = moment.utc(endDate)
    const startWeek = startMoment.week()
    const endWeek = endMoment.week()
    return endWeek - startWeek + 1
  }

  const getDeliveryOrgValueToShow = () => {
    switch (selectedKey) {
      case 1:
        return getDeliveryBenchCostYearToDate()
      case 2:
        return getDeliveryRevenueYearToDate()
      default:
        return getDeliveryNetRevenueYearToDate()
    }
  }

  const getTribeValueToShow = (tribe: TribeStaffed) => {
    switch (selectedKey) {
      case 1:
        return (
          getTribeTotalCostYTD(tribe) - getTribeCostWhileInEngagementYTD(tribe)
        )
      case 2:
        return getTribeTotalRevenueFromEngagements(tribe)
      default:
        return (
          getTribeTotalRevenueFromEngagements(tribe) -
          getTribeTotalCostYTD(tribe)
        )
    }
  }

  return (
    <div className="container my-4 min-height">
      <h1 className="mt-4">{generalTitle}</h1>
      <div className="d-flex justify-content-between py-2 bottom-divider">
        <p className="h3 mb-0">Filters</p>
      </div>
      <div className="mb-5 d-flex justify-content-between">
        <div className="d-flex gap-3">
          <div>
            <label>Interval</label>
            <Select
              key={`interval-select-${selectedInterval}`}
              className="basic-single"
              isClearable={true}
              isRtl={false}
              value={selectedInterval}
              options={[
                { value: 'YTD', label: 'YTD' },
                { value: 'Year', label: 'Year' },
                { value: 'Q1', label: 'Q1' },
                { value: 'Q2', label: 'Q2' },
                { value: 'Q3', label: 'Q3' },
                { value: 'Q4', label: 'Q4' },
              ]}
              onChange={option => {
                if (option) setSelectedInterval(option)
                else setSelectedInterval({ value: 'YTD', label: 'YTD' })
              }}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={'YTD'}
            />
          </div>
          <div>
            <label>Year</label>
            <Select
              key={`year-select-${selectedYear}`}
              className="basic-single"
              isClearable={true}
              isRtl={false}
              value={selectedYear}
              options={yearOptions}
              onChange={option => {
                if (option) setSelectedYear(option)
                else setSelectedYear(yearOptions[0])
              }}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={yearOptions[0].value}
            />
          </div>
        </div>
        <div className="d-flex gap-3">
          <div style={{ width: '200px' }}>
            <label>Tribe</label>
            <Select
              key={`tribe-select-${selectedTribe?.label}`}
              className="basic-single"
              isClearable={true}
              isRtl={false}
              options={availableTribes}
              value={selectedTribe}
              onChange={option => {
                if (option) setSelectedTribe(option)
                else setSelectedTribe(undefined)
              }}
              placeholder={'Sort by Tribe'}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
            />
          </div>
          <div style={{ width: '200px' }}>
            <label>Team</label>
            <Select
              key={`team-select-${selectedTeam?.label}`}
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isRtl={false}
              options={availableTeams}
              value={selectedTeam}
              onChange={option => {
                if (option) setSelectedTeam(option)
                else setSelectedTeam(undefined)
              }}
              placeholder={'Sort by Team'}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between py-2">
        <p className="h3 mb-0">{dashboardTitle}</p>
      </div>
      <div className="d-flex flex flex-row justify-content-between">
        <div className="d-flex flex-column dashboard-item p-3 justify-content-around">
          <div className="title fw-bolder">
            {totalOrganizationTitle} ({selectedInterval.label})
          </div>
          <div className="grow big-number text-break">
            ${getDeliveryOrgValueToShow().toLocaleString()}
          </div>
        </div>
        <div
          className="d-flex flex-column dashboard-item p-4 justify-content-around"
          style={{ width: '68%' }}
        >
          <div className="title">
            {totalPerTribeNumberTitle} ({selectedInterval.label})
          </div>
          <div className="d-flex overflow-auto">
            {getFilteredTribes().map((element, index) => {
              return (
                <div className="tribe-item" key={index}>
                  <div className="fw-bold">{element.name}</div>
                  <div className="text-secondary">
                    ${getTribeValueToShow(element).toLocaleString()}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="mt-5 d-flex justify-content-between py-2">
        <p className="h3 mb-0">{tableTitle}</p>
      </div>
      <AverageBenchCostTimeline
        groups={timelineGroups}
        items={timelineItems}
        timelineStart={timelineStart}
        timelineEnd={timelineEnd}
        timelineStartDefaultValue={timelineStartDefaultDate}
        timelineEndDefaultValue={timelineEndDefaultDate}
      />
    </div>
  )
}

export default AverageBenchCost
