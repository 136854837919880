import React from 'react'
import ReactExport from 'react-export-excel-xlsx-fix'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import ColorGuide from '../ColorGuide/ColorGuide'

interface Props {
  selectedReport: string
  exportData: Array<object>
}
const ReportTableTitleBar = ({ selectedReport, exportData }: Props) => {
  const createReportFilename = (reportType: string) =>
    `${reportType}-${moment().format('MM-DD-YYYY')}`

  const reportFilename = createReportFilename(selectedReport)
  return (
    <div className="d-flex justify-content-between w-full align-items-baseline">
      <p className="h3 bold">{`${selectedReport} Report`}</p>
      <div className="d-flex justify-content-between w-full align-items-baseline">
        {selectedReport === "Time Off" && 
          <div className="d-flex flex-row me-3">
            <ColorGuide style={{ background: '#8E9296' }} text="Past" />
            <ColorGuide style={{ background: '#72C02C' }} text="0-2 months" />
            <ColorGuide style={{ background: '#FFB800' }} text="3-6 months" />
            <ColorGuide style={{ background: '#4F4959' }} text="+6 months" />
          </div>
        }
        <span className="h-auto">
          <ReactExport.ExcelFile
            filename={reportFilename}
            element={
              <a href="#" className="download-report">
                Download
                <FontAwesomeIcon className="mx-2" icon={faDownload} />
              </a>
            }
            className="download-report"
          >
            <ReactExport.ExcelSheet
              dataSet={exportData}
              className="download-report"
              name={reportFilename}
            />
          </ReactExport.ExcelFile>
        </span>
      </div>
    </div>
  )
}

export default ReportTableTitleBar
