import { Company, CreateCompanyDto } from '../types/Company'
import { timeoffApi } from './timeoff.api'

const companyEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getCompanyById: build.query<Company, string>({
      query: id => `/company/byId/${id}`,
      providesTags: ['CompanyData'],
    }),
    createCompany: build.mutation<Company, CreateCompanyDto>({
      query: data => ({
        url: `/company`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const { useGetCompanyByIdQuery, useCreateCompanyMutation } =
  companyEndpoints
