import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import ColorGuide from '../ColorGuide/ColorGuide'
import { SelectOption } from '../../types/SelectOption'
import Select from 'react-select'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import { getWeekOfYear } from '../../utils/general'
import moment from 'moment'

interface Props {
  guildsInfo: Array<{ name: string; color: string; guildId: number }>
  selectedYear: SelectOption
  selectedWeek: SelectOption
  selectedSnapshot: SelectOption
  snapshotDates: SelectOption[]
  yearOptions: SelectOption[]
  setSelectedYear: (SelectOption) => void
  setSelectedWeek: (SelectOption) => void
  setSelectedSnapshot: (SelectOption) => void
  setCurrent: () => void
}

const TribeOrgHeaderContainer = ({
  guildsInfo,
  selectedYear,
  selectedWeek,
  snapshotDates,
  selectedSnapshot,
  yearOptions,
  setSelectedYear,
  setSelectedWeek,
  setSelectedSnapshot,
  setCurrent,
}: Props) => {
  const filteredDates = useMemo(() => {
    return snapshotDates.filter(snapshotDate => {
      if (selectedYear.value === '') return false
      const date = moment(snapshotDate.value)
      if (!date.isValid()) {
        return false
      }
      return date.year() === parseInt(selectedYear.value)
    })
  }, [snapshotDates])

  const selectWeeks = useMemo(() => {
    return filteredDates.map(snap => {
      const weekOfYear = getWeekOfYear(snap.value)
      return { label: `Week ${weekOfYear}`, value: `${weekOfYear}` }
    })
  }, [filteredDates])
 
  const backward = () => {
    const currentWeek = parseInt(selectedWeek.value)
    const limitWeek = parseInt(selectWeeks[selectWeeks.length - 1].value)
    if (currentWeek - 1 >= limitWeek) {
      setSelectedWeek({
        label: `Week ${currentWeek - 1}`,
        value: `${currentWeek - 1}`,
      })
    }else{
      const weekOfYear = getWeekOfYear(filteredDates[0].value)
      setSelectedSnapshot(filteredDates[0])
      setSelectedWeek({ value: `${weekOfYear}`, label: `Week ${weekOfYear}` })
    }
  }

  const forward = () => {
    const currentWeek = parseInt(selectedWeek.value)
    const limitWeek = parseInt(selectWeeks[0].value)
    if (currentWeek + 1 <= limitWeek) {
      setSelectedWeek({
        label: `Week ${currentWeek + 1}`,
        value: `${currentWeek + 1}`,
      })
    }
  }

  document.title = 'Time Off - Tribe Org'
  return (
    <div>
      <div className="tribe-org-header-container">
        <h1>Tribe Org</h1>
      </div>
      <div className="bottom-divider">
        <h4>Filters</h4>
      </div>
      <div className="tribe-org-filters mb-5 d-flex justify-content-between">
        <div className="d-flex gap-3">
          <div style={{ width: '150px' }}>
            <label>Year</label>
            <Select
              key={`year-select-${selectedYear}`}
              className="basic-single"
              isClearable={selectedYear.value !== ''}
              isRtl={false}
              value={selectedYear}
              options={yearOptions}
              onChange={option => {
                if (option) setSelectedYear(option)
                else setSelectedYear({ value: '', label: '' })
              }}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={'2022'}
            />
          </div>
          <div style={{ width: '180px' }}>
            <label>Week</label>
            <Select
              key={`month-select-${selectedWeek}`}
              className="basic-single"
              isClearable={selectedWeek.value !== ''}
              isRtl={false}
              defaultValue={selectWeeks[0]}
              value={selectedWeek}
              options={selectWeeks}
              onChange={option => {
                if (option) setSelectedWeek(option)
                else setSelectedWeek({ value: '', label: '' })
              }}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={'Week 21'}
            />
          </div>
          <div>
            <button
              className="tribe-org-button-week border-0"
              style={{ marginTop: '30px' }}
              onClick={() => backward()}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </div>
          <div style={{ width: '300px' }}>
            <label></label>
            <Select
              key={`month-select-${selectedWeek}`}
              className="basic-single"
              isClearable={selectedSnapshot.value !== ''}
              isRtl={false}
              options={filteredDates}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={'Pick a date range'}
              value={selectedSnapshot}
              onChange={option => {
                if (option) setSelectedSnapshot(option)
                else {
                  setSelectedWeek({ value: '', label: '' })
                  setSelectedSnapshot({ value: '', label: '' })
                }
              }}
            />
          </div>
          <div>
            <button
              className="tribe-org-button-week border-0"
              style={{ marginTop: '30px' }}
              onClick={() => forward()}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
        <div className="d-flex gap-3">
          <div>
            <Button
              className={`no-decoration normal-link tribe-org-manage-tribe-button`}
              style={{ marginTop: '25px' }}
              onClick={() => setCurrent()}
              disabled={selectedSnapshot.value === ''}
            >
              Current State
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TribeOrgHeaderContainer
