/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EngagementForm } from '../../components/EngagementForm/EngagementForm'
import Loader from '../../components/Loader/Loader'
import { useGetAllClientsQuery } from '../../redux/client.endpoints'
import { useCreateEngagementMutation } from '../../redux/engagement.endpoints'
import { useGetAllTeamsQuery } from '../../redux/team.endpoints'
import { CreateEngagementDto } from '../../types/Engagement'

interface Props {
  companyId: number
}

const EngagementCreate = ({ companyId }: Props) => {
  const navigate = useNavigate()

  const {
    data: clients,
    isSuccess: clientsSuccess,
    refetch: refetchClients,
  } = useGetAllClientsQuery(`${companyId}`)
  const {
    data: teams,
    isSuccess: teamsSuccess,
    refetch: refetchTeams,
  } = useGetAllTeamsQuery(companyId)
  // const { data: teams, isLoading: teamsLoading } = useGetAllTeams(companyId)
  const [createEngagement, createEngagementResult] =
    useCreateEngagementMutation()
  const handleCreateEngagement = async (args: CreateEngagementDto) => {
    createEngagement(args)
  }

  useEffect(() => {
    refetchClients()
    refetchTeams()
  }, [])

  useEffect(() => {
    if (createEngagementResult && createEngagementResult.isError) {
      if (createEngagementResult.error) {
        const error = createEngagementResult.error
        if ('data' in error) {
          const errorMessage = error.data as any

          if ('error' in errorMessage) {
            toast.error(errorMessage.error, {
              toastId: 'create-engagement-error',
            })
          }
        }
      } else {
        toast.error(`An error occurred when creating the engagement.`, {
          toastId: 'create-engagement-error',
        })
      }
    } else if (createEngagementResult && createEngagementResult.isSuccess) {
      toast.success(`Engagement created successfully!`, {
        toastId: 'create-engagement-success',
      })
      navigate('/engagements')
    }
  }, [createEngagementResult])

  return (
    <>
      <Helmet title="Time Off - Create Engagement" />
      <main className="container-fluid scrollable">
        <div className={`row pt-3 ${isDesktop || isTablet ? 'h-100' : ''}`}>
          <div className="col-8 offset-2 d-flex flex-column align-items-center justify-content-center bg-lazuli-white pb-5">
            <p className="align-self-start font-30 fw-bold">
              Add New Engagement
            </p>
            {teamsSuccess && clientsSuccess ? (
              <EngagementForm
                handleCreateSubmit={handleCreateEngagement}
                displayErrorBanner={
                  createEngagementResult && createEngagementResult.isError
                }
                loading={
                  createEngagementResult && createEngagementResult.isLoading
                }
                companyId={companyId}
                clients={clients || []}
                teams={teams || []}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default EngagementCreate
