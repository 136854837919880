import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { backendUrl } from '../utils/env'

export const timeoffApi = createApi({
  reducerPath: 'timeoffApi',
  baseQuery: fetchBaseQuery({ baseUrl: backendUrl }),
  tagTypes: [
    'Users',
    'LoggedInUser',
    'UserData',
    'CompanyData',
    'TribeData',
    'TribeOrgData',
    'ClientData',
    'Team',
    'TeamsData',
    'SnapshotsData',
    'SnapshotsDates',
    'TimeOffList',
    'TimeOffData',
    'EngagementsData',
    'TribeOrg',
    'Allocation',
    'Role',
    'Integration',
    'TechStacks',
  ],
  endpoints: () => ({}),
})
