import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { FormCheck, Modal } from 'react-bootstrap'
import {
  useGetIntegrationStatusQuery,
  useUpdateIntegrationStatusMutation,
} from '../../redux/timeoff.endpoints'
import bamboohrLogo from '../../assets/bamboohr_logo.png'

type ModalBambooIntegrationProps = {
  isOpen: boolean
  onClose: () => void
}

const WarningMessage = () => {
  return (
    <div>
      <div
        className="alert alert-warning"
        role="alert"
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 205, 57, 0.6)',
          color: 'rgba(33, 37, 41, 1)',
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color="#FFB800"
          style={{ marginRight: '10px' }}
          size="2x"
        />
        <span>
          Before turning off any integration setting, understand its current
          functionality and the potential impact of disabling it. Consider how
          it may affect data flow, processes, and users
        </span>
      </div>
    </div>
  )
}

type IntegrationStatusProps = {
  isOn: string
  setIsOn: (value: string) => void
}

const IntegrationStatus = ({ isOn, setIsOn }: IntegrationStatusProps) => {
  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <div style={{ marginBottom: '10px' }}>
        <div style={{ fontWeight: 'bold', margin: '0' }}>
          <FormCheck
            style={{ fontSize: '20px', marginBottom: '10px' }}
            inline
            label="Bamboo integration is ON"
            name="group1"
            type={'radio'}
            id={`inline-radio-1`}
            checked={isOn === 'on'}
            onChange={() => setIsOn('on')}
          />
        </div>
        <p style={{ margin: '0' }}>
          Bamboo Time Off integrations are on. All team information will
          automatically sync.
        </p>
      </div>
      <div>
        <div style={{ fontWeight: 'bold', margin: '0' }}>
          <FormCheck
            style={{ fontSize: '20px', marginBottom: '10px' }}
            inline
            label="Bamboo integration is OFF"
            name="group1"
            type="radio"
            id={`inline-radio-2`}
            checked={isOn === 'off'}
            onChange={() => setIsOn('off')}
          />
        </div>
        <p style={{ margin: '0' }}>
          Bamboo Time Off integrations are off. All team information will no
          longer be synced, information can be lost.
        </p>
      </div>
    </div>
  )
}

export default function ModalBambooIntegration({
  isOpen,
  onClose,
}: ModalBambooIntegrationProps) {
  const [isOn, setIsOn] = React.useState('on')
  const { data, isSuccess } = useGetIntegrationStatusQuery('bamboohr')
  const [updateStatus] = useUpdateIntegrationStatusMutation()

  useEffect(() => {
    if (data && isSuccess) {
      const isActive = data.isActive ? 'on' : 'off'
      setIsOn(isActive)
    }
  }, [data, isSuccess])

  const handleSave = async () => {
    await updateStatus({ integrationName: 'bamboohr', isActive: isOn === 'on' })
    onClose()
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton style={{ justifyContent: 'center' }}>
        <img
          src={bamboohrLogo}
          alt="BambooHR Logo"
          style={{
            height: '25px',
            width: '153px',
            marginRight: '10px',
            marginBottom: '3px',
          }}
        />
        <Modal.Title>Integration Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WarningMessage />
        <IntegrationStatus isOn={isOn} setIsOn={setIsOn} />
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', width: '100%' }}>
          <button
            className="btn btn-secondary"
            style={{
              marginRight: '10px',
              flex: 1,
            }}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            style={{
              flex: 1,
            }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
