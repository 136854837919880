import React, { useEffect, useState } from 'react'
import { validateEmail } from '../../utils/validations'
import { CreateUserDto, EditUserDto, User } from '../../types/User'
import { Tribe } from '../../types/Tribe'
import { Team } from '../../types/Team'
import { useNavigate } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role, TeamRoles, LeadershipRoleTypes } from '../../types/Roles'
import moment from 'moment'
import Select from 'react-select'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'
import { Engagement } from '../../types/Engagement'
import {
  ExperienceLevel,
  TechnologyList,
  TechStackList,
} from '../../types/TechStacks'

interface Props {
  handleCreateSubmit?: (args: CreateUserDto) => Promise<void>
  handleEditSubmit?: (args: EditUserDto) => Promise<void>
  displayErrorBanner?: boolean
  loading: boolean
  user?: User
  companyId: number
  tribes: Tribe[]
  teams: Team[]
  engagements: Engagement[]
  technologyList: TechnologyList[]
  experienceLevel: ExperienceLevel[]
  selectedTechStackListByUser?: TechStackList[]
}

export const EmployeeForm = ({
  handleCreateSubmit,
  handleEditSubmit,
  displayErrorBanner,
  loading,
  user,
  companyId,
  teams,
  tribes,
  engagements,
  technologyList,
  experienceLevel,
  selectedTechStackListByUser,
}: Props) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isGuildMaster, setIsGuildMaster] = useState(false)
  const [isStaff, setIsStaff] = useState(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedGuild, setSelectedGuild] = useState(-1)
  const [selectedTechnologies, setSelectedTechnologies] = useState<number>(0)
  const [selectedExperience, setSelectedExperience] = useState<number>(0)
  const [guilds, setGuilds] = useState<Array<Role>>([])
  const [technologies, setTechnologies] = useState<TechnologyList[]>([])
  const [experience, setExperience] = useState<ExperienceLevel[]>([])
  const [selectedTechStackList, setSelectedTechStackList] = useState<
    TechStackList[]
  >([])
  const [selectedTribes, setSelectedTribes] = useState<Tribe[]>([])
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([])
  const [tempSelectedTribeIds, setTempSelectedTribeIds] = useState<number[]>([])
  const [tempSelectedGuildIds, setTempSelectedGuildIds] = useState<number[]>([])
  const [
    selectedTribeIdsForEmailNotification,
    setSelectedTribeIdsForEmailNotification,
  ] = useState<number[]>([])
  const [
    selectedGuildIdsForEmailNotification,
    setSelectedGuildIdsForEmailNotification,
  ] = useState<number[]>([])
  const [teamAllocations, setTeamAllocations] = useState<{
    [key: number]: { allocation: number; role: string }
  }>([])
  const [allocationError, setAllocationError] = useState(false)
  const navigate = useNavigate()
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()

  const selectableTeams = teams
    .filter(team => selectedTribes.find(tribe => tribe.id === team.tribeId))
    .filter(
      team => !selectedTeams.find(selectedTeam => selectedTeam.id === team.id),
    )

  const colorMapping = {
    1: 'lightgrey', //Junior
    2: 'lightgreen', //Middle
    3: 'darkgreen', //Senior
  }

  const [displayTechListForUser, setDisplayTechListForUser] = useState(false)

  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setIsAdmin(user.isAdmin)
      setIsGuildMaster(user.isGuildMaster)
      guilds.forEach(
        guild => guild.id === user.guildId && setSelectedGuild(user.guildId),
      )
      setStartDate(user.start_on ? new Date(user.start_on) : null)
      setEndDate(user.end_on ? new Date(user.end_on) : null)
      setSelectedGuild(user.guildId)
      setIsStaff(user.staffAugmentation)
      setSelectedGuildIdsForEmailNotification(user.subscribedGuildIdsForEmail)
      setSelectedTribeIdsForEmailNotification(user.subscribedTribeIdsForEmail)
      setTempSelectedGuildIds(user.subscribedGuildIdsForEmail)
      setTempSelectedTribeIds(user.subscribedTribeIdsForEmail)

      if (user.tribes.length > 0) {
        const userSelectedTribes: Tribe[] = []
        user.tribes.forEach(userTribe => {
          const tribe = tribes.find(tribe => tribe.id === userTribe.id)
          if (tribe && !userSelectedTribes.includes(tribe))
            userSelectedTribes.push(tribe)
        })
        setSelectedTribes([...userSelectedTribes])
      }
      if (user.teamAllocations) {
        const allocations: {
          [key: number]: { allocation: number; role: string }
        } = {}
        const userSelectedTeams: Team[] = []

        user.teamAllocations.forEach(userTeam => {
          allocations[userTeam.teamId] = {
            allocation: userTeam.allocation,
            role: userTeam.role || '',
          }

          userSelectedTeams.push(userTeam.team)
        })
        setSelectedTeams([...userSelectedTeams])
        setTeamAllocations({
          ...allocations,
        })
      }
    }
  }, [user])

  useEffect(() => {
    if (selectedTechStackListByUser) {
      setSelectedTechStackList(selectedTechStackListByUser)
    }
  }, [selectedTechStackListByUser])

  useEffect(() => {
    if (selectedTechStackList) {
      setDisplayTechListForUser(true)
    } else {
      setDisplayTechListForUser(false)
    }
  }, [selectedTechStackList])

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectedTeams.length > 0) {
      let totalAllocation = 0
      Object.keys(teamAllocations).forEach(key => {
        totalAllocation += teamAllocations[parseInt(key)].allocation
      })
      if (totalAllocation > 100) {
        setAllocationError(true)
        toast.error('Total teams allocation can not be over 100%', {
          toastId: 'allocation-error',
        })
      } else if (validateEmail(email)) {
        const allocations: {
          [key: number]: { allocation: number; role: string }
        } = {}
        Object.keys(teamAllocations).map(key => {
          const id = parseInt(key)
          allocations[id] = teamAllocations[id]
        })
        const args = {
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          isAdmin,
          isGuildMaster,
          guildId: selectedGuild,
          teamAllocations: allocations,
          startDate,
          endDate,
          tribes: selectedTribes,
          staffAugmentation: isStaff,
          subscribedTribeIdsForEmail: selectedTribeIdsForEmailNotification,
          subscribedGuildIdsForEmail: selectedGuildIdsForEmailNotification,
          techStack: selectedTechStackList,
        }
        user
          ? handleEditSubmit &&
            (await handleEditSubmit({
              ...args,
              userId: user.id,
            }))
          : handleCreateSubmit &&
            (await handleCreateSubmit({
              ...args,
              companyId,
            }))
      }
    } else {
      const args = {
        email: email.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        isAdmin,
        isGuildMaster,
        guildId: selectedGuild,
        teamAllocations: [],
        startDate,
        endDate,
        tribes: selectedTribes,
        staffAugmentation: isStaff,
        subscribedTribeIdsForEmail: selectedTribeIdsForEmailNotification,
        subscribedGuildIdsForEmail: selectedGuildIdsForEmailNotification,
        techStack: selectedTechStackList,
      }
      user
        ? handleEditSubmit &&
          (await handleEditSubmit({
            ...args,
            userId: user.id,
          }))
        : handleCreateSubmit &&
          (await handleCreateSubmit({
            ...args,
            companyId,
          }))
    }
    navigate(-1)
  }

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  useEffect(() => {
    getAllRoles()
  }, [])

  const shouldRenderLabelsSelect = () => {
    const isLeadershipRole = LeadershipRoleTypes.find(
      leadershipRoleType =>
        leadershipRoleType ===
        guilds.find(guild => guild.id === selectedGuild)?.type,
    )
    return !isLeadershipRole && Object.keys(teamAllocations).length > 0
  }

  const getTooltip = (team: Team) => {
    const today = new Date()
    const filteredEngagements = engagements
      .filter(engagement => {
        const engagementEndDate = new Date(engagement.endDate)
        return engagement.team.id === team.id && today < engagementEndDate
      })
      .sort(function (a, b) {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      })
    if (filteredEngagements.length === 0) {
      return (
        <div className="item-tooltip">
          <span className="item-tooltip-title">{'Open'}</span>
        </div>
      )
    }

    const startTime = moment(filteredEngagements[0].startDate).calendar()
    const endTime = moment(filteredEngagements[0].endDate).calendar()

    return (
      <div className="item-tooltip">
        <span className="item-tooltip-title">{`${filteredEngagements[0].client.name} - ${filteredEngagements[0].projectName}`}</span>
        <span>{`Start: ${startTime}`}</span>
        <span>{`End: ${endTime}`}</span>
      </div>
    )
  }

  const handleOnBack = e => {
    e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    if (experienceLevel && technologyList) {
      setExperience(experienceLevel)
      setTechnologies(technologyList)
    }
  }, [experienceLevel, technologyList])

  const addNewTechStackToEmployee = event => {
    event.preventDefault()
    if (
      !selectedTechnologies ||
      !selectedExperience ||
      selectedTechnologies === 0 ||
      selectedExperience === 0
    ) {
      return
    }

    const techStackWithExperienceExists = selectedTechStackList.find(
      techStack =>
        techStack.idExperienceLevel === selectedExperience &&
        techStack.idTechnology === selectedTechnologies,
    )

    const techStackExists = selectedTechStackList.find(
      techStack => techStack.idTechnology === selectedTechnologies,
    )

    if (techStackWithExperienceExists) {
      return
    }

    if (techStackExists) {
      setSelectedTechStackList(prevList =>
        prevList.map(techStack => {
          if (techStack.idTechnology === selectedTechnologies) {
            return {
              ...techStack,
              idExperienceLevel: selectedExperience,
            }
          }
          return techStack
        }),
      )
    }

    if (!techStackExists && !techStackWithExperienceExists) {
      const newTechStack = {
        idTechnology: selectedTechnologies,
        idExperienceLevel: selectedExperience,
      }
      setSelectedTechStackList(prevList => [...prevList, newTechStack])
    }
  }

  const experienceOptions = () => {
    return [...experience]
      .sort((a, b) => {
        return a.description.localeCompare(b.description)
      })
      .map((exp, index) => {
        return {
          label: exp.description,
          value: exp.id.toString(),
        }
      })
  }

  const technologiesOptions = () => {
    return technologies.map((tech, index) => {
      return {
        label: tech.name,
        value: tech.id.toString(),
      }
    })
  }

  return (
    <>
      <div
        className="modal fade"
        id="subscribeToEmailNotificationsModal"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Time Off Notification Email</h5>
              <button
                className="btn-close"
                onClick={() => {
                  setTempSelectedGuildIds(selectedGuildIdsForEmailNotification)
                  setTempSelectedTribeIds(selectedTribeIdsForEmailNotification)
                }}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                You can customize select which Tribes and Guides to receive
                notification of. I would like to receive information of:
              </p>
              <p>Select Tribes:</p>
              {tribes.map((tribe, index) => {
                return (
                  <div key={index} className="form-check col-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={tribe.id.toString()}
                      checked={tempSelectedTribeIds?.includes(tribe.id)}
                      onChange={e => {
                        e.target.checked
                          ? setTempSelectedTribeIds([
                              ...tempSelectedTribeIds,
                              tribe.id,
                            ])
                          : setTempSelectedTribeIds(
                              tempSelectedTribeIds?.filter(
                                tribeId => tribeId != tribe.id,
                              ),
                            )
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="myGuildOnlyCheck"
                    >
                      {tribe.name}
                    </label>
                  </div>
                )
              })}
              <p>Select Guilds:</p>
              {guilds.map((guild, index) => {
                return (
                  <div key={index} className="form-check col-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={guild.id.toString()}
                      checked={tempSelectedGuildIds?.includes(guild.id)}
                      onChange={e => {
                        e.target.checked
                          ? setTempSelectedGuildIds([
                              ...tempSelectedGuildIds,
                              guild.id,
                            ])
                          : setTempSelectedGuildIds(
                              tempSelectedGuildIds?.filter(
                                guildId => guildId != guild.id,
                              ),
                            )
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="myGuildOnlyCheck"
                    >
                      {guild.role}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() => {
                  setTempSelectedGuildIds(selectedGuildIdsForEmailNotification)
                  setTempSelectedTribeIds(selectedTribeIdsForEmailNotification)
                }}
                data-bs-dismiss="modal"
                className="btn btn-secondary me-2"
              >
                Cancel
              </button>
              <button
                data-bs-dismiss="modal"
                className="btn btn-primary"
                onClick={() => {
                  setSelectedGuildIdsForEmailNotification(tempSelectedGuildIds)
                  setSelectedTribeIdsForEmailNotification(tempSelectedTribeIds)
                }}
                disabled={false}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <form className="col-12" onSubmit={handleFormSubmit}>
        <div className="align-self-start mt-3">
          <p className="font-18 fw-bold">General Information</p>
          <div className="font-16 fw-bold">
            {'('}
            <p className="text-red d-inline">*</p>
            {')'}
            Required
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="createEmployeeEmailInput"
              className="font-16 fw-semibold"
            >
              Email <p className="text-red d-inline">*</p>
            </label>
            <input
              type="email"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              } ${email && !displayErrorBanner ? 'input-success' : ''}`}
              id="createEmployeeEmailInput"
              aria-describedby="emailHelp"
              value={email}
              disabled={!!user}
              onChange={e => {
                setEmail(e.target.value)
              }}
              aria-label="login-email-input"
            />
          </div>
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="createEmployeeFirstNameInput"
              className="font-16 fw-semibold timeoff-grey"
            >
              First Name <p className="text-red d-inline">*</p>
            </label>
            <input
              type="text"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'input-error' : ''
              } ${firstName && !displayErrorBanner ? 'input-success' : ''}`}
              id="createEmployeeFirstNameInput"
              aria-describedby="firstNameHelp"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value || '')
              }}
              aria-label="login-first-name-input"
              maxLength={25}
            />
          </div>
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="createEmployeeLastNameInput"
              className="font-16 fw-semibold timeoff-grey"
            >
              Last Name <p className="text-red d-inline">*</p>
            </label>
            <input
              type="text"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              } ${lastName && !displayErrorBanner ? 'input-success' : ''}`}
              id="createEmployeeLastNameInput"
              aria-describedby="lastNameHelp"
              value={lastName}
              onChange={e => {
                setLastName(e.target.value || '')
              }}
              aria-label="login-last-name-input"
              maxLength={25}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 form-group py-3">
            <div className="d-flex flex-column">
              <label className="font-16 fw-semibold timeoff-grey">
                Start Date <p className="text-red d-inline">*</p>
              </label>
              <DatePicker
                className={`form-control timeoff-input ${
                  startDate && !displayErrorBanner ? 'input-success' : ''
                }`}
                maxDate={endDate}
                selected={startDate}
                placeholderText={'Select a date'}
                onChange={date => {
                  if (date) {
                    if (endDate && date > endDate) {
                      setEndDate(date)
                    }
                    setStartDate(date)
                  }
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-4 form-group py-3">
            <div className="d-flex flex-column">
              <label className="font-16 fw-semibold timeoff-grey">
                End Date
              </label>
              <DatePicker
                className={`form-control timeoff-input ${
                  endDate && !displayErrorBanner ? 'input-success' : ''
                }`}
                selected={endDate}
                placeholderText={'Select a date'}
                minDate={startDate}
                isClearable
                onChange={date => {
                  if (date) {
                    if (!startDate) {
                      setEndDate(date)
                    } else if (date < startDate) {
                      setEndDate(startDate)
                    } else {
                      setEndDate(date)
                    }
                  } else {
                    setEndDate(null)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="font-18 fw-bold">Member Settings</p>
            <div className="form-check col-12">
              <input
                className="form-check-input"
                type="checkbox"
                id="isAdminCheck"
                checked={isAdmin}
                onChange={e => {
                  setIsAdmin(e.target.checked)
                  if (!e.target.checked) {
                    setSelectedGuildIdsForEmailNotification([])
                    setSelectedTribeIdsForEmailNotification([])
                  }
                }}
              />
              <label className="form-check-label" htmlFor="isAdminCheck">
                This member is an Admin
              </label>
            </div>
            <div className="form-check col-12 mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="isGuildMasterCheck"
                checked={isGuildMaster}
                onChange={e => {
                  setIsGuildMaster(e.target.checked)
                }}
              />
              <label className="form-check-label" htmlFor="isGuildMasterCheck">
                This member is a Guild Master
              </label>
            </div>

            <div className="form-check col-12 mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="isStaffCheck"
                checked={isStaff}
                onChange={e => {
                  setIsStaff(e.target.checked)
                }}
              />
              <label className="form-check-label" htmlFor="isStaffCheck">
                This member is in Staff Augmentation Loan
              </label>
            </div>
          </div>
          <div className="col" hidden={!isAdmin}>
            <p className="font-18 fw-bold">Time Off Notification Email</p>
            <p>
              An email will automatically be generated each week to show admin
              users who will be taking time off in the upcoming week. I would
              like to receive information of:
            </p>
            <div className="form-check col-12">
              <input
                className="form-check-input"
                type="checkbox"
                id="myTribeOnlyCheck"
                checked={user?.tribes?.every(userTribe => {
                  return selectedTribeIdsForEmailNotification?.includes(
                    userTribe.id,
                  )
                })}
                onChange={e => {
                  let currentSelectedTribeIds =
                    selectedTribeIdsForEmailNotification
                  const userTribes = user?.tribes.map(tribe => tribe.id) ?? []
                  e.target.checked
                    ? (currentSelectedTribeIds = [
                        ...selectedTribeIdsForEmailNotification,
                        ...userTribes,
                      ])
                    : (currentSelectedTribeIds =
                        selectedTribeIdsForEmailNotification?.filter(
                          tribeId => {
                            userTribes.includes(tribeId)
                          },
                        ))
                  setSelectedTribeIdsForEmailNotification(
                    currentSelectedTribeIds,
                  )
                  setTempSelectedTribeIds(currentSelectedTribeIds)
                }}
              />
              <label className="form-check-label" htmlFor="myTribeOnlyCheck">
                My Tribe Only
              </label>
            </div>
            <div className="">
              <div className="form-check col-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="myGuildOnlyCheck"
                  checked={selectedGuildIdsForEmailNotification?.includes(
                    user?.guildId ?? 0,
                  )}
                  onChange={e => {
                    let currentSelectedGuildIds =
                      selectedGuildIdsForEmailNotification
                    e.target.checked && user?.guildId
                      ? (currentSelectedGuildIds = [
                          ...selectedGuildIdsForEmailNotification,
                          user?.guildId,
                        ])
                      : (currentSelectedGuildIds =
                          selectedGuildIdsForEmailNotification?.filter(
                            guildId => guildId != user?.guildId,
                          ))
                    setSelectedGuildIdsForEmailNotification(
                      currentSelectedGuildIds,
                    )
                    setTempSelectedGuildIds(currentSelectedGuildIds)
                  }}
                />
                <label className="form-check-label" htmlFor="myGuildOnlyCheck">
                  My Guild Only
                </label>
              </div>
              <p
                className="text-primary"
                data-bs-toggle="modal"
                data-bs-target="#subscribeToEmailNotificationsModal"
                style={{
                  cursor: 'pointer',
                }}
              >
                <u>More Options</u>
              </p>
            </div>
          </div>
          <div className="align-self-start mt-3">
            <p className="font-18 fw-bold mb-0">Guild & Team Information</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-3 form-group py-3">
              <label
                htmlFor="createEmployeeGuildInput"
                className="font-16 fw-semibold timeoff-grey"
              >
                Guild <p className="text-red d-inline">*</p>
              </label>
              <select
                className="form-select"
                value={selectedGuild}
                id="createEmployeeGuildInput"
                onChange={e => {
                  setSelectedGuild(Number(e.target.value))
                  setSelectedTeams([])
                  setTeamAllocations({})
                }}
              >
                <option hidden key={`no-guild`} value={''}>
                  Select an option
                </option>
                {guilds.map((guild, index) => (
                  <option key={`guild-${index}`} value={guild.id}>
                    {guild.role}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="font-18 fw-bold">Member TechStack</p>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-md-4 form-group">
            <label
              htmlFor="createEmployeeTechnologyInput"
              className="font-16 fw-semibold timeoff-grey"
            >
              Technology List
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="selectedTechnologies"
              options={technologiesOptions()}
              onChange={option => {
                if (option) {
                  setSelectedTechnologies(Number(option?.value))
                } else {
                  setSelectedTechnologies(0)
                }
              }}
            />
          </div>
          <div className="col-4 col-md-4 form-group">
            <label
              htmlFor="createEmployeeExperienceInput"
              className="font-16 fw-semibold timeoff-grey"
            >
              Experience Level
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="selectedExperience"
              options={experienceOptions()}
              onChange={option => {
                if (option) {
                  setSelectedExperience(Number(option?.value))
                } else {
                  setSelectedExperience(0)
                }
              }}
            />
          </div>
          <div className="col-4 col-md-4 form-group align-items-center">
            <button
              className="btn btn-secondary me-4"
              style={{ fontWeight: '500', marginTop: '25px' }}
              onClick={addNewTechStackToEmployee}
              aria-label="login-submit-button"
            >
              Add TechStack +
            </button>
          </div>
        </div>
        {displayTechListForUser && (
          <div className="row">
            <div className="col-12 col-md-12 form-group align-items-center py-2">
              <label
                htmlFor="createEmployeeExperienceInput"
                className="font-16 fw-semibold timeoff-grey"
              >
                Selected Technology list
              </label>
              <div className="py-2">
                {selectedTechStackList.map((selectedTechStack, index) => {
                  const buttonStyle = {
                    backgroundColor:
                      colorMapping[selectedTechStack.idExperienceLevel] ||
                      '#7f39fb',
                    color:
                      selectedTechStack.idExperienceLevel === 1
                        ? 'black'
                        : 'white',
                  }

                  return (
                    <button
                      key={`selectedTechStack-${index}`}
                      className="col-auto pill-remove mb-2 mx-6 ms-3 text-truncate py-3"
                      style={buttonStyle}
                      onClick={e => {
                        e.preventDefault()
                        setSelectedTechStackList(prevList =>
                          prevList.filter((techStack, i) => i !== index),
                        )
                      }}
                    >
                      <div
                        key={index}
                        className="d-flex align-items-center mb-0  text-truncate"
                      >
                        <FontAwesomeIcon
                          icon={faX}
                          size="xs"
                          className="me-1"
                        />
                        <div className="ms-2 d-block">
                          <span className="d-block fw-bold fs-8">
                            {
                              technologies.find(
                                tech =>
                                  tech.id === selectedTechStack.idTechnology,
                              )?.name
                            }
                          </span>
                          <span className="d-block">
                            {
                              experienceLevel.find(
                                exp =>
                                  exp.id ===
                                  selectedTechStack.idExperienceLevel,
                              )?.description
                            }
                          </span>
                        </div>
                      </div>
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="createEmployeeteamInput"
              className="font-16 fw-semibold timeoff-grey pb-2"
            >
              Select a Tribe
            </label>
            <div className="row col-10 ps-2">
              {selectedTribes.map(
                tribe =>
                  tribe.id && (
                    <button
                      key={`tribe-${tribe.id}`}
                      className="col-auto pill-remove py-2 mb-2 mx-2 text-truncate"
                      onClick={e => {
                        e.preventDefault()
                        setSelectedTribes([
                          ...selectedTribes.filter(
                            newTribe => newTribe.id !== tribe.id,
                          ),
                        ])
                        const newTeams = selectedTeams.filter(
                          team => team.tribeId !== tribe.id,
                        )
                        const newAllocations = {
                          ...teamAllocations,
                        }
                        Object.keys(newAllocations).forEach(key => {
                          if (!newTeams.find(team => team.id === parseInt(key)))
                            delete newAllocations[parseInt(key)]
                        })
                        setTeamAllocations({
                          ...newAllocations,
                        })
                        setSelectedTeams([...newTeams])
                      }}
                    >
                      <div className="d-inline mb-0 text-truncate">
                        <FontAwesomeIcon
                          icon={faX}
                          size="xs"
                          className="me-1"
                        />
                        {tribe.name}
                      </div>
                    </button>
                  ),
              )}
            </div>
            <div className="row ps-2">
              {tribes.length === 0 ? (
                <p>
                  {`There aren't tribes avalaible ${selectedTribes.length}`}{' '}
                </p>
              ) : (
                tribes.map(
                  tribe =>
                    tribe.id &&
                    !selectedTribes.includes(tribe) && (
                      <button
                        key={`tribe-${tribe.id}`}
                        className="col-auto pill-add py-1 mb-2 mx-2 text-truncate"
                        onClick={() =>
                          tribe.id &&
                          setSelectedTribes([...selectedTribes, tribe])
                        }
                      >
                        <div className="d-inline mb-0 text-truncate">
                          <FontAwesomeIcon
                            icon={faPlus}
                            size="xs"
                            className="me-1"
                          />
                          {tribe.name}
                        </div>
                      </button>
                    ),
                )
              )}
            </div>
          </div>

          <div className="col-12 col-md-6 form-group py-3">
            {tribes.length > 0 && (
              <>
                <label
                  htmlFor="createEmployeeteamInput"
                  className="font-16 fw-semibold timeoff-grey pb-2"
                >
                  Select a Team
                </label>
                <div className="row col-10 ps-2">
                  {selectedTeams.map(
                    team =>
                      team.id && (
                        <Tippy
                          key={`team-${team.id}`}
                          content={getTooltip(team)}
                          followCursor="horizontal"
                          plugins={[followCursor]}
                        >
                          <button
                            className="col-auto pill-remove py-2 mb-2 mx-2 text-truncate"
                            onClick={e => {
                              e.preventDefault()
                              const newTeams = selectedTeams.filter(
                                newTeam => newTeam.id !== team.id,
                              )
                              const newAllocations = {
                                ...teamAllocations,
                              }
                              Object.keys(newAllocations).forEach(key => {
                                if (
                                  !newTeams.find(
                                    team => team.id === parseInt(key),
                                  )
                                )
                                  delete newAllocations[parseInt(key)]
                              })
                              setTeamAllocations({
                                ...newAllocations,
                              })
                              setSelectedTeams([...newTeams])
                            }}
                          >
                            <div className="d-inline mb-0 text-truncate">
                              <FontAwesomeIcon
                                icon={faX}
                                size="xs"
                                className="me-1"
                              />
                              {team.name}
                            </div>
                          </button>
                        </Tippy>
                      ),
                  )}
                </div>
                <div className="row ps-2">
                  {selectableTeams.length === 0 &&
                  selectedTeams.length === 0 ? (
                    <p>Select a tribe to see its teams</p>
                  ) : (
                    selectableTeams
                      .filter(team => {
                        if (
                          !team.endDate ||
                          moment().isSameOrBefore(moment(team.startDate))
                        )
                          return true
                        return moment().isBetween(
                          moment(team.startDate),
                          moment(team.endDate),
                          undefined,
                          '[]',
                        )
                      })
                      .map(
                        team =>
                          team.id &&
                          !selectedTeams.includes(team) && (
                            <Tippy
                              key={`team-${team.id}`}
                              content={getTooltip(team)}
                              followCursor="horizontal"
                              plugins={[followCursor]}
                            >
                              <button
                                className="col-auto pill-add py-1 mb-2 mx-2 text-truncate"
                                onClick={() => {
                                  setSelectedTeams([...selectedTeams, team])
                                  const newAllocations = {
                                    ...teamAllocations,
                                  }
                                  newAllocations[team.id] = {
                                    allocation: 0,
                                    role: '',
                                  }
                                  setTeamAllocations({
                                    ...newAllocations,
                                  })
                                }}
                              >
                                <div className="d-inline mb-0 text-truncate">
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    size="xs"
                                    className="me-1"
                                  />
                                  {team.name}
                                </div>
                              </button>
                            </Tippy>
                          ),
                      )
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row">
          {Object.keys(teamAllocations).length > 0 && (
            <>
              <label
                htmlFor="createEmployeeteamInput"
                className="font-16 fw-semibold timeoff-grey pb-2"
              >
                {'Team Allocation (Capacity %)'}
              </label>
              {Object.keys(teamAllocations).map(key => {
                const teamId = parseInt(key)
                return (
                  <div
                    key={`team-allocation-${key}`}
                    className="col-auto py-1 mb-2 d-flex flex-row align-items-center mr-2 text-truncate"
                  >
                    <div>
                      <label
                        htmlFor="createEmployeeteamInput"
                        className="font-16 fw-semibold timeoff-grey pb-2"
                      >
                        {teams.find(team => team.id === parseInt(key))?.name ||
                          ''}
                      </label>
                      <input
                        className={`form-control timeoff-input ${
                          allocationError ? 'input-error' : ''
                        }`}
                        type="number"
                        min={0}
                        max={100}
                        value={`${teamAllocations[teamId].allocation}`}
                        onChange={e => {
                          if (allocationError) setAllocationError(false)
                          const changedAllocations = {
                            ...teamAllocations,
                          }
                          const newAllocation = parseInt(
                            (e.target.value || '0').replace(/%/g, ''),
                          )
                          changedAllocations[teamId].allocation =
                            newAllocation > 100 ? 100 : newAllocation
                          setTeamAllocations({
                            ...changedAllocations,
                          })
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        <div className="row">
          {shouldRenderLabelsSelect() && (
            <>
              <label
                htmlFor="createEmployeeTeamRoleSelect"
                className="font-16 fw-semibold timeoff-grey pb-2"
              >
                {'Label as Team Lead or Support'}
              </label>
              {Object.keys(teamAllocations).map(key => {
                const teamId = parseInt(key)
                return (
                  <div
                    key={`team-role-${key}`}
                    className="col-auto py-1 mb-2 d-flex flex-row align-items-center mr-2 text-truncate"
                  >
                    <div>
                      <label
                        htmlFor="createEmployeeRoleSelect"
                        className="font-16 fw-semibold timeoff-grey pb-2"
                      >
                        {teams.find(team => team.id === parseInt(key))?.name ||
                          ''}
                      </label>
                      <select
                        className="form-select"
                        value={teamAllocations[teamId].role}
                        id="createEmployeeRoleSelect"
                        onChange={e => {
                          const allocations = {
                            ...teamAllocations,
                          }
                          allocations[teamId] = {
                            allocation: teamAllocations[teamId].allocation,
                            role: e.target.value,
                          }
                          setTeamAllocations(allocations)
                        }}
                      >
                        <option key={`no-role`} value={''}>
                          No Label
                        </option>
                        {TeamRoles[
                          guilds.find(guild => guild.id === selectedGuild)
                            ?.type ?? ''
                        ]?.map((teamLabel, index) => (
                          <option key={`team-role-${index}`} value={teamLabel}>
                            {teamLabel}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        <div className="py-2 d-flex justify-content-center">
          <div>
            <button
              className="btn btn-secondary me-4"
              style={{ fontWeight: '500' }}
              onClick={handleOnBack}
              aria-label="login-submit-button"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary px-4"
              style={{ fontWeight: '500' }}
              disabled={
                !validateEmail(email) ||
                !firstName ||
                !lastName ||
                !startDate ||
                !selectedGuild ||
                loading
              }
              aria-label="login-submit-button"
            >
              {user ? 'Save' : 'Add Member'}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
