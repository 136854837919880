import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Tribe } from '../../types/Tribe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faSearch,
  faPlus,
  faArrowUpAZ,
  faArrowDownAZ,
} from '@fortawesome/free-solid-svg-icons'
import {
  useCreateTribeMutation,
  useLazyGetAllTribesQuery,
} from '../../redux/tribe.endpoints'
import { User } from '../../types/User'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import moment from 'moment-timezone'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { handleSort, sortObject } from '../../utils/general'

interface Props {
  user: User
}

const TribesView = ({ user }: Props) => {
  const navigate = useNavigate()
  const [tribes, setTribes] = useState([] as Tribe[])
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [newTribeName, setNewTribeName] = useState('')
  const [showInTeamUtilization, setShowInTeamUtilization] = useState(true)
  const [createTribe, createTribeResult] = useCreateTribeMutation()
  const [sortedTribes, setSortedTribes] = useState([] as Tribe[])
  const [getTribes, { data: tribesData }] = useLazyGetAllTribesQuery()
  const [sortColumn, setSortColumn] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<string>('asc')
  const [sortOrderIcon, setSortOrderIcon] = useState({
    name: faArrowUpAZ,
    created_at: faArrowUpAZ,
  })
  const clearSearch = () => {
    getTribes({
      companyId: user.company.id,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    setSearchQuery('')
    setIsSearch(false)
  }
  useEffect(() => {
    getTribes({
      companyId: user.company.id,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
  }, [])

  useEffect(() => {
    setTribes(tribesData || [])
  }, [tribesData])

  useEffect(() => {
    if (createTribeResult && createTribeResult.isSuccess) {
      getTribes({
        companyId: user.company.id,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery: '',
        sortType: 'DESC',
      })
    } else if (createTribeResult && createTribeResult.error) {
      const error: any = createTribeResult.error
      toast.error(
        `An error occurred when creating the tribe: ${error.data.error}`,
        {
          toastId: 'create-tribe-error',
        },
      )
    }
    setNewTribeName('')
  }, [createTribeResult])

  const handleSearch = () => {
    if (searchQuery.length >= 3) {
      getTribes({
        companyId: user.company.id,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery,
        sortType: 'DESC',
      })
      setIsSearch(true)
    } else {
      toast.info('Query Search Must Be More Than 3 Characters', {
        toastId: 'tribe-search',
      })
    }
  }

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSortBy = (sortBy: string) => {
    const { newSortColumn, newSortOrder } = handleSort(
      sortBy,
      sortColumn,
      sortOrder,
    )
    setSortColumn(newSortColumn)
    setSortOrder(newSortOrder)

    setSortOrderIcon(prevState => {
      return {
        ...prevState,
        [sortBy]: newSortOrder === 'asc' ? faArrowUpAZ : faArrowDownAZ,
      }
    })
  }

  useEffect(() => {
    const sortedData = sortObject(sortedTribes, sortColumn, sortOrder)
    setSortedTribes([...sortedData])
  }, [sortColumn, sortOrder])

  useEffect(() => {
    const sorted = [...tribes].sort((a, b) => a.name.localeCompare(b.name))
    setSortedTribes(sorted)
  }, [tribes])

  return (
    <div className="container my-4">
      <div className="modal fade" id="addTribeModal" tabIndex={-1}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Tribe</h5>
              <button
                className="btn-close"
                onClick={() => setNewTribeName('')}
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-grid">
                <label className="my-2">Name</label>
                <input
                  placeholder="Enter name here"
                  className="form-control"
                  type="text"
                  name="tribe-name"
                  value={newTribeName}
                  onChange={e => setNewTribeName(e.target.value)}
                  maxLength={25}
                />
                <div className="form-group pt-3 d-flex justify-content-between">
                  <div className="form-check hover-dark-blue">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="rememberMeCheck"
                      defaultChecked
                      checked={showInTeamUtilization}
                      onClick={() =>
                        setShowInTeamUtilization(!showInTeamUtilization)
                      }
                      role="button"
                    />
                    <label
                      className="form-check-label not-selectable font-weight-bold timeoff-grey remember-me-label cursor-pointer text-decoration-none"
                      htmlFor="rememberMeCheck"
                    >
                      Show in Team Utilization
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                onClick={() => setNewTribeName('')}
                data-bs-dismiss="modal"
                className="btn btn-secondary me-2"
              >
                Cancel
              </button>
              <button
                data-bs-dismiss="modal"
                className="btn btn-primary"
                onClick={() =>
                  createTribe({
                    name: newTribeName.trim(),
                    isActive: true,
                    description: '',
                    companyId: user.company.id,
                    isDeliveryTribe: !showInTeamUtilization,
                  })
                }
                disabled={newTribeName.length == 0}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <Helmet title="Time Off - Tribes" />
      <p className="fs-2 fw-bold">Manage Tribes</p>
      <div className="d-flex justify-content-between">
        <div>
          <label className="">Search</label>
          <div className="input-group mb-3 input-group-border">
            <input
              type="text"
              className="form-control border border-0 outline-0"
              placeholder="Search by Tribe"
              aria-label="Search by Tribe"
              aria-describedby="button-addon2"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            {isSearch ? (
              <span
                className="input-group-text border border-0 search"
                onClick={() => clearSearch()}
              >
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={handleSearch}
                className="input-group-text border border-0 search"
              >
                <FontAwesomeIcon icon={faSearch} />
              </span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button className="btn btn-primary" data-bs-target="#addTribeModal">
            <FontAwesomeIcon className="me-2" icon={faPlus} />
            Add New Tribe
          </button>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="col-md-3" scope="col">
              Tribe
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={sortOrderIcon.name}
                  onClick={() => handleSortBy('name')}
                />
              </span>
            </th>
            <th className="col-md-3" scope="col">
              Created On
              <span className="ms-2">
                <FontAwesomeIcon
                  icon={sortOrderIcon.created_at}
                  onClick={() => handleSortBy('created_at')}
                />
              </span>
            </th>
            <th className="col-md-3" scope="col">
              Teams
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {sortedTribes.map((tribe, index) => {
            return (
              <tr
                className="pointer-cursor"
                onClick={() => navigate(`/tribes/${tribe.id}`)}
                key={index}
              >
                <td>{tribe.name}</td>
                <td>{moment(tribe.created_at).format('MM/DD/YYYY')}</td>
                <td>
                  {tribe.teams
                    .filter(
                      team =>
                        !team.endDate ||
                        new Date(team.endDate).valueOf() > new Date().valueOf(),
                    )
                    .map(team => {
                      return team.name
                    })
                    .join(', ')}
                </td>
                <td className="text-end pe-5">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {tribes.length == 0 && <h2 className="text-center">No tribes found</h2>}
    </div>
  )
}

export default TribesView
