import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { User } from '../../types/User'
import {
  useLazyGetTribeByIdQuery,
  useUpdateTribeMutation,
} from '../../redux/tribe.endpoints'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router'
import { Tribe } from '../../types/Tribe'
import Loader from '../../components/Loader/Loader'
import moment from 'moment-timezone'
import { Team } from '../../types/Team'

interface Props {
  user: User
}

const EditTribe = ({ user }: Props) => {
  const navigate = useNavigate()
  const { tribeId } = useParams()
  const [getTribe, getTribeResponse] = useLazyGetTribeByIdQuery()
  const [tribe, setTribe] = useState<Tribe>({
    id: 0,
    name: '',
    description: '',
    isActive: false,
    teams: [],
    created_at: '',
    isDeliveryTribe: false,
  })
  const [showInTeamUtilization, setShowInTeamUtilization] = useState(true)
  const [updatedTribeName, setUpdatedTribeName] = useState('')
  const [updateTribe, updateTribeResult] = useUpdateTribeMutation()
  const [sortedTeams, setSortedTeams] = useState([] as Team[])

  useEffect(() => {
    getTribe({
      companyId: user.company.id,
      tribeId: parseInt(tribeId || '0'),
    })
  }, [])

  useEffect(() => {
    if (getTribeResponse.data) {
      setTribe(getTribeResponse.data)
      setUpdatedTribeName(getTribeResponse.data.name)
      setShowInTeamUtilization(!getTribeResponse.data.isDeliveryTribe)
    }
  }, [getTribeResponse.data])

  useEffect(() => {
    if (updateTribeResult && updateTribeResult.isError) {
      const error: any = updateTribeResult.error
      toast.error(
        `An error occurred when creating the tribe: ${error.data.error}`,
        {
          toastId: 'create-tribe-error',
        },
      )
    } else if (updateTribeResult && updateTribeResult.isSuccess) {
      navigate('/tribes')
    }
  }, [updateTribeResult])

  useEffect(() => {
    const sorted = [...tribe.teams].sort((a, b) => a.name.localeCompare(b.name))
    setSortedTeams(sorted)
  }, [tribe.teams])

  return (
    <div className="container py-2 position-relative">
      {getTribeResponse.isLoading && <Loader />}
      <div>
        <h1>Edit Tribe</h1>
        <p className="mb-0">
          <strong>General Information</strong>
        </p>
        <p>
          <span>
            (<span className="text-danger">*</span>)
          </span>
          <strong>Required</strong>
        </p>
      </div>
      <div className="w-50" style={{ maxWidth: '350px' }}>
        <label>
          Tribe Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          value={updatedTribeName}
          onChange={e => setUpdatedTribeName(e.target.value)}
          maxLength={25}
        />
      </div>
      <div>
        <div className="form-group pt-3 d-flex justify-content-between">
          <div className="form-check hover-dark-blue">
            <input
              type="checkbox"
              className="form-check-input"
              id="rememberMeCheck"
              defaultChecked
              checked={showInTeamUtilization}
              onClick={() => setShowInTeamUtilization(!showInTeamUtilization)}
              role="button"
            />
            <label
              className="form-check-label not-selectable font-weight-bold timeoff-grey remember-me-label cursor-pointer text-decoration-none"
              htmlFor="rememberMeCheck"
            >
              Show in Team Utilization
            </label>
          </div>
        </div>
      </div>
      <div className="teams-wrapper">
        <div className="mt-5 d-flex justify-content-between">
          <h4>Create Teams</h4>
          <div className="d-flex align-items-center">
            <button
              onClick={() => navigate(`/tribes/${tribe.id}/create-team`)}
              className="btn btn-primary px-4"
            >
              <FontAwesomeIcon className="me-2" icon={faPlus} />
              Add Team
            </button>
          </div>
        </div>
        <table className="table my-2">
          <thead>
            <tr>
              <th className="col-md-3">Team ID</th>
              <th>Created On</th>
              <th>Team Start</th>
              <th>Disband Date</th>
            </tr>
          </thead>
          <tbody>
            {sortedTeams.map(team => {
              return (
                <tr
                  className="pointer-cursor"
                  onClick={() => navigate(`/tribe/team/${team.id}`)}
                  key={team.id}
                >
                  <td>{team.name}</td>
                  <td>{moment(team.created_at).format('MM/DD/YYYY')}</td>
                  <td>{moment(team.startDate).format('MM/DD/YYYY')}</td>
                  <td>
                    {team.endDate
                      ? moment(team.endDate).format('MM/DD/YYYY')
                      : 'No End Date'}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="button-container">
          <div className="tribe-action-controls">
            <button
              className="btn btn-secondary me-4 px-4"
              onClick={() => navigate('/tribes')}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary px-4"
              disabled={
                (updatedTribeName.length == 0 ||
                  updatedTribeName.trim() === tribe.name) &&
                showInTeamUtilization === !tribe.isDeliveryTribe
              }
              onClick={() =>
                updateTribe({
                  id: tribe.id || 0,
                  name: updatedTribeName,
                  description: '',
                  isActive: true,
                  companyId: user.company.id,
                  isDeliveryTribe: !showInTeamUtilization,
                })
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditTribe
