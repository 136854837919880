import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import bamboohrLogo from '../../assets/bamboohr_logo.png' // Adjust the path accordingly

type CustomButtonProps = {
  onClick: () => void
}
const CustomButton = ({ onClick }: CustomButtonProps) => {
  return (
    <button
      className="btn btn-secondary"
      style={{
        border: '2px solid #7F39FB',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        backgroundColor: 'white',
        marginRight: '4px',
      }}
      onClick={onClick}
    >
      <img
        src={bamboohrLogo}
        alt="BambooHR Logo"
        style={{ height: '18px', width: '113px', marginRight: '10px' }}
      />
      <FontAwesomeIcon icon={faCloudUploadAlt} color="#7F39FB" size="sm" />
    </button>
  )
}

export default CustomButton
