import React, { useEffect, useState } from 'react'
import { UsersTimesOff, UsersTribeOrg } from '../../types/TimeOff'
import { parseTribes } from '../../types/Tribe'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { Role } from '../../types/Roles'
import moment from 'moment'

interface Props {
  usersInfo: UsersTimesOff[] | UsersTribeOrg[]
}

export const ReportTableTribeOrgContent = ({ usersInfo }: Props) => {
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])
  
  useEffect(() => {
    getAllRoles()
  }, [])
  
  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  const getStatus = (date) => {
    if(!date) return "Active"
    const today = new Date()
    const userEndDate = new Date(date)

    return userEndDate < today ? "Inactive" : "Active"
  }

  return (
    <tbody>
      {usersInfo.map((employee, index: number) => {
        const tribes = parseTribes(employee.tribes)
        const teams = 
          employee.teamAllocations
          ?.map(allocation => `${allocation.team.name} (${allocation.allocation}%)` || '')
          .filter(val => !!val)
          .join(', ') || ''
        return (
          <tr key={index}>
            <td>
              {employee.firstName} {employee.lastName}
            </td>
            <td>{getStatus(employee.end_on)}</td>
            <td>{guilds.find(guild=>guild.id===employee.guildId)?.role??""}</td>
            <td>{tribes}</td>
            <td>{teams}</td>
            <td>{moment(employee.start_on).format('MM/DD/YYYY')}</td>
          </tr>
        )
      })}
    </tbody>
  )
}
