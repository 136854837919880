import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { InputGroup, Form} from 'react-bootstrap';
import '../../css/customDateInput.scss';


export interface CustomDateInputProps {
  value: any;
  onClick: ()=>void;
  forwardedRef: any;
}

const CustomDateInput = ({value, onClick, forwardedRef} : CustomDateInputProps) => {
  return (
    <div className="input-group" ref={forwardedRef}>
      <InputGroup className="mb-3">
        <Form.Control
          className="form-control"
          placeholder="12/12/12"
          value={value}
          onClick={onClick}
          readOnly
        />
        <InputGroup.Text id="right-icon"> {!value && <FontAwesomeIcon className="fa-icon" icon={faCalendar} /> }</InputGroup.Text>
      </InputGroup>
    </div>
  );
}

export default CustomDateInput;