import { Dispatch, useEffect, useState } from 'react'

const usePersistentState = <T,>(
  key: string,
  initialValue: T,
): readonly [T, Dispatch<T>] => {
  const storageKey = `${key}`
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(storageKey)
    return storedValue ? JSON.parse(storedValue) : initialValue
  })

  useEffect(() => {
    const storedValue = localStorage.getItem(storageKey)
    if (storedValue) {
      setState(JSON.parse(storedValue))
    }
  }, [storageKey])

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state))
  }, [storageKey, state])

  return [state, setState] as const
}

export default usePersistentState
