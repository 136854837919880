import { AllGuildTypes } from "../types/Roles"

export const leadershipDefaultValues = {
  [AllGuildTypes.ADM]: {
    firstName: '---',
    lastName: '',
    guildId: -1,
    email: 'N/A',
  },
  [AllGuildTypes.BA]: {
    firstName: '---',
    lastName: '',
    guildId: -1,
    email: 'N/A',
  },
  [AllGuildTypes.SM]: {
    firstName: '---',
    lastName: '',
    guildId: -1,
    email: 'N/A',
  },
}

export const teamDefaultValues = {
  [AllGuildTypes.Developer]: [
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
  ],
  [AllGuildTypes.QA]: [
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
  ],
  [AllGuildTypes.Design]: [
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
  ],
  [AllGuildTypes.DevOps]: [
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
    {
      firstName: '---',
      lastName: '',
      guildId: -1,
      email: 'N/A',
      role: '',
    },
  ],
}

export const engagementsDefaultValues = {
  endDate: 'N/A',
  projectName: 'Open',
  startDate: 'N/A',
}
