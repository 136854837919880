/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EngagementForm } from '../../components/EngagementForm/EngagementForm'
import Loader from '../../components/Loader/Loader'
import { useGetAllClientsQuery } from '../../redux/client.endpoints'
import {
  useLazyGetEngagementByIdQuery,
  useUpdateEngagementMutation,
} from '../../redux/engagement.endpoints'
import { useGetAllTeamsQuery } from '../../redux/team.endpoints'
import { Engagement, UpdateEngagementDto } from '../../types/Engagement'

interface Props {
  companyId: number
}

const EngagementEdit = ({ companyId }: Props) => {
  const navigate = useNavigate()

  const { engagementId } = useParams()
  const [getEngagement, getEngagementResponse] = useLazyGetEngagementByIdQuery()
  const [engagement, setEngagement] = useState<Engagement>({} as Engagement)

  useEffect(() => {
    getEngagement({
      id: parseInt(engagementId || '0'),
      companyId,
    })
  }, [])

  useEffect(() => {
    if (getEngagementResponse.data) {
      setEngagement(getEngagementResponse.data)
    }
    if (getEngagementResponse.error) {
      navigate('/engagements')
    }
  }, [getEngagementResponse])

  const {
    data: clients,
    isSuccess: clientsSuccess,
    refetch: refetchClients,
  } = useGetAllClientsQuery(`${companyId}`)
  const {
    data: teams,
    isSuccess: teamsSuccess,
    refetch: refetchTeams,
  } = useGetAllTeamsQuery(companyId)
  const [updateEngagement, updateEngagementResult] =
    useUpdateEngagementMutation()
  const handleEditEngagement = async (args: UpdateEngagementDto) => {
    updateEngagement(args)
  }

  useEffect(() => {
    refetchClients()
    refetchTeams()
  }, [])

  useEffect(() => {
    if (updateEngagementResult && updateEngagementResult.isError) {
      if (updateEngagementResult.error) {
        const error = updateEngagementResult.error
        if ('data' in error) {
          const errorMessage = error.data as any

          if ('error' in errorMessage) {
            toast.error(errorMessage.error, {
              toastId: 'edit-engagement-error',
            })
          }
        }
      } else {
        toast.error(`An error occurred when updating the engagement.`, {
          toastId: 'edit-engagement-error',
        })
      }
    } else if (updateEngagementResult && updateEngagementResult.isSuccess) {
      toast.success(`Engagement updated successfully!`, {
        toastId: 'edit-engagement-success',
      })
      navigate('/engagements')
    }
  }, [updateEngagementResult])

  return (
    <>
      <Helmet title="Time Off - Update Engagement" />
      <main className="container-fluid scrollable">
        <div className={`row pt-3 ${isDesktop || isTablet ? 'h-100' : ''}`}>
          <div className="col-8 offset-2 d-flex flex-column align-items-center justify-content-center bg-lazuli-white pb-5">
            <p className="align-self-start font-30 fw-bold">Edit Engagement</p>
            {teamsSuccess && clientsSuccess ? (
              <EngagementForm
                handleEditSubmit={handleEditEngagement}
                displayErrorBanner={
                  updateEngagementResult && updateEngagementResult.isError
                }
                loading={
                  updateEngagementResult && updateEngagementResult.isLoading
                }
                companyId={companyId}
                clients={clients || []}
                teams={teams || []}
                engagement={engagement}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default EngagementEdit
