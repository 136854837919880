import React, { useState } from 'react'
import { CreateCompanyDto } from '../../types/Company'
import { validateEmail } from '../../utils/validations'
import moment_tz from 'moment-timezone'
import { countries } from '../../utils/enums'
interface Props {
  handleCreateCompany: (args: CreateCompanyDto) => Promise<void>
  displayErrorBanner?: boolean
  loading: boolean
}

export const CreateCompanyForm = ({
  handleCreateCompany,
  displayErrorBanner,
  loading,
}: Props) => {
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('US')
  const [timezone, setTimezone] = useState('EST')
  const timezonesList = moment_tz.tz.names()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateEmail(email)) {
      handleCreateCompany({
        email: email.trim(),
        companyName: companyName.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        country,
        timezone,
      })
    }
  }

  return (
    <>
      <form className="col-6" onSubmit={handleSubmit}>
        <h1 className="text-center">Create Company</h1>

        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="email font-weight-bold timeoff-grey "
              className="login-form-label-style"
            >
              Email
            </label>
            <input
              type="email"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="createCompanyEmailInput"
              aria-describedby="emailHelp"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
              aria-label="login-email-input"
            />
          </div>
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="createCompanyNameInput"
              className="login-form-label-style font-weight-bold timeoff-grey"
            >
              Company Name
            </label>
            <input
              type="text"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="createCompanyNameInput"
              aria-describedby="NameHelp"
              value={companyName}
              onChange={e => {
                setCompanyName(e.target.value || '')
              }}
              aria-label="create-company-name-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="createCompanyFirstNameInput"
              className="login-form-label-style font-weight-bold timeoff-grey"
            >
              First Name
            </label>
            <input
              type="text"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="createCompanyFirstNameInput"
              aria-describedby="firstNameHelp"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value || '')
              }}
              aria-label="login-first-name-input"
            />
          </div>
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="createCompanyLastNameInput"
              className="login-form-label-style font-weight-bold timeoff-grey"
            >
              Last Name
            </label>
            <input
              type="text"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="createCompanyLastNameInput"
              aria-describedby="lastNameHelp"
              value={lastName}
              onChange={e => {
                setLastName(e.target.value || '')
              }}
              aria-label="login-last-name-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <select
              className="form-select"
              value={timezone}
              onChange={e => setTimezone(e.target.value)}
            >
              {timezonesList.map(tz => (
                <option key={tz} value={tz}>
                  {tz}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-6 form-group py-3">
            <select
              className="form-select"
              value={country}
              onChange={e => setCountry(e.target.value)}
            >
              {countries.map(country => (
                <option key={country.code} value={country.code}>
                  {country.code}: {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="py-2 d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bg-timeoff-blue py-1 py-md-2 shadow-box"
            style={{ fontWeight: '500' }}
            disabled={
              !validateEmail(email) ||
              !firstName.trim() ||
              !lastName.trim() ||
              !country ||
              !timezone ||
              !companyName.trim() ||
              loading
            }
            aria-label="login-submit-button"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  )
}
