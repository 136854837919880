import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { validateEmail } from '../../utils/validations'
import { featureFlags } from '../FeatureFlagsContextProvider/FeatureFlagsContextProvider'

interface Props {
  handleLogin: (email: string, password: string) => Promise<void>
  displayErrorBanner?: boolean
  loading: boolean
}

export const LoginForm = ({
  handleLogin,
  displayErrorBanner,
  loading,
}: Props) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { isCompanyCreationEnabled } = useContext(featureFlags)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateEmail(email) && password) {
      handleLogin(email, password)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ minWidth: '20rem' }}>
        <h1 className="text-center">Login</h1>

        <div
          className={`form-group py-3 ${
            isCompanyCreationEnabled ? 'px-lg-4 mx-lg-4' : 'col-12'
          }`}
        >
          <label
            htmlFor="email font-weight-bold timeoff-grey "
            className="login-form-label-style"
          >
            Email
          </label>
          <input
            type="email"
            className={`form-control timeoff-input ${
              displayErrorBanner ? 'error' : ''
            }`}
            id="loginEmailInput"
            aria-describedby="emailHelp"
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
            aria-label="login-email-input"
          />
        </div>
        <div
          className={`form-group py-3 ${
            isCompanyCreationEnabled ? 'px-lg-4 mx-lg-4' : 'col-12'
          }`}
        >
          <label
            htmlFor="password font-weight-bold timeoff-grey"
            className="login-form-label-style"
          >
            Password
          </label>
          <input
            type="password"
            className={`form-control timeoff-input ${
              displayErrorBanner ? 'error' : ''
            }`}
            id="loginPasswordInput"
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
            aria-label="login-password-input"
          />
        </div>
        <div
          className={`form-group py-3 ${
            isCompanyCreationEnabled
              ? 'px-lg-4 mx-lg-4 d-flex justify-content-between'
              : 'col-12'
          }`}
        >
          <div className="mb-3">
            <button
              className="forgot-password-btn font-weight-bold timeoff-grey text-decoration-none hover-dark-blue"
              onClick={() => navigate('/password-recovery')}
              type="button"
            >
              Forgot Password?
            </button>
          </div>
          {isCompanyCreationEnabled && (
            <div className="mb-3 ps-3">
              <button
                className="forgot-password-btn font-weight-bold timeoff-grey text-decoration-none hover-dark-blue"
                onClick={() => navigate('/register-company')}
                type="button"
              >
                Register New Company
              </button>
            </div>
          )}
        </div>

        <div className="py-2 px-lg-4 mx-lg-4 d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bg-timeoff-blue py-1 py-md-2 shadow-box"
            style={{ fontWeight: '500' }}
            disabled={!validateEmail(email) || !password || loading}
            aria-label="login-submit-button"
          >
            Log In
          </button>
        </div>
      </form>
    </>
  )
}
