import React from 'react'
import { EngagementTribeOrg } from '../../types/Engagement'
import { Member } from '../../types/Tribe'
import ProjectDateRange from './TribeProjectComponents/ProjectDateRange'
import ProjectName from './TribeProjectComponents/ProjectNameContainer'
import ProjectTeamBox from './TribeProjectComponents/ProjectTeamBox'
import { AllGuildTypes } from '../../types/Roles'
import { useNavigate } from 'react-router-dom'

interface Props {
  projectNumber: string
  engagement: EngagementTribeOrg
  team:
    | {
        [AllGuildTypes.Developer]: Array<Member>
        [AllGuildTypes.QA]: Array<Member>
        [AllGuildTypes.Design]: Array<Member>
        [AllGuildTypes.DevOps]: Array<Member>
      }
    | undefined
  guildsInfo: Array<{ name: string; color: string; guildId: number }>
  teamName: string
  teamId: number
}

const TribeProjectContainer = ({
  projectNumber,
  engagement,
  team,
  guildsInfo,
  teamName,
  teamId,
}: Props) => {
  const navigate = useNavigate()

  const handleTeamClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    navigate(`/tribe/team/${teamId}`)
  }

  const handleMemberClick = (event: React.MouseEvent, memberId: number) => {
    event.stopPropagation()
    if (memberId !== undefined && memberId !== -1) {
      navigate(`/members/${memberId}`)
    }
  }

  const handleEngagementClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (engagement.id !== undefined && engagement.id !== -1) {
      navigate(`/engagements/${engagement.id}`)
    }
  }

  const showMemberByGuild = (guild, members, key, handleMemberClick) => {
    if (guild && guild.name) {
      return (
        team && (
          <div key={key} className={`team ${guild.name}`}>
            {members.map((member, index) => {
              if (member.guildId == guild.guildId) {
                return (
                  <ProjectTeamBox
                    key={index}
                    style={`${guild.name.toLowerCase()}`}
                    text={`${member.firstName} ${member.lastName}`}
                    role={member.role}
                    memberId={member.id}
                    handleMemberClick={handleMemberClick}
                  />
                )
              }
            })}
          </div>
        )
      )
    }
  }

  return (
    <div className="tribe-project-container">
      <ProjectName
        projectNumber={projectNumber}
        projectName={engagement.projectName}
        clientName={engagement?.client?.name || ''}
        teamName={teamName}
        handleTeamClick={handleTeamClick}
        handleEngagementClick={handleEngagementClick}
      />
      <ProjectDateRange
        startDate={
          engagement.startDate !== 'N/A'
            ? new Date(engagement.startDate)
            : engagement.startDate
        }
        endDate={
          engagement.startDate !== 'N/A'
            ? new Date(engagement.endDate)
            : engagement.startDate
        }
      />
      <div className="tribe-team-container">
        {team ? (
          guildsInfo.map((guild, index) =>
            guild.name !== 'OPs'
              ? showMemberByGuild(
                  guild,
                  team[`${guild.name}`],
                  index,
                  handleMemberClick,
                )
              : showMemberByGuild(
                  'devops',
                  team[AllGuildTypes.DevOps],
                  index,
                  handleMemberClick,
                ),
          )
        ) : (
          <div className="no-team-message-container">
            <div>Open</div>
            <div style={{ fontWeight: '200' }}>No team members assigned</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TribeProjectContainer
