import React, { useEffect, useState, forwardRef } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ColorGuide from '../../components/ColorGuide/ColorGuide'
import TeamUtilizationTimeline, {
  TeamUtilizationTimelineGroup,
  TeamUtilizationTimelineItemBase,
} from '../../components/TeamUtilizationTimeline/TeamUtilizationTimeline'
import { User } from '../../types/User'
import { useLazyGetAllTribesStaffedQuery } from '../../redux/tribe.endpoints'
import { useLazyGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { Engagement, concatClientAndProjectName } from '../../types/Engagement'
import moment from 'moment'
import { SelectOption } from '../../types/SelectOption'
import { Tribe, TribeStaffed } from '../../types/Tribe'
import { Team, TeamStaffed } from '../../types/Team'
import CustomDateInput, {
  CustomDateInputProps,
} from '../../components/CustomDateInput/CustomDateInput'
import { Helmet } from 'react-helmet'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { AllGuildTypes, Role } from '../../types/Roles'
import { toast } from 'react-toastify'
import { getYearOptions } from '../../utils/dropdownOptionHelper'
interface Props {
  profile: User
  companyId: number
}

const TeamUtilization = ({ companyId }: Props) => {
  const [getTribesStaffed, { data: tribesData }] =
    useLazyGetAllTribesStaffedQuery()
  const [getEngagements, { data: engagementsData }] =
    useLazyGetAllEngagementsQuery()
  const [timelineItems, setTimelineItems] = useState<
    TeamUtilizationTimelineItemBase[]
  >([])
  const [availableTribes, setAvailableTribes] = useState<SelectOption[]>([])
  const [availableTeams, setAvailableTeams] = useState<SelectOption[]>([])
  const [selectedTribe, setSelectedTribe] = useState<SelectOption>()
  const [selectedTeam, setSelectedTeam] = useState<SelectOption>()
  const [benchedTeamsToDateCount, setBenchedTeamsToDateCount] =
    useState<number>(0)
  const timelineStartDefaultDate = moment().startOf('day').toDate()
  const [timelineStart, setTimelineStart] = useState<Date>(
    timelineStartDefaultDate,
  )
  const timelineEndDefaultDate = moment().add(65, 'day').endOf('day').toDate()
  const [timelineEnd, setTimelineEnd] = useState<Date>(timelineEndDefaultDate)

  const [selectedYear, setSelectedYear] = useState<SelectOption>()
  const [selectedMonth, setSelectedMonth] = useState<SelectOption>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  const getGuildIdWithType = (guildType: string) => {
    if (guilds && guilds.length > 0) {
      return guilds.find(guild => guild.type === guildType)?.id ?? -1
    } else {
      return -1
    }
  }

  const CustomDateInputRef = forwardRef((props: any, ref) => (
    <CustomDateInput {...props} forwardedRef={ref} />
  ))
  CustomDateInputRef.displayName = 'Date Input'

  useEffect(() => {
    getTribesStaffed({
      companyId: companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    getEngagements({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
  }, [])

  useEffect(() => {
    if (tribesData && engagementsData) {
      buildTeamUtilizationTimelineGroups(tribesData)
      buildTeamUtilizationTimelineItems(tribesData)
      setBenchedTeamsToDateCount(getBenchedTeamsToDateCount())
    }
  }, [tribesData, engagementsData])

  useEffect(() => {
    if (startDate || endDate) {
      setSelectedYear(undefined)
      setSelectedMonth(undefined)
    }
    if (
      startDate != undefined &&
      endDate != undefined &&
      moment(startDate) > moment(endDate)
    ) {
      toast.error('The date ranges are incorrect, please check them.', {
        toastId: 'create-timeoff-error',
      })
      return
    }
    if (startDate && endDate) {
      setTimelineStart(startDate)
      setTimelineEnd(endDate)
    } else {
      setTimelineStart(timelineStartDefaultDate)
      setTimelineEnd(timelineEndDefaultDate)
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (selectedMonth || selectedYear) {
      setStartDate(undefined)
      setEndDate(undefined)
    }
    if (selectedYear && selectedMonth) {
      setTimelineStart(
        moment()
          .year(parseInt(selectedYear.value))
          .month(parseInt(selectedMonth.value))
          .startOf('month')
          .toDate(),
      )
      setTimelineEnd(
        moment()
          .year(parseInt(selectedYear.value))
          .month(parseInt(selectedMonth.value))
          .endOf('month')
          .toDate(),
      )
    } else if (selectedYear) {
      setTimelineStart(
        moment().year(parseInt(selectedYear.value)).startOf('year').toDate(),
      )
      setTimelineEnd(
        moment().year(parseInt(selectedYear.value)).endOf('year').toDate(),
      )
    } else if (selectedMonth) {
      setTimelineStart(
        moment().month(parseInt(selectedMonth.value)).startOf('month').toDate(),
      )
      setTimelineEnd(
        moment().month(parseInt(selectedMonth.value)).endOf('month').toDate(),
      )
    } else {
      setTimelineStart(timelineStartDefaultDate)
      setTimelineEnd(timelineEndDefaultDate)
    }
  }, [selectedYear, selectedMonth])

  useEffect(() => {
    if (tribesData) {
      const tribeOptions: SelectOption[] = tribesData
        .filter(tribe => !tribe.isDeliveryTribe)
        .map(tribe => {
          return { label: tribe.name, value: `${tribe.id}` }
        })
      setAvailableTribes(tribeOptions)
    }
  }, [tribesData])

  useEffect(() => {
    const teamOptions: SelectOption[] = [{ label: 'All Teams', value: '' }]
    const tribes = getFilteredTribes()
    tribes?.forEach(tribe => {
      tribe.teams.forEach(team => {
        teamOptions.push({ label: team.name, value: `${team.id}` })
      })
    })
    setSelectedTeam(undefined)
    setAvailableTeams(teamOptions)
    buildTeamUtilizationTimelineGroups(tribes)
  }, [selectedTribe, availableTribes])

  useEffect(() => {
    if (!selectedTeam?.value) {
      buildTeamUtilizationTimelineGroups(getFilteredTribes())
      return
    }
    const tribes: Tribe[] = []
    tribesData
      ?.filter(tribe => !tribe.isDeliveryTribe)
      .forEach(tribe => {
        tribe.teams.forEach(team => {
          if (team.id === parseInt(selectedTeam?.value))
            tribes.push({ ...tribe, teams: [team] })
        })
      })
    buildTeamUtilizationTimelineGroups(tribes)
  }, [selectedTeam])

  const todayCalculationForOpenTeams = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
    ),
  )

  const isMondayOfCurrentWeek = (date: Date): boolean => {
    // Check if the day of the week for the given date is Monday, 1 is Monday
    const isMonday = date.getUTCDay() === 1

    // Check if the given date's year is the same as the current year.
    const isCurrentYear =
      date.getUTCFullYear() === todayCalculationForOpenTeams.getUTCFullYear()

    // This ensures that the date is not just any Monday, but the Monday of this current week.
    const isInCurrentWeek =
      getWeekNumber(date) === getWeekNumber(todayCalculationForOpenTeams)

    // The date is the Monday of the current week if all the above conditions are true.
    return isMonday && isCurrentYear && isInCurrentWeek
  }

  const getWeekNumber = (dateToEvaluate: Date): number => {
    // January 1st of the date's year
    const januaryFirst = new Date(dateToEvaluate.getFullYear(), 0, 1)

    // Days since the start of the year, adding one day in milliseconds
    const dayOfYear =
      (dateToEvaluate.getTime() - januaryFirst.getTime() + 86400000) / 86400000

    // Dividing the days by 7 to get the week number
    return Math.ceil(dayOfYear / 7)
  }

  const getFilteredTribes = () => {
    let tribes = tribesData?.filter(tribe => !tribe.isDeliveryTribe) || []
    if (selectedTribe) {
      tribes = tribes?.filter(
        tribe => tribe.id === parseInt(selectedTribe.value),
      )
    }
    return tribes
  }

  const getTeamCount = teamAllocation => {
    if (teamAllocation) {
      const length = teamAllocation.filter(item => {
        if (!item.user.end_on) return true
        const today = new Date()
        const userEndDate = new Date(item.user.end_on)
        if (userEndDate < today) return false
        else return true
      }).length
      return length
    }

    return 0
  }

  const isBetweenDateRange = (team: Team | TeamStaffed) => {
    const timelineStartWithoutTimezoneOffset =
      timelineStart.valueOf() - timelineStart.getTimezoneOffset() * 60 * 1000
    const timelineEndWithoutTimezoneOffset =
      timelineEnd.valueOf() - timelineEnd.getTimezoneOffset() * 60 * 1000
    if (
      timelineStart.valueOf() !== timelineStartDefaultDate.valueOf() &&
      timelineEnd.valueOf() !== timelineEndDefaultDate.valueOf()
    ) {
      return (
        new Date(team.startDate).valueOf() <
          timelineEndWithoutTimezoneOffset.valueOf() &&
        (!team.endDate ||
          new Date(team.endDate).valueOf() >
            timelineStartWithoutTimezoneOffset.valueOf())
      )
    } else {
      return true
    }
  }

  const buildTeamUtilizationTimelineGroups = (tribes: Tribe[]) => {
    const groups: TeamUtilizationTimelineGroup[] = []
    tribes
      .filter(tribe => !tribe.isDeliveryTribe)
      .forEach(tribe => {
        groups.push({
          id: `Tribe-${tribe.id}`,
          title: tribe.name,
          type: 'tribe',
          bgColor: tribe.color,
        })
        tribe.teams.forEach(team => {
          if (isBetweenDateRange(team)) {
            groups.push({
              id: `Team-${team.id}`,
              title: team.name,
              type: 'team',
            })
          }
        })
        groups.push({
          id: `Tribe-${tribe.id}-Expand-Collapse`,
          title: '',
          type: 'Expand/Collapse',
        })
        groups.push({
          id: `Tribe-${tribe.id}-Open-Teams`,
          title: 'Open Teams',
          type: 'openTeams',
          parentId: `Tribe-${tribe.id}-Expand-Collapse`,
        })
        groups.push({
          id: `Tribe-${tribe.id}-Devs-In-Bench`,
          title: 'Devs in Bench',
          type: 'devsInBench',
          parentId: `Tribe-${tribe.id}-Expand-Collapse`,
        })
      })
    return groups
  }

  const buildTeamUtilizationTimelineItems = (tribes: TribeStaffed[]) => {
    /* Build timeline items */
    const items: TeamUtilizationTimelineItemBase[] = []
    const dayInUnixTime = 86400000
    const weeksSinceEpoch = Math.floor(
      moment.utc(new Date().toDateString()).valueOf() / (7 * dayInUnixTime),
    )
    const weeksOffsetForCalculation = 100

    /* Add timeline items for the team's engagements */
    engagementsData?.forEach(engagement => {
      const { team, client } = engagement
      const tribe = tribesData
        ?.filter(tribe => !tribe.isDeliveryTribe)
        .find(tribe => tribe.id === team.tribeId)
      let staff = 0
      if (tribe) {
        const foundTeam = tribe.teams.find(item => item.id === team.id)
        if (foundTeam && foundTeam.teamAllocations) {
          staff = getTeamCount(foundTeam.teamAllocations)
        }
      }
      items.push({
        id: engagement.id,
        group: `Team-${team.id}`,
        title: concatClientAndProjectName(client.name, engagement.projectName),
        start_time: moment(engagement.startDate).startOf('day').valueOf(),
        end_time: engagement.endDate
          ? moment(engagement.endDate).endOf('day').valueOf()
          : 1e15,
        type: 'inProgress',
        bgColor: '#7bda03',
        selectedBgColor: '#8cfb04',
        staffMember: staff,
      })
    })

    tribes
      .filter(tribe => !tribe.isDeliveryTribe)
      .forEach(tribe => {
        const weeklyValueOfOpenTeams = Array(
          weeksOffsetForCalculation * 2 + 1,
        ).fill(tribe.teams.length)
        const weeklyValueOfDevsInBench = Array(
          weeksOffsetForCalculation * 2 + 1,
        ).fill(0)
        tribe.teams.forEach(team => {
          const today = new Date()
          const weeksSinceEpochSinceTeamStart = Math.floor(
            moment(new Date(team.startDate).toDateString()).valueOf() /
              (7 * dayInUnixTime),
          )
          const weeksSinceEpochSinceTeamEnd = Math.floor(
            moment(
              (team.endDate != null
                ? new Date(team.endDate)
                : moment(new Date())
                    .add(weeksOffsetForCalculation, 'w')
                    .toDate()
              ).toDateString(),
            ).valueOf() /
              (7 * dayInUnixTime),
          )

          for (
            let i = weeksSinceEpoch - weeksOffsetForCalculation;
            i < weeksSinceEpoch + weeksOffsetForCalculation;
            i++
          ) {
            if (i < weeksSinceEpochSinceTeamStart) {
              weeklyValueOfOpenTeams[
                i - weeksSinceEpoch + weeksOffsetForCalculation
              ] -= 1
            }
            if (team.endDate != null && i > weeksSinceEpochSinceTeamEnd) {
              weeklyValueOfOpenTeams[
                i - weeksSinceEpoch + weeksOffsetForCalculation
              ] -= 1
            }
            team.teamAllocations
              ?.filter(item => {
                if (!item.user.end_on) return true
                const userEndDate = new Date(item.user.end_on)
                if (userEndDate < today) return false
                else return true
              })
              ?.forEach(member => {
                if (
                  member.user.guildId ==
                    getGuildIdWithType(AllGuildTypes.Developer) &&
                  i >= weeksSinceEpochSinceTeamStart &&
                  (team.endDate == null || i <= weeksSinceEpochSinceTeamEnd)
                ) {
                  weeklyValueOfDevsInBench[
                    i - weeksSinceEpoch + weeksOffsetForCalculation
                  ] += 1
                }
              })
          }

          const teamStartTime = moment(team.startDate).startOf('day').valueOf()
          const teamEndTime = team.endDate
            ? moment(team.endDate).endOf('day').valueOf()
            : 1e15

          /* Fill out the rest of the timeline to the right with empty items on open dates */
          const engagementsForTeam = engagementsData?.filter(
            engagement => engagement.team.id === team.id,
          )
          const engagementsForTeamSorted = engagementsForTeam?.sort(
            (a, b) =>
              moment(a.startDate).valueOf() - moment(b.startDate).valueOf(),
          )
          const staffed = team.isStaffed
          const openBgColor = staffed ? '#adb5bd' : '#fecba1'
          const openSelectedBgColor = staffed ? '#b8bfc6' : '#fed5b3'
          const openTitle = staffed ? 'Open' : 'Open Not Staffed'
          const openType = staffed ? 'open' : 'openNotStaffed'
          if (engagementsForTeamSorted && engagementsForTeamSorted.length > 0) {
            /* Add an timeline item starting from the team's creation to the first ever engagement start date */
            items.push({
              id: `empty-${team.id}`,
              group: `Team-${team.id}`,
              title: openTitle,
              start_time: teamStartTime,
              end_time: moment(engagementsForTeamSorted[0].startDate)
                .startOf('day')
                .valueOf(),
              type: openType,
              bgColor: openBgColor,
              selectedBgColor: openSelectedBgColor,
              staffMember: getTeamCount(team.teamAllocations),
            })

            engagementsForTeamSorted.forEach(
              (engagement, index, engagements) => {
                const nextEngagement =
                  index < engagements.length - 1 ? engagements[index + 1] : null
                const engagementStartDate = moment(
                  engagement.startDate,
                ).startOf('day')
                const engagementEndDate = moment(engagement.endDate).startOf(
                  'day',
                )
                const nextEngagementStartDate = moment(
                  nextEngagement?.startDate,
                ).startOf('day')
                /* Add open items in between engagements */
                if (nextEngagement) {
                  if (
                    Math.abs(
                      engagementEndDate.diff(nextEngagementStartDate, 'days'),
                    ) > 0
                  ) {
                    items.push({
                      id: `${team.id}-${engagement.id}-${nextEngagement.id}`,
                      group: `Team-${team.id}`,
                      title: openTitle,
                      start_time: engagementEndDate.endOf('day').valueOf(),
                      end_time: nextEngagementStartDate
                        .startOf('day')
                        .valueOf(),
                      type: openType,
                      bgColor: openBgColor,
                      selectedBgColor: openSelectedBgColor,
                      staffMember: getTeamCount(team.teamAllocations),
                    })
                  }
                } else {
                  /* Add open items in at the end */
                  items.push({
                    id: `${team.id}-${engagement.id}-end`,
                    group: `Team-${team.id}`,
                    title: openTitle,
                    start_time: engagementEndDate.endOf('day').valueOf(),
                    end_time: teamEndTime,
                    type: openType,
                    bgColor: openBgColor,
                    selectedBgColor: openSelectedBgColor,
                    staffMember: getTeamCount(team.teamAllocations),
                  })
                }
                for (
                  let i = weeksSinceEpoch - weeksOffsetForCalculation;
                  i < weeksSinceEpoch + weeksOffsetForCalculation;
                  i++
                ) {
                  const momentWeekStarts = moment(
                    7 * dayInUnixTime * i + 3 * dayInUnixTime,
                  ).endOf('day')
                  const momentWeekEnds = moment(
                    7 * dayInUnixTime * (i + 1) - 1 + 3 * dayInUnixTime,
                  ).endOf('day')
                  if (
                    engagementStartDate.valueOf() <= momentWeekEnds.valueOf() &&
                    engagementEndDate.valueOf() > momentWeekStarts.valueOf()
                  ) {
                    if (
                      weeklyValueOfOpenTeams[
                        i - weeksSinceEpoch + weeksOffsetForCalculation
                      ] > 0
                    ) {
                      weeklyValueOfOpenTeams[
                        i - weeksSinceEpoch + weeksOffsetForCalculation
                      ] -= 1
                    }
                    team.teamAllocations
                      ?.filter(item => {
                        if (!item.user.end_on) return true
                        const today = new Date()
                        const userEndDate = new Date(item.user.end_on)
                        if (userEndDate < today) return false
                        else return true
                      })
                      ?.forEach(member => {
                        if (
                          member.user.guildId ==
                          getGuildIdWithType(AllGuildTypes.Developer)
                        ) {
                          weeklyValueOfDevsInBench[
                            i - weeksSinceEpoch + weeksOffsetForCalculation
                          ] -= 1
                        }
                      })
                  }
                }
              },
            )
          } else {
            /* Add an empty item at the beginning, in other words teams without projects nor engagements */
            items.push({
              id: `empty-${team.id}`,
              group: `Team-${team.id}`,
              title: openTitle,
              start_time: teamStartTime,
              end_time: teamEndTime,
              type: openType,
              bgColor: openBgColor,
              selectedBgColor: openSelectedBgColor,
              staffMember: getTeamCount(team.teamAllocations),
            })
          }
        })

        /* Push a weekly slot for open teams and devs in bench */
        for (
          let i = weeksSinceEpoch - weeksOffsetForCalculation;
          i < weeksSinceEpoch + weeksOffsetForCalculation;
          i++
        ) {
          const momentWeekStarts = moment(
            7 * dayInUnixTime * i + 3 * dayInUnixTime,
          ).endOf('day')
          const momentWeekEnds = moment(
            7 * dayInUnixTime * (i + 1) - 1 + 3 * dayInUnixTime,
          ).endOf('day')
          items.push({
            id: `tribe-${tribe.id}-week-${i}`,
            group: `Tribe-${tribe.id}-Open-Teams`,
            title: `${
              weeklyValueOfOpenTeams[
                i - weeksSinceEpoch + weeksOffsetForCalculation
              ]
            }`,
            start_time: momentWeekStarts.valueOf(),
            end_time: momentWeekEnds.valueOf(),
            type: 'openTeams',
            bgColor: '#01000000',
            selectedBgColor: '#00000000',
            className: 'item-with-text-align',
          })
          items.push({
            id: `tribe-${tribe.id}-week${i}`,
            group: `Tribe-${tribe.id}-Devs-In-Bench`,
            title: `${
              weeklyValueOfDevsInBench[
                i - weeksSinceEpoch + weeksOffsetForCalculation
              ]
            }`,
            start_time: momentWeekStarts.valueOf(),
            end_time: momentWeekEnds.valueOf(),
            type: 'devsInBench',
            bgColor: '#01000000',
            selectedBgColor: '#00000000',
            className: 'item-with-text-align',
          })
        }
      })
    setTimelineItems(items)
  }

  const getBenchedTeamsToDateCount = () => {
    const currentTeamsInEngagements = getCurrentTeamsInEngagements()
    let allTeams: Team[] = []
    tribesData
      ?.filter(tribe => !tribe.isDeliveryTribe)
      .forEach(tribe => {
        allTeams = allTeams.concat(tribe.teams)
      })
    return allTeams.length - currentTeamsInEngagements.length
  }

  const getCurrentTeamsInEngagements = () => {
    const today = new Date()
    const currentTeamsInEngagementToDate: Team[] = []
    engagementsData?.forEach(engagement => {
      if (
        moment(today).isBetween(
          engagement.startDate,
          engagement.endDate,
          'w',
          '[]',
        )
      ) {
        currentTeamsInEngagementToDate.push(engagement.team)
      }
    })
    return currentTeamsInEngagementToDate
  }

  const getTeamsInEngagementWithStaffedFlag = () => {
    const currentTeamInEngagements = getCurrentTeamsInEngagements()
    const currentTeamIdsWithEngagementsToDate: number[] =
      currentTeamInEngagements.map(team => team.id)
    let allTeams: TeamStaffed[] = []
    tribesData
      ?.filter(tribe => !tribe.isDeliveryTribe)
      .forEach(tribe => {
        allTeams = allTeams.concat(tribe.teams)
      })
    const allTeamsIds: number[] = allTeams.map(team => team.id)
    const filteredAllTeamsIds = allTeamsIds.filter(
      teamId => !currentTeamIdsWithEngagementsToDate.includes(teamId),
    )
    const allFilteredTeams = allTeams.filter(team =>
      filteredAllTeamsIds.includes(team.id),
    )
    return allFilteredTeams
  }

  const getOpenStaffedTeamsCount = () => {
    return getTeamsInEngagementWithStaffedFlag().filter(team => {
      const endDate = new Date(team.endDate)

      return (
        team.isStaffed &&
        new Date(team.startDate).valueOf() <= new Date().valueOf() &&
        (team.endDate === null ||
          endDate.valueOf() >= new Date().valueOf() ||
          isMondayOfCurrentWeek(endDate))
      )
    }).length
  }

  const getOpenNotStaffedTeamsCount = () => {
    return getTeamsInEngagementWithStaffedFlag().filter(team => {
      const endDate = new Date(team.endDate)

      return (
        !team.isStaffed &&
        new Date(team.startDate).valueOf() <= new Date().valueOf() &&
        (team.endDate === null ||
          endDate.valueOf() >= new Date().valueOf() ||
          isMondayOfCurrentWeek(endDate))
      )
    }).length
  }

  const getOpenNotStaffedTeamById = (teamId?: number) => {
    const matchingTeams = getTeamsInEngagementWithStaffedFlag().filter(team => {
      return (!teamId || team.id === teamId) && !team.isStaffed
    })

    return matchingTeams.length > 0
  }

  const getDevsOnBenchTotal = () => {
    let allDevsInBench = 0

    getTeamsInEngagementWithStaffedFlag()
      .filter(team => {
        const endDate = new Date(team.endDate)

        return (
          new Date(team.startDate).valueOf() <= new Date().valueOf() &&
          (team.endDate === null ||
            endDate.valueOf() >= new Date().valueOf() ||
            isMondayOfCurrentWeek(endDate))
        )
      })
      .forEach(team => {
        allDevsInBench +=
          team.teamAllocations?.filter(
            member =>
              member.user.guildId ===
              getGuildIdWithType(AllGuildTypes.Developer),
          ).length ?? 0
      })

    return allDevsInBench
  }

  const getDeliveryBenchTimeYearToDate = () => {
    let sumOfOpenWeeksInDelivery = 0
    tribesData
      ?.filter(tribe => !tribe.isDeliveryTribe)
      .forEach(tribe => {
        sumOfOpenWeeksInDelivery += getTribeBenchWeeksToDate(tribe)
      })
    return sumOfOpenWeeksInDelivery
  }

  const getTribeBenchWeeksToDate = (tribe: TribeStaffed) => {
    let totalDaysAllTeamsInTribeAreActivePeriod = 0

    const teamIsOpenNotStaffed = (teamId: number) => {
      return getOpenNotStaffedTeamById(teamId)
    }

    const calculateWeekDifference = (start: Date, end: Date) => {
      const getMillisecondsInDay = 24 * 60 * 60 * 1000
      const diffDays = Math.round(
        Math.abs((start.getTime() - end.getTime()) / getMillisecondsInDay),
      )
      return Math.floor(diffDays / 7)
    }

    tribe.teams.forEach(team => {
      if (teamIsOpenNotStaffed(team.id)) return

      const startDate = new Date(team.startDate)
      const teamEndDate = team.endDate ? new Date(team.endDate) : new Date()

      // Find all engagements for the team and sort them by start date.
      const teamEngagements = engagementsData
        ?.filter(engagement => engagement.team.id === team.id)
        .sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
        )

      if (teamEngagements && teamEngagements.length > 0) {
        let previousEngagementEndDate = startDate

        teamEngagements.forEach((engagement, index) => {
          const engagementStartDate = new Date(engagement.startDate)
          const engagementEndDate = new Date(engagement.endDate)

          // Add the weeks from the end of the previous engagement (or team start) to the start of this engagement.
          totalDaysAllTeamsInTribeAreActivePeriod += calculateWeekDifference(
            previousEngagementEndDate,
            engagementStartDate,
          )

          // If this is the last engagement for the team and it ends before the team ends, add the remaining weeks.
          if (
            index === teamEngagements.length - 1 &&
            engagementEndDate < teamEndDate
          ) {
            totalDaysAllTeamsInTribeAreActivePeriod += calculateWeekDifference(
              engagementEndDate,
              teamEndDate,
            )
          }

          previousEngagementEndDate = engagementEndDate
        })
      } else {
        // If no engagements, just count all the weeks.
        totalDaysAllTeamsInTribeAreActivePeriod += calculateWeekDifference(
          startDate,
          teamEndDate,
        )
      }

      // Check if the team ends on the Monday of the current week.
      if (team.endDate && isMondayOfCurrentWeek(teamEndDate)) {
        totalDaysAllTeamsInTribeAreActivePeriod += 1
      }
    })

    return totalDaysAllTeamsInTribeAreActivePeriod
  }

  const selectMonths = [
    { value: '0', label: 'January' },
    { value: '1', label: 'February' },
    { value: '2', label: 'March' },
    { value: '3', label: 'April' },
    { value: '4', label: 'May' },
    { value: '5', label: 'June' },
    { value: '6', label: 'July' },
    { value: '7', label: 'August' },
    { value: '8', label: 'September' },
    { value: '9', label: 'October' },
    { value: '10', label: 'November' },
    { value: '11', label: 'December' },
  ]

  return (
    <div className="container my-4 min-height">
      <h1 className="mt-4">Team Utilization Over Time</h1>
      <h2 className="my-4 current-state">Current State</h2>
      <div className="d-flex flex flex-row justify-content-between">
        <div className="d-flex flex-column dashboard-item p-3 justify-content-around">
          <div className="title gray-color">Teams Allocated</div>
          <div className="grow big-number">
            {getCurrentTeamsInEngagements().length}
          </div>
          <div className="gray-color">Engagements in Progress</div>
        </div>
        <div className="d-flex flex-column dashboard-item p-3">
          <div className="title gray-color">Teams Summary</div>
          <div className="d-flex flex-row justify-content-between py-2 border-bottom border-dark">
            <div>Open Teams</div>
            <div className="gray-color">{getOpenStaffedTeamsCount()}</div>
          </div>
          <div className="d-flex flex-row justify-content-between py-2 border-bottom border-dark">
            <div>Open Not Staffed Teams</div>
            <div className="gray-color">{getOpenNotStaffedTeamsCount()}</div>
          </div>
          <div className="d-flex flex-row justify-content-between py-2">
            <div>Total Devs in Bench</div>
            <div className="gray-color">{getDevsOnBenchTotal()}</div>
          </div>
        </div>
        <div className="d-flex flex-column dashboard-item p-3 justify-content-around">
          <div className="title gray-color">Delivery Org Bench Time</div>
          <div className="big-number">{getDeliveryBenchTimeYearToDate()}</div>
          <div className="gray-color">Weeks Total (YTD)</div>
        </div>
        <div className="d-flex flex-column dashboard-item p-3">
          <div className="title">Total Bench Per Tribe</div>
          <div className="container-fluid scrollable overflow-auto">
            {tribesData
              ?.filter(tribe => !tribe.isDeliveryTribe)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((element, index) => {
                const classname = `d-flex flex-row justify-content-between py-2 ${
                  index !== tribesData.length - 1
                    ? 'border-bottom border-dark'
                    : ''
                }`
                return (
                  <div className={classname} key={index}>
                    <div>{element.name}</div>
                    <div className="gray-color">
                      {getTribeBenchWeeksToDate(element)} weeks
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4 py-2 bottom-divider">
        <p className="h3 mb-0">Filters</p>
      </div>
      <div className="mb-5 d-flex justify-content-between">
        <div className="d-flex gap-3">
          <div>
            <label>Year</label>
            <Select
              key={`year-select-${selectedYear}`}
              className="basic-single"
              isClearable={true}
              isRtl={false}
              value={selectedYear}
              options={getYearOptions()}
              onChange={option => {
                if (option) setSelectedYear(option)
                else setSelectedYear(undefined)
              }}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={'2024'}
            />
          </div>
          <div>
            <label>Month</label>
            <Select
              key={`month-select-${selectedMonth}`}
              className="basic-single"
              isClearable={true}
              isRtl={false}
              value={selectedMonth}
              options={selectMonths}
              onChange={option => {
                if (option) setSelectedMonth(option)
                else setSelectedMonth(undefined)
              }}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
              placeholder={'January'}
            />
          </div>
          <div style={{ width: '150px' }}>
            <label>From</label>
            <DatePicker
              className={`form-control timeoff-input`}
              maxDate={endDate}
              selected={startDate}
              placeholderText={'Select a date'}
              isClearable
              onChange={date => {
                setStartDate(date ?? undefined)
              }}
              customInput={<CustomDateInputRef />}
            />
          </div>
          <div style={{ width: '150px' }}>
            <label>To</label>
            <DatePicker
              minDate={startDate}
              className={`form-control timeoff-input`}
              selected={endDate}
              isClearable
              placeholderText={'Select a date'}
              onChange={date => {
                setEndDate(date ?? undefined)
              }}
              customInput={<CustomDateInputRef />}
            />
          </div>
        </div>
        <div className="d-flex gap-3">
          <div style={{ width: '200px' }}>
            <label>Tribe</label>
            <Select
              key={`tribe-select-${selectedTribe?.label}`}
              className="basic-single"
              isClearable={true}
              isRtl={false}
              options={availableTribes}
              value={selectedTribe}
              onChange={option => {
                if (option) setSelectedTribe(option)
                else setSelectedTribe(undefined)
              }}
              placeholder={'Sort by Tribe'}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
            />
          </div>
          <div style={{ width: '200px' }}>
            <label>Team</label>
            <Select
              key={`team-select-${selectedTeam?.label}`}
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isRtl={false}
              options={availableTeams}
              value={selectedTeam}
              onChange={option => {
                if (option) setSelectedTeam(option)
                else setSelectedTeam(undefined)
              }}
              placeholder={'Sort by Team'}
              styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end m-4">
        <div className="d-flex flex-row">
          <ColorGuide style={{ background: '#ADB5BD' }} text="Open" />
          <ColorGuide
            style={{ background: '#FECBA1' }}
            text="Open Not Staffed"
          />
          <ColorGuide style={{ background: '#7BDA03' }} text="In Progress" />
        </div>
      </div>
      <TeamUtilizationTimeline
        groups={buildTeamUtilizationTimelineGroups(getFilteredTribes())}
        items={timelineItems}
        timelineStart={timelineStart}
        timelineEnd={moment(timelineEnd).endOf('day').toDate()}
        timelineStartDefaultValue={timelineStartDefaultDate}
        timelineEndDefaultValue={timelineEndDefaultDate}
      />
    </div>
  )
}

export default TeamUtilization
