import React from 'react'
import ReactModal from 'react-modal'

interface Props {
  children: JSX.Element
  isModalOpen: boolean
  hideModal: () => void
}

const ModalContainer = ({ children, isModalOpen, hideModal }: Props) => {
  return (
    <ReactModal
      className="modal-container mt-5"
      onRequestClose={() => hideModal()}
      isOpen={isModalOpen}
    >
      {children}
    </ReactModal>
  )
}

export default ModalContainer
