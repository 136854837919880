import moment from 'moment'
import { TimeOff } from '../types/TimeOff'

export const useTimeoffCalculator = () => {
  const countWeekendDays = (startDate, endDate) => {
    let count = 0
    const currentDate = startDate.clone()

    while (currentDate.isSameOrBefore(endDate, 'day')) {
      if (currentDate.day() === 0 || currentDate.day() === 6) {
        count++
      }
      currentDate.add(1, 'day')
    }

    return count
  }
  const getTotalDaysUsed = (allTimesOff: TimeOff[]) => {
    const currentYear = moment().year()
    const today = moment()

    return allTimesOff.reduce((total, timeoff) => {
      let startDate = moment(timeoff.startDate)
      let endDate = moment(timeoff.endDate)

      if (startDate.year() !== currentYear && endDate.year() !== currentYear) {
        return total
      }

      if (startDate.year() !== endDate.year()) {
        if (startDate.year() === currentYear) {
          const nextYearStartDate = moment({
            year: currentYear + 1,
            month: 0,
            date: 1,
          })
          if (endDate.isAfter(nextYearStartDate)) {
            startDate = moment.max(startDate, nextYearStartDate)
          } else {
            startDate = moment.max(startDate, today)
          }
        } else if (endDate.year() === currentYear) {
          endDate = moment.min(endDate, today)
          if (startDate.year() < currentYear) {
            startDate = moment({ year: currentYear, month: 0, date: 1 })
          }
        }
      }

      if (endDate.isAfter(today)) {
        endDate = today
      }

      const weekends = countWeekendDays(startDate, endDate)
      const duration = endDate.diff(startDate, 'days') + 1

      total += Math.max(duration - weekends, 0)

      return total
    }, 0)
  }
  const getTotalDaysScheduled = (allTimesOff: TimeOff[]) => {
    const currentYear = moment().year()

    return allTimesOff.reduce((total, timeoff) => {
      const startDate = moment(timeoff.startDate)
      const endDate = moment(timeoff.endDate)

      if (
        (startDate.year() === currentYear || endDate.year() === currentYear) &&
        startDate.year() === endDate.year()
      ) {
        const duration = endDate.diff(startDate, 'days') + 1
        const weekends = countWeekendDays(startDate, endDate)

        total += Math.max(duration - weekends, 0)
      } else if (
        startDate.year() === currentYear &&
        endDate.year() > currentYear
      ) {
        const endOfYear = moment([currentYear, 11, 31])
        const duration = endOfYear.diff(startDate, 'days') + 1
        const weekends = countWeekendDays(startDate, endOfYear)

        total += Math.max(duration - weekends, 0)
      } else if (
        startDate.year() < currentYear &&
        endDate.year() === currentYear
      ) {
        const startOfYear = moment([currentYear, 0, 1])
        const duration = endDate.diff(startOfYear, 'days') + 1
        const weekends = countWeekendDays(startOfYear, endDate)

        total += Math.max(duration - weekends, 0)
      }

      return total
    }, 0)
  }
  const getRemainingDays = (allTimesOff: TimeOff[]) => {
    const totalDaysUsed = getTotalDaysUsed(allTimesOff)
    const totalDaysScheduled = getTotalDaysScheduled(allTimesOff)
    const remainingDays = Math.max(totalDaysScheduled-totalDaysUsed,0)
    return remainingDays
  }
  return {
    getTotalDaysScheduled,
    getTotalDaysUsed,
    getRemainingDays
  }
}
