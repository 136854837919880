import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ExperienceLevel, TechnologyList } from '../types/TechStacks';
import { timeoffApi } from './timeoff.api';

const techStackApi = timeoffApi.injectEndpoints({
  endpoints: build => ({
    getAllExperienceLevel: build.query<ExperienceLevel[], { pageSize: number; pageNumber: number; sortBy: string; sortType: 'ASC' | 'DESC'; search: string }>({
      query: query => ({
        url: '/experienceLevel',
        method: 'GET',
        params: query,
      }),
    }),
    getAllTechnologies: build.query<TechnologyList[], { pageSize: number; pageNumber: number; sortBy: string; sortType: 'ASC' | 'DESC'; search: string }>({
      query: query => ({
        url: '/technologies',
        method: 'GET',
        params: query,
      }),
    }),
    getUserTechStackByUserId: build.query<any[], { userId: string }>({
      query: query => ({
        url: `/user/techstack/${query.userId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { 
  useLazyGetAllExperienceLevelQuery,
  useLazyGetAllTechnologiesQuery,
  useLazyGetUserTechStackByUserIdQuery
 } = techStackApi;