import { SelectOption } from '../types/SelectOption'

type YearOptionType = SelectOption

export const getYearOptions = () => {
  const minYear = 2022
  const maxYear = new Date().getFullYear()

  const yearOptions: YearOptionType[] = []

  for (let year = minYear; year <= maxYear; year++) {
    yearOptions.push({ value: year.toString(), label: year.toString() })
  }
  return yearOptions
}
