import React, { useContext } from 'react'
import { authContext } from '../CognitoContextProvider/CognitoContextProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

interface Props {
  isAdmin: boolean
  name: string
  userId:number
}

const Navbar = ({ isAdmin, name, userId }: Props) => {
  const { logOut } = useContext(authContext)

  return (
    <div>
      <ul className="navbar nav nav-pills">
        {isAdmin && (
          <li className="nav-item">
            <a
              className="nav-link"
              id="teamManagement"
              role="button"
              href="/releaseplans"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: 'white' }}
            >
              Release Plans
            </a>
          </li>
        )}
        <li className="nav-item no-decoration me-2">
          <Link className="no-decoration normal-link" to="/">
            Time Off
          </Link>
        </li>
        {isAdmin ? (
          <>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-link"
                data-bs-toggle="dropdown"
                id="teamManagement"
                role="button"
                href="#"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Team Management{' '}
                <FontAwesomeIcon icon={faChevronDown} size="1x" />
              </a>
              <div className="dropdown-menu" aria-labelledby="teamManagement">
                <Link className="no-decoration" to="/tribes">
                  <div className="dropdown-item">Tribes</div>
                </Link>
                {/* <Link className="no-decoration" to="/teams">
      <div className="dropdown-item">Teams</div>
    </Link> */}
                <Link className="no-decoration" to="/guilds">
                  <div className="dropdown-item">Guilds</div>
                </Link>
                <Link className="no-decoration" to="/members">
                  <div className="dropdown-item">Members</div>
                </Link>
                <Link className="no-decoration" to="/clients">
                  <div className="dropdown-item">Clients</div>
                </Link>
                <Link className="no-decoration" to="/engagements">
                  <div className="dropdown-item">Engagements</div>
                </Link>
                <Link className="no-decoration" to="/tribeOrg">
                  <div className="dropdown-item">Tribe Org</div>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="teamManagement"
                role="button"
                href="/reports"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: 'white' }}
              >
                Reports
              </a>
            </li>
            <li className="nav-item no-decoration me-2">
              <Link className="no-decoration normal-link" to="/teamUtilization">
                Team Utilization
              </Link>
            </li>
          </>
        ) : (
          <></>
        )}

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-link"
            data-bs-toggle="dropdown"
            id="profile"
            role="button"
            href="#"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {name} <FontAwesomeIcon icon={faChevronDown} size="1x" />
          </a>
          {isAdmin ? (
            <div className="dropdown-menu" aria-labelledby="profile">
            <Link to={`/members/${userId}`} className="dropdown-item">
              Settings
            </Link>
            <div className="dropdown-item" onClick={() => logOut()}>
              Logout
            </div>
          </div> 
        ):(
        <div className="dropdown-menu" aria-labelledby="profile">
            <div className="dropdown-item" onClick={() => logOut()}>
              Logout
            </div>
          </div>
        )}
        </li>
      </ul>
    </div>
  )
}

export default Navbar
