import React, { useEffect, useState } from 'react'
import TribeHeaderContainer from '../../components/TribeOrg/TribeHeaderContainer'
import TribeLeadershipContainer from '../../components/TribeOrg/TribeLeadershipContainer'
import TribeProjectContainer from '../../components/TribeOrg/TribeProjectContainer'
import '../../css/tribeOrgView.scss'
import { LeadershipGroup, Member } from '../../types/Tribe'
import {
  engagementsDefaultValues,
  leadershipDefaultValues,
  teamDefaultValues,
} from '../../utils/tribeOrgDefaultValues'
import { UserTeam } from '../../types/UserTeam'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'
import { AllGuildTribeHeader, AllGuildTypes, Role } from '../../types/Roles'
import moment from 'moment'

interface Props {
  tribesOrg: any
  isSnapshot: boolean
  startDate: string
  endDate: string
}

const TribeOrgContainer = ({ tribesOrg, isSnapshot, startDate, endDate }: Props) => {
  const [tribeLeaders, setTribeLeaders] = useState<Array<Member>>([])
  const [currentTribeOrg, setCurrentTribeOrg] = useState<any>({
    name: '',
    updated_at: new Date(),
    teams: [],
  })
  const [teamsMap, setTeamsMap] = useState(new Map())
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    setCurrentTribeOrg(tribesOrg)
  }, [tribesOrg])

  useEffect(() => {
    getLeadersAndTeams()
  }, [currentTribeOrg])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
      getLeadersAndTeams()
    }
  }, [getAllRolesResponse])

  const getGuildIdWithType = (guildType: string) => {
    if (guilds && guilds.length > 0) {
      return guilds.find(guild => guild.type === guildType)?.id ?? -1
    } else {
      return -1
    }
  }

  function getLeadersAndTeams() {
    document.title = 'Time Off - Tribe Org'
    currentTribeOrg && getTribeLeaders()
    currentTribeOrg && setTeamsMap(buildTeamDictionary(currentTribeOrg))
  }

  const guildsInfo = [
    {
      name: AllGuildTypes.Developer,
      color: '#e2d9f3',
      guildId: getGuildIdWithType(AllGuildTypes.Developer),
    },
    {
      name: AllGuildTypes.QA,
      color: '#FFE5D0',
      guildId: getGuildIdWithType(AllGuildTypes.QA),
    },
    {
      name: AllGuildTypes.Design,
      color: '#D2F4EA',
      guildId: getGuildIdWithType(AllGuildTypes.Design),
    },
    {
      name: AllGuildTypes.DevOps,
      color: '#E7F1FF',
      guildId: getGuildIdWithType(AllGuildTypes.DevOps),
    },
  ]

  const guildsInfoHeader = [
    {
      name: AllGuildTribeHeader.Developer,
      color: '#e2d9f3',
      guildId: getGuildIdWithType(AllGuildTypes.Developer),
    },
    {
      name: AllGuildTribeHeader.QA,
      color: '#FFE5D0',
      guildId: getGuildIdWithType(AllGuildTypes.QA),
    },
    {
      name: AllGuildTribeHeader.Design,
      color: '#D2F4EA',
      guildId: getGuildIdWithType(AllGuildTypes.Design),
    },
    {
      name: AllGuildTribeHeader.DevOps,
      color: '#E7F1FF',
      guildId: getGuildIdWithType(AllGuildTypes.DevOps),
    },
  ]

  const leadershipRoleIds = [
    getGuildIdWithType(AllGuildTypes.SM),
    getGuildIdWithType(AllGuildTypes.BA),
    getGuildIdWithType(AllGuildTypes.ADM),
  ]

  const getLeadershipMembersByGuildType = (leadershipMembers, guildType) => {
    let leaderMember = leadershipMembers.some(
      member => member.guildId === getGuildIdWithType(guildType),
    )
      ? leadershipMembers.find(
          member => member.guildId === getGuildIdWithType(guildType),
        )
      : leadershipDefaultValues[guildType]
    if (leaderMember.guildId === -1) {
      leaderMember = { ...leaderMember, guildId: getGuildIdWithType(guildType) }
    }
    return leaderMember
  }

  const getTeamMembersByGuildType = (teamMembers, guildType, membersLimit) => {
    if (guilds && guilds.length > 0) {
      const guildId = getGuildIdWithType(guildType)
      const filteredTeamMembers = teamMembers.some(
        member => member.guildId === guildId,
      )
        ? teamMembers.filter(member => member.guildId === guildId)
        : teamDefaultValues[guildType]
      filteredTeamMembers.sort((a, b) => a.firstName.localeCompare(b.firstName))
      filteredTeamMembers.forEach(teamMember => {
        if (teamMember.guildId === -1) {
          teamMember.guildId = getGuildIdWithType(guildType)
        }
        return teamMember
      })
      if (filteredTeamMembers.length < membersLimit) {
        while (filteredTeamMembers.length < membersLimit) {
          if (
            filteredTeamMembers.some(
              member => member.role !== null || member.role === '',
            )
          ) {
            filteredTeamMembers.push({
              ...teamDefaultValues[guildType][1],
              firstName: '---',
              guildId: getGuildIdWithType(guildType),
            })
          } else {
            filteredTeamMembers.push({
              ...teamDefaultValues[guildType][0],
              firstName: '---',
              guildId: getGuildIdWithType(guildType),
            })
          }
        }
      }
      return filteredTeamMembers
    } else {
      return []
    }
  }

  const getTribeLeaders = () => {
    const leadership: Array<Member> = []
    currentTribeOrg.teams.forEach(team => {
      team.teamAllocations
        ?.filter(item => {
          if (isSnapshot || !item.user.end_on) return true
          const today = new Date()
          const userEndDate = new Date(item.user.end_on)
          if (userEndDate < today) return false
          else return true
        })
        .forEach(allocation => {
          if (
            leadershipRoleIds.some(role => allocation.user.guildId === role)
          ) {
            leadership.push(allocation.user)
          }
        })
    })
    let leaders: Array<Member> = []

    if (leadership.length > 0) {
      leaders = [
        getLeadershipMembersByGuildType(leadership, AllGuildTypes.ADM),
        getLeadershipMembersByGuildType(leadership, AllGuildTypes.BA),
        getLeadershipMembersByGuildType(leadership, AllGuildTypes.SM),
      ]
    } else {
      leaders = [
        {
          ...leadershipDefaultValues[AllGuildTypes.ADM],
          id: -1,
          guildId: getGuildIdWithType(AllGuildTypes.ADM),
        },
        {
          ...leadershipDefaultValues[AllGuildTypes.BA],
          id: -1,
          guildId: getGuildIdWithType(AllGuildTypes.BA),
        },
        {
          ...leadershipDefaultValues[AllGuildTypes.SM],
          id: -1,
          guildId: getGuildIdWithType(AllGuildTypes.SM),
        },
      ]
    }
    setTribeLeaders(leaders)
  }

  const getTeamLeadership = team => {
    const leaderShip: Member[] = []
    let leaders: Array<Member> = []

    team.teamAllocations
      ?.filter(item => {
        if (isSnapshot || !item.user.end_on) return true
        const today = new Date()
        const userEndDate = new Date(item.user.end_on)
        if (userEndDate < today) return false
        else return true
      })
      .forEach(member => {
        if (leadershipRoleIds.some(role => member.user.guildId === role)) {
          leaderShip.push(member.user)
        }
      })

    if (leaderShip.length > 0) {
      const groupByGuild = leaderShip.reduce((group, product) => {
        const { guildId } = product
        group[guildId] = group[guildId] ?? []
        group[guildId].push(product)
        return group
      }, {})
      return groupByGuild as LeadershipGroup
    }

    leaders = [
      {
        ...leadershipDefaultValues[AllGuildTypes.ADM],
        id: -1,
        guildId: getGuildIdWithType(AllGuildTypes.ADM),
      },
      {
        ...leadershipDefaultValues[AllGuildTypes.BA],
        id: -1,
        guildId: getGuildIdWithType(AllGuildTypes.BA),
      },
      {
        ...leadershipDefaultValues[AllGuildTypes.SM],
        id: -1,
        guildId: getGuildIdWithType(AllGuildTypes.SM),
      },
    ]

    const groupByGuild = leaders.reduce((group, product) => {
      const { guildId } = product
      group[guilds.find(guild => guild.id === guildId)?.type ?? ''] =
        group[guildId] ?? []
      group[guilds.find(guild => guild.id === guildId)?.type ?? ''].push(
        product,
      )
      return group
    }, {})

    return groupByGuild as LeadershipGroup
  }

  const getTeamMembersFromAllocations = (teamAllocations: UserTeam[]) => {
    const members: Member[] = []
    teamAllocations
      ?.filter(item => {
        if (isSnapshot || !item.user.end_on) return true
        const today = new Date()
        const userEndDate = new Date(item.user.end_on)
        if (userEndDate < today) return false
        else return true
      })
      .forEach(allocation => {
        if (!leadershipRoleIds.some(role => allocation.user.guildId === role)) {
          members.push({ ...allocation.user, role: allocation.role })
        }
      })

    if (!members.length) return undefined

    return {
      [AllGuildTypes.Developer]: getTeamMembersByGuildType(
        members,
        AllGuildTypes.Developer,
        3,
      ),
      [AllGuildTypes.QA]: getTeamMembersByGuildType(
        members,
        AllGuildTypes.QA,
        2,
      ),
      [AllGuildTypes.Design]: getTeamMembersByGuildType(
        members,
        AllGuildTypes.Design,
        2,
      ),
      [AllGuildTypes.DevOps]: getTeamMembersByGuildType(
        members,
        AllGuildTypes.DevOps,
        2,
      ),
    }
  }

  const buildTeamDictionary = tribesOrg => {
    const map = new Map()
    const sortedTeams = [...tribesOrg.teams].sort((a: { name: string },b: { name: string }) => a.name.localeCompare(b.name));


    sortedTeams.forEach(team => {
      const setSet = new Set()
      team.teamAllocations
        ?.filter(item => {
          if (isSnapshot || !item.user.end_on) return true
          const today = new Date()
          const userEndDate = new Date(item.user.end_on)
          if (userEndDate < today) return false
          else return true
        })
        .forEach(element => {
          if (leadershipRoleIds.some(role => element.user.guildId === role)) {
            setSet.add(element.userId)
          }
        })
      const setKey = Array.from(setSet)
        .sort((a, b) => (a as number) - (b as number))
        .join('-')

      if (
        team.endDate &&
        !moment().isBetween(
          moment(team.startDate),
          moment(team.endDate),
          undefined,
          '[]',
        )
      )
        return

      if (setKey) {
        let filteredTeam;
        if(startDate && endDate){
          filteredTeam = {
            ...team,
            engagements: team.engagements.filter(
              engagement => (new Date(engagement.endDate).toISOString().split('T')[0]) >= (new Date(endDate).toISOString().split('T')[0]) &&
              (new Date(engagement.startDate).toISOString().split('T')[0]) <= (new Date(startDate).toISOString().split('T')[0]),
            ),
          }
        }else{
          filteredTeam = {
            ...team,
            engagements: team.engagements.filter(
              engagement => new Date(engagement.endDate) >= new Date(),
            ),
          }
        }
        if (map.has(setKey)) {
          const teamsLeadership = map.get(setKey)
          teamsLeadership.push(filteredTeam)
          map.set(setKey, teamsLeadership)
        } else {
          map.set(setKey, [filteredTeam])
        }
      } else {
        map.set('empty', [
          { teamAllocations: [], engagements: [] },
          { teamAllocations: [], engagements: [] },
        ])
      }
    })

    return map
  }

  const leadershipKeys = Array.from(teamsMap.keys()).sort()

  return (
    <div className="tribes-container">
      <TribeHeaderContainer
        tribeName={currentTribeOrg.name}
        tribeUpdate={new Date(currentTribeOrg.updated_at)}
        guildsInfo={guildsInfoHeader}
      />
      <hr style={{ border: '1px solid #000000' }} />
      <div className="tribe-projects-container">
        {leadershipKeys.map(key => (
          <div
            key={`${currentTribeOrg.name}-${key}`}
            className="project-container-col col"
          >
            <TribeLeadershipContainer
              key={key}
              leadership={getTeamLeadership(
                teamsMap.get(key).length > 0 ? teamsMap.get(key)[0] : [],
              )}
            />
            <div className="leadership-team">
              {teamsMap.get(key).map((team, index) => (
                <TribeProjectContainer
                  key={`${key}-${index}`}
                  projectNumber={index + 1}
                  engagement={
                    team.engagements.length > 0
                      ? team.engagements[0]
                      : engagementsDefaultValues
                  }
                  team={getTeamMembersFromAllocations(team.teamAllocations)}
                  guildsInfo={guildsInfo}
                  teamName={team.name}
                  teamId={team.id}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TribeOrgContainer
