/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useContext, useState } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authContext } from '../../components/CognitoContextProvider/CognitoContextProvider'
import { LoginForm } from '../../components/LoginForm/LoginForm'

const Login = () => {
  const { signIn } = useContext(authContext)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const handleLogin = async (email: string, password: string) => {
    try {
      setLoading(true)

      const user = await signIn(email, password)
      if (
        user &&
        user.challengeName &&
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        navigate(`/confirm-reset?email=${email}&forcedReset=true`)
      } else if (user) {
        navigate('/')
      }
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      if (err.message === 'User is disabled.') {
        toast.error(
          'There was a problem logging in. Contact an administrator.',
          {
            toastId: 'user-disabled',
          },
        )
      } else {
        toast.error(
          'There was a problem logging in, check your credentials and try again.',
          {
            toastId: 'login-error',
          },
        )
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Time Off - Login</title>
      </Helmet>
      <main className="container-fluid scrollable">
        <div className={`row ${isDesktop || isTablet ? 'h-100' : ''}`}>
          <div className="col-12 d-flex align-items-center justify-content-center bg-lazuli-white pb-5">
            <LoginForm
              handleLogin={handleLogin}
              displayErrorBanner={false}
              loading={loading}
            />
          </div>
        </div>
      </main>
    </>
  )
}

export default Login
