import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { User } from '../../types/User'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, { components } from 'react-select'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

interface Props {
  index: number
  users: User[]
  timeOffs: any[]
  isEditing?: boolean
  handleOnChange: (
    index: number,
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void
  handleDateOnChange: (index: number, field: string, date: Date) => void
  handleUserSelectOnChange: (index: number, value: number) => void
  startDate: Date
  endDate: Date
  removeTimeOff: (index: number) => void
  currentUser: User
}

const TimeOffForm = ({
  index,
  users,
  timeOffs,
  handleOnChange,
  handleDateOnChange,
  handleUserSelectOnChange,
  removeTimeOff,
  isEditing,
  currentUser,
}: Props) => {
  const options = users.map(user => {
    return { label: `${user.firstName} ${user.lastName}`, value: user.id }
  })

  const SearchIcon = () => {
    return <FontAwesomeIcon icon={faSearch} />
  }

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  }

  const parseDateAndSetToStartOfDay = (dateLike: any) => {
    if (!dateLike) return null
    const mDate = moment(dateLike)
    return mDate.startOf('day').toDate()
  }

  const selectDefaultValue = (options: {
      label: string;
      value: number;
  }[]) => {
    const currentUserOption = options.find(option => option.label === `${currentUser.firstName} ${currentUser.lastName}`)
    if (timeOffs[index]['userId'] === currentUserOption?.value){
      handleUserSelectOnChange(index, currentUserOption?.value || 0)
      return currentUserOption
    }
  }

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <h3>Time Off {!isEditing && `#${index + 1}`}</h3>
        {!isEditing ? (
          <a onClick={() => removeTimeOff(index)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </a>
        ) : (
          <></>
        )}
      </div>
      <div className="form-group mb-4">
        {isEditing ? (
          <h5>
            {users[0].firstName} {users[0].lastName}
          </h5>
        ) : (
          <>
            <label htmlFor="timeoffUserName">
              Name <span className="text-danger">*</span>
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="Name"
              options={options}
              components={{ DropdownIndicator }}
              onChange={event =>
                handleUserSelectOnChange(index, event?.value || 0)
              }
              defaultValue={selectDefaultValue(options)}              
            />
          </>
        )}
      </div>
      <div className="d-flex row form-group mb-4">
        <div className="col-6">
          <label htmlFor="timeoffStartDate">
            From <span className="text-danger">*</span>
          </label>
          <DatePicker
            maxDate={
              timeOffs[index].endDate
                ? parseDateAndSetToStartOfDay(timeOffs[index].endDate)
                : undefined
            }
            className={`form-control timeoff-input`}
            selected={parseDateAndSetToStartOfDay(timeOffs[index].startDate)}
            placeholderText={'Select a date'}
            onChange={date => {
              if (date) {
                handleDateOnChange(index, 'startDate', date)
              }
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="timeoffEndDate">
            To <span className="text-danger">*</span>
          </label>
          <DatePicker
            minDate={
              timeOffs[index].startDate
                ? parseDateAndSetToStartOfDay(timeOffs[index].startDate)
                : new Date()
            }
            className={`form-control timeoff-input`}
            selected={parseDateAndSetToStartOfDay(timeOffs[index].endDate)}
            placeholderText={'Select a date'}
            onChange={date => {
              if (date) {
                handleDateOnChange(index, 'endDate', date)
              }
            }}
          />
        </div>
      </div>
      <div className="form-group mb-4">
        <label htmlFor="timeoffDescription">What are your plans?</label>
        <textarea
          name="description"
          id="timeoffDescription"
          className="form-control"
          defaultValue={timeOffs[index].description}
          style={{
            height: '86px',
            maxHeight: '86px',
            minHeight: '86px',
            width: '100%',
          }}
          placeholder="Enter your plans here"
          onChange={event => handleOnChange(index, event)}
        />
      </div>
    </div>
  )
}

export default TimeOffForm
