import React, { useEffect, useState } from 'react'
import { Team } from '../../types/Team'
import { useNavigate } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  CreateEngagementDto,
  Engagement,
  UpdateEngagementDto,
} from '../../types/Engagement'
import { Client } from '../../types/Client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { toast } from 'react-toastify'
interface Props {
  handleCreateSubmit?: (args: CreateEngagementDto) => Promise<void>
  handleEditSubmit?: (args: UpdateEngagementDto) => Promise<void>
  displayErrorBanner?: boolean
  loading: boolean
  engagement?: Engagement
  companyId: number
  clients: Client[]
  teams: Team[]
}

export const EngagementForm = ({
  handleCreateSubmit,
  handleEditSubmit,
  displayErrorBanner,
  loading,
  engagement,
  companyId,
  teams,
  clients,
}: Props) => {
  const [projectName, setProjectName] = useState('')
  const [description, setDescription] = useState('')
  const [techStack, setTechStack] = useState<string[]>([])
  const [industries, setIndustries] = useState<string[]>([])
  const [weeklyRate, setWeeklyRate] = useState<number>(0)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedClient, setSelectedClient] = useState<number>(0)
  const [selectedTeam, setSelectedTeam] = useState<number>(-1)
  const [currentTech, setCurrentTech] = useState('')
  const [currentIndustry, setCurrentIndustry] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (engagement) {
      setProjectName(engagement.projectName || '')
      setDescription(engagement.description || '')
      setTechStack(engagement.techStack || [])
      setIndustries(engagement.industries || [])
      setWeeklyRate(engagement.weeklyRate || 0)
      setStartDate(engagement.startDate ? new Date(engagement.startDate) : null)
      setEndDate(engagement.endDate ? new Date(engagement.endDate) : null)
      setSelectedClient(engagement.client?.id || 0)
      setSelectedTeam(engagement.team?.id || 0)
    }
  }, [engagement])

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!selectedTeam || !selectedClient || !startDate) return
    const args = {
      projectName: projectName.trim(),
      techStack,
      industries,
      weeklyRate,
      teamId: selectedTeam,
      clientId: selectedClient,
      startDate,
      endDate,
      companyId,
      description,
    }
    engagement
      ? handleEditSubmit &&
        handleEditSubmit({
          ...args,
          id: engagement.id,
        })
      : handleCreateSubmit &&
        handleCreateSubmit({
          ...args,
        })
  }

  const handleOnBack = () => {
    navigate(-1)
  }

  return (
    <>
      <form className="col-12" onSubmit={handleFormSubmit}>
        <div className="align-self-start mt-3">
          <p className="font-18 fw-bold">General Information</p>
          <div className="font-16 fw-bold">
            {'('}
            <p className="text-red d-inline">*</p>
            {')'}
            Required
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="engagementFormProjectNameInput"
              className="font-16 fw-semibold"
            >
              Project Name <p className="text-red d-inline">*</p>
            </label>
            <input
              type="text"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }  ${projectName && !displayErrorBanner ? 'input-success' : ''}`}
              id="engagementFormProjectNameInput"
              aria-describedby="projectNameHelp"
              value={projectName}
              onChange={e => {
                setProjectName(e.target.value)
              }}
              maxLength={50}
              aria-label="form-project-name-input"
            />
          </div>
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="engagementFormClientInput"
              className="font-16 fw-semibold timeoff-grey"
            >
              Client <p className="text-red d-inline">*</p>
            </label>
            <select
              className={`form-select ${
                selectedClient && !displayErrorBanner ? 'input-success' : ''
              }`}
              value={selectedClient}
              id="engagementFormClientInput"
              onChange={e => {
                setSelectedClient(parseInt(e.target.value))
              }}
            >
              <option hidden value={0}>
                Select a Client
              </option>
              {clients.map((client, index) => (
                <option key={`client-${index}`} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="engagementFormTeamInput"
              className="font-16 fw-semibold timeoff-grey"
            >
              Team <p className="text-red d-inline">*</p>
            </label>
            <select
              className={`form-select ${
                selectedTeam && !displayErrorBanner ? 'input-success' : ''
              }`}
              value={selectedTeam}
              id="engagementFormTeamInput"
              onChange={e => {
                if (startDate || endDate) {
                  setEndDate(null)
                  setStartDate(null)
                }
                setSelectedTeam(parseInt(e.target.value))
              }}
            >
              <option hidden value={0}>
                Select a Team
              </option>
              {teams
                .filter(team => {
                  if (
                    !team.endDate ||
                    moment().isSameOrBefore(moment(team.startDate))
                  )
                    return true

                  return moment().isBetween(
                    team.startDate,
                    team.endDate,
                    undefined,
                    '[]',
                  )
                })
                .map((team, index) => (
                  <option key={`team-${index}`} value={team.id}>
                    {team.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4 form-group py-3">
            <label className="font-16 fw-semibold timeoff-grey">
              Started On <p className="text-red d-inline">*</p>
            </label>
            <DatePicker
              className={`form-control timeoff-input ${
                startDate && !displayErrorBanner ? 'input-success' : ''
              }`}
              maxDate={endDate}
              selected={startDate}
              placeholderText={'Select a date'}
              onChange={date => {
                if (date) {
                  const newDate = moment(date)
                  if (selectedTeam === -1) {
                    toast.warning(`You must select a team before`)
                    return
                  }
                  const formsSelectedTeam = teams.find(
                    value => value.id === selectedTeam,
                  )
                  if (!formsSelectedTeam) return

                  const selectedTeamStartDate = moment(
                    formsSelectedTeam.startDate,
                  )
                  const selectedTeamEndDate = moment(formsSelectedTeam.endDate)

                  if (
                    (formsSelectedTeam.endDate &&
                      !newDate.isBetween(
                        selectedTeamStartDate,
                        selectedTeamEndDate,
                        undefined,
                        '[]',
                      )) ||
                    newDate.isBefore(selectedTeamStartDate)
                  ) {
                    toast.warning(
                      `Looks like team ${formsSelectedTeam.name} does not exist on the start date you selected`,
                    )
                    return
                  }

                  if (endDate && date > endDate) {
                    setEndDate(date)
                  }
                  setStartDate(date)
                }
              }}
            />
          </div>
          <div className="col-6 col-md-4 form-group py-3">
            <label className="font-16 fw-semibold timeoff-grey">
              Ended On <p className="text-red d-inline">*</p>
            </label>
            <DatePicker
              className={`form-control timeoff-input ${
                endDate && !displayErrorBanner ? 'input-success' : ''
              }`}
              selected={endDate}
              placeholderText={'Select a date'}
              minDate={startDate}
              onChange={date => {
                if (date) {
                  if (selectedTeam === -1) {
                    toast.warning(`You must select a team before`)
                    return
                  }
                  const newDate = moment(date)
                  const formsSelectedTeam = teams.find(
                    value => value.id === selectedTeam,
                  )
                  if (!formsSelectedTeam) return

                  const selectedTeamStartDate = moment(
                    formsSelectedTeam.startDate,
                  )
                  const selectedTeamEndDate = moment(formsSelectedTeam.endDate)
                  if (
                    formsSelectedTeam.endDate &&
                    !newDate.isBetween(
                      selectedTeamStartDate,
                      selectedTeamEndDate,
                      undefined,
                      '[]',
                    )
                  ) {
                    toast.warning(
                      `Looks like team ${formsSelectedTeam.name} does not exist on the end date you selected`,
                    )
                    return
                  }
                  if (!startDate) {
                    setEndDate(date)
                  } else if (date < startDate) {
                    setEndDate(startDate)
                  } else {
                    setEndDate(date)
                  }
                }
              }}
            />
          </div>
          <div className="col-12 col-md-4 form-group py-3">
            <label
              htmlFor="engagementFormWeeklyRateInput"
              className="font-16 fw-semibold"
            >
              Weekly Rate ($)
            </label>
            <input
              type="number"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }  ${weeklyRate && !displayErrorBanner ? 'input-success' : ''}`}
              step="0.01"
              min="0"
              id="engagementFormWeeklyRateInput"
              aria-describedby="WeeklyRateHelp"
              value={weeklyRate}
              onChange={e => {
                setWeeklyRate(parseFloat(e.target.value))
              }}
              aria-label="form-weekly-rate-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="engagementDescriptionInput"
              className="font-16 fw-semibold"
            >
              Description
            </label>
            <textarea
              placeholder="Add a Description (500 characters max)"
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="engagementTechStackInput"
              aria-describedby="descriptionHelp"
              value={description}
              onChange={e => {
                setDescription(e.target.value)
              }}
              rows={6}
              maxLength={500}
              aria-label="form-tech-stack-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="engagementTechStackInput"
              className="font-16 fw-semibold"
            >
              Tech Stack & Toolset (Type and press Enter to add a tag)
            </label>
            <input
              type="text"
              placeholder="e.g. Design Thinking, AWS, Scrum Master..."
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="engagementTechStackInput"
              aria-describedby="techStackHelp"
              value={currentTech}
              onChange={e => {
                setCurrentTech(e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  if (currentTech) {
                    setTechStack([
                      ...currentTech.split(',').filter(t => t),
                      ...techStack,
                    ])
                    setCurrentTech('')
                  }
                }
              }}
              maxLength={50}
              aria-label="form-tech-stack-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 row">
            {(techStack || []).map((tech, index) => (
              <button
                key={`tech-${index}`}
                className="col-auto pill-remove py-2 mb-2 mx-2 text-truncate"
                onClick={e => {
                  e.preventDefault()
                  setTechStack([
                    ...techStack.filter(newTech => newTech !== tech),
                  ])
                }}
              >
                <div className="d-inline mb-0 text-truncate">
                  <FontAwesomeIcon icon={faX} size="xs" className="me-1" />
                  {tech}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 form-group py-3">
            <label
              htmlFor="engagementIndustryInput"
              className="font-16 fw-semibold"
            >
              Industries (Type and press Enter to add a tag)
            </label>
            <input
              type="text"
              placeholder="e.g. Finance, Tech, Project Management..."
              className={`form-control timeoff-input ${
                displayErrorBanner ? 'error' : ''
              }`}
              id="engagementIndustryInput"
              aria-describedby="industriesHelp"
              value={currentIndustry}
              onChange={e => {
                setCurrentIndustry(e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  if (currentIndustry) {
                    setIndustries([
                      ...currentIndustry.split(',').filter(i => i),
                      ...industries,
                    ])
                    setCurrentIndustry('')
                  }
                }
              }}
              maxLength={50}
              aria-label="form-industries-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 row">
            {industries.map((industry, index) => (
              <button
                key={`industry-${index}`}
                className="col-auto pill-remove py-2 mb-2 mx-2 text-truncate"
                onClick={e => {
                  e.preventDefault()
                  setIndustries([
                    ...industries.filter(
                      newIndustry => newIndustry !== industry,
                    ),
                  ])
                }}
              >
                <div className="d-inline mb-0 text-truncate">
                  <FontAwesomeIcon icon={faX} size="xs" className="me-1" />
                  {industry}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="py-2 d-flex justify-content-center">
          <div>
            <button
              type="button"
              className="btn btn-secondary me-4"
              style={{ fontWeight: '500' }}
              onClick={handleOnBack}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary px-4"
              style={{ fontWeight: '500' }}
              disabled={
                !projectName.trim() ||
                !startDate ||
                !endDate ||
                !selectedClient ||
                !selectedTeam ||
                loading
              }
              aria-label="login-submit-button"
            >
              {engagement ? 'Save' : 'Add Engagement'}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
