import {
  Snapshot,
  SnapshotTeamUser,
  DatesSnapshotTeamUser,
  getSnapshotsByDateDto,
} from '../types/Snapshot'
import { getTeamByIdDto, Team } from '../types/Team'
import { timeoffApi } from './timeoff.api'

const teamEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    createTeam: build.mutation<
      Team,
      {
        name: string
        description: string
        isActive: boolean
        companyId: number
        tribeId: number
        clientId: number
        startDate: Date
        endDate: Date | undefined | null
      }
    >({
      query: data => ({
        url: `/team`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['TeamsData'],
    }),
    updateTeam: build.mutation<
      Team,
      {
        id: number
        name: string
        description: string
        isActive: boolean
        tribeId: number
        clientId: number
        startDate: Date
        endDate: Date | undefined | null
      }
    >({
      query: data => ({
        url: `/team/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['TeamsData'],
    }),
    getAllTeams: build.query<Team[], number>({
      query: companyId => `/team?companyId=${companyId}`,
      providesTags: ['TeamsData'],
    }),
    getSnapshots: build.query<SnapshotTeamUser[], number>({
      query: companyId => `/team/snapshots?companyId=${companyId}`,
      providesTags: ['SnapshotsData'],
    }),
    getTeamById: build.query<Team, getTeamByIdDto>({
      query: query => `/team/${query.companyId}/${query.id}`,
      providesTags: ['TeamsData'],
    }),
    deleteTeam: build.mutation<boolean, number>({
      query: id => ({
        url: `/team/soft-delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeamsData'],
    }),
    getDatesSnapshots: build.query<DatesSnapshotTeamUser[], number>({
      query: companyId => `/team/dates-snapshot?companyId=${companyId}`,
      providesTags: ['SnapshotsDates'],
    }),
    getSnapshotsFilteredByDate: build.query<
      SnapshotTeamUser[],
      getSnapshotsByDateDto
    >({
      query: query =>
        `/team/snapshots-by-date?companyId=${query.companyId}&startDate=${query.startDate}&endDate=${query.endDate}`,
      providesTags: ['SnapshotsData'],
    }),
  }),
})

export const {
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useGetAllTeamsQuery,
  useGetSnapshotsQuery,
  useGetTeamByIdQuery,
  useDeleteTeamMutation,
  useLazyGetTeamByIdQuery,
  useLazyGetAllTeamsQuery,
  useLazyGetSnapshotsQuery,
  useLazyGetDatesSnapshotsQuery,
  useLazyGetSnapshotsFilteredByDateQuery,
} = teamEndpoints
