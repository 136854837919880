import React from "react";

interface Props {
  tableHeaders: Array<string>;
  userCount: number;
}

export const ReportTableHeaders = ({tableHeaders, userCount}: Props) => {
  return (
    <thead>
      <tr>
        <th className="report-members-header">{`Members(${userCount})`}</th>
        {tableHeaders.map((header, index) => <th key={index}>{header}</th>)}
      </tr>
    </thead>
  )
}

