import React, { useEffect, useState } from 'react'
import moment from 'moment'
import '../../css/teamUtilizationTimeline.scss'
import Timeline, {
  TimelineHeaders,
  DateHeader,
  ReactCalendarItemRendererProps,
  TimelineGroupBase,
  ReactCalendarGroupRendererProps,
  SidebarHeader,
  TimelineItemBase,
} from 'react-calendar-timeline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';

export interface TeamUtilizationTimelineGroup extends TimelineGroupBase {
  type: 'tribe' | 'team' | 'Expand/Collapse' | 'openTeams' | 'devsInBench' | 'costOfBench' 
  bgColor?: string
  parentId?: string
}

export interface TeamUtilizationTimelineItemBase
  extends TimelineItemBase<number> {
  type: 'open' | 'openNotStaffed' | 'inProgress' | 'openTeams' | 'devsInBench' | 'costOfBench'
  bgColor?: string
  selectedBgColor?: string
  staffMember?: number
  engagementName?: string
}

interface Props {
  groups: TeamUtilizationTimelineGroup[]
  items: TeamUtilizationTimelineItemBase[]
  timelineStart: Date
  timelineEnd: Date
  timelineStartDefaultValue: Date
  timelineEndDefaultValue: Date
}

const AverageBenchCostTimeline = ({ groups, items, timelineStart, timelineEnd, timelineStartDefaultValue, timelineEndDefaultValue}: Props) => {
  const [timelineGroups, setTimelineGroup] = useState<
    TeamUtilizationTimelineGroup[]
  >([])
  const [timelineItems, setTimelineItems] = useState<
    TimelineItemBase<number>[]
  >([])

  const [visibleTimeStart, setVisibleTimeStart] = useState<Date>(moment().startOf('week').toDate())
  const [visibleTimeEnd, setVisibleTimeEnd] = useState<Date>(moment().add(65, 'day').endOf('week').toDate())
  const [openGroups, setOpenGroups] = useState<any>({})

  useEffect(() => {
    setTimelineGroup(groups)
    setTimelineItems(items)
  }, [groups, items])

  useEffect(() => {
    if(timelineStart && timelineEnd){
      setVisibleTimeStart(moment(timelineStart).startOf('week').toDate())
      setVisibleTimeEnd(moment(timelineEnd).startOf('week').toDate())
    }
  },[timelineStart, timelineEnd])

  const TooltipContent = ({ content }: { content: string }) => (
    <div className="item-tooltip">
      <span>
        <strong>
          <span style={{ fontSize: '16px' }}>{content}</span>
        </strong>
      </span>
    </div>
  )

  const itemRenderer = (reactCalendarProps: ReactCalendarItemRendererProps) => {
    const { itemContext, getItemProps } = reactCalendarProps
    const item = reactCalendarProps.item as TeamUtilizationTimelineItemBase
    const backgroundColor = itemContext.selected
      ? item.selectedBgColor
      : item.bgColor

    const timelineItem =         
    <div
      {...getItemProps({
        style: {
          backgroundColor,
        },
      })}
    >
      <div
        style={{
            height: itemContext.dimensions.height,
        }}
      >
      {itemContext.title}
      </div>
    </div>

    return (
      item.group.toString().match(/Team/) ?
      <Tippy
        content={
          <TooltipContent content={item.engagementName ?? "Open"} />
        }
      >
        {timelineItem}
      </Tippy> :
      timelineItem
    )
  }

  const groupRenderer = (
    reactCalendarGroupRendererProps: ReactCalendarGroupRendererProps<TeamUtilizationTimelineGroup>,
  ) => {
    const { group } = reactCalendarGroupRendererProps
      return (
        <div 
          className="d-flex flex-row justify-content-center align-items-center line lh-1 h-100 w-100 bg-white border-top"
          onClick={()=>{
              setOpenGroups({...openGroups, [group.id]: !openGroups[group.id]})
            }}
        >
          <div
            className={`d-flex flex-row justify-content-center rounded py-1 px-2`}
          >
            {group.type === "Expand/Collapse" ?
              <FontAwesomeIcon icon={openGroups[group.id] ? faChevronUp : faChevronDown} size="1x" /> : 
              <span className="group-title">{group.title}</span>
            }
          </div>
        </div>
      )  
  }

  const timelineHeaders = () => {

    const diffMonths = moment(visibleTimeEnd).diff(moment(visibleTimeStart), 'months');

    return (
    <TimelineHeaders>
      <SidebarHeader>
        {({ getRootProps }) => {
          return (
            <div className="sidebar-header" {...getRootProps()}>
              <div className="sidebar-header-years" style={{display: diffMonths <= 12 ? 'none' : 'flex'}}>Years</div>
              <div className="sidebar-header-months">Months</div>
              <div className="sidebar-header-weeks" style={{display: diffMonths <= 12 ? 'flex' : 'none', height: 30}}>Weeks</div>
            </div>
          )
        }}
      </SidebarHeader>
      <DateHeader
        unit="year"
        className="date-header-year"
        style={{display: diffMonths <= 12 ? 'none' : 'flex' }}
      />
      <DateHeader
        unit="month"
        className="date-header-month"
      />
      <DateHeader
        unit="week"
        className="date-header-week"
        style={{display: diffMonths <= 12 ? 'flex' : 'none', height: 30}}
        intervalRenderer={context => {
          const startTime = moment(
            context?.intervalContext.interval.startTime,
          )
          const week = startTime.format("ww")
          const isThisWeek = startTime.isSame(new Date(), 'week')

          return ( diffMonths <= 2 &&
            <div
              className={`rct-dateHeader ${isThisWeek ? 'this-week' : ''}`}
              {...context?.getIntervalProps({})}
            >
              <span className="day-span">{week}</span>
            </div>
          )
        }}
      />
    </TimelineHeaders>)
  }

  function getNewGroups(){
    return timelineGroups
      .filter(g => 
        g.type === "Expand/Collapse" || 
        g.type === "tribe" ||
        g.type === "team" ||
        openGroups[g.parentId ?? ""]
      ) 
  } 

  return (
    <div className="team-utilization-timeline">
      {timelineGroups.length > 0 ? (
        <Timeline
          groups={getNewGroups()}
          items={timelineItems}
          itemTouchSendsClick={false}
          canMove={false}
          canResize={false}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          itemRenderer={itemRenderer}
          groupRenderer={groupRenderer}
          maxZoom={moment.duration(65, 'days').asMilliseconds()}
          minZoom={moment.duration(65, 'days').asMilliseconds()}
          lineHeight={40}
          sidebarWidth={150}
          itemHeightRatio={0.8}
          onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
              if(visibleTimeStart && visibleTimeEnd) updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
              if (timelineStart.valueOf() === timelineStartDefaultValue.valueOf() && timelineEnd.valueOf() === timelineEndDefaultValue.valueOf()) {
                if(visibleTimeStart) setVisibleTimeStart(new Date(visibleTimeStart))
                if(visibleTimeEnd) setVisibleTimeEnd(new Date(visibleTimeEnd))
              }
            }
          }
        >
        {timelineHeaders()}
        </Timeline>
      ) : (
        <></>
      )}
    </div>
  )
}

export default AverageBenchCostTimeline