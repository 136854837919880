import React, { useEffect, useState } from 'react'
import { LeadershipGroup, Member } from '../../types/Tribe'
import ProjectLeadershipContainer from './TribeProjectComponents/ProjectLeadershipContainer'
import { leadershipDefaultValues } from '../../utils/tribeOrgDefaultValues'
import { AllGuildTypes, Role } from '../../types/Roles'
import { useLazyGetAllRolesQuery } from '../../redux/role.endpoints'

interface Props {
  leadership: LeadershipGroup
}

const TribeLeadershipContainer = ({ leadership }: Props) => {
  const [getAllRoles, getAllRolesResponse] = useLazyGetAllRolesQuery()
  const [guilds, setGuilds] = useState<Array<Role>>([])

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    if (getAllRolesResponse && getAllRolesResponse.data) {
      setGuilds(getAllRolesResponse.data)
    }
  }, [getAllRolesResponse])

  return (
    <div className="tribe-leadership-container">
      <ProjectLeadershipContainer role={AllGuildTypes.ADM} leaders={leadership[guilds.find(guild=>guild.type===AllGuildTypes.ADM)?.id??-1] || [leadershipDefaultValues[AllGuildTypes.ADM]]} guildName={guilds.find(guild=>guild.type === AllGuildTypes.ADM)?.role??""}/>
      <ProjectLeadershipContainer role={AllGuildTypes.BA} leaders={leadership[guilds.find(guild=>guild.type===AllGuildTypes.BA)?.id??-1] || [leadershipDefaultValues[AllGuildTypes.BA]]} guildName={guilds.find(guild=>guild.type === AllGuildTypes.BA)?.role??""}/>
      <ProjectLeadershipContainer role={AllGuildTypes.SM} leaders={leadership[guilds.find(guild=>guild.type===AllGuildTypes.SM)?.id??-1] || [leadershipDefaultValues[AllGuildTypes.SM]]} guildName={guilds.find(guild=>guild.type === AllGuildTypes.SM)?.role??""}/>
    </div>
  )
}

export default TribeLeadershipContainer
