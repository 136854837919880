/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { isDesktop, isTablet } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EmployeeForm } from '../../components/EmployeeForm/EmployeeForm'
import Loader from '../../components/Loader/Loader'
import { useGetAllTeamsQuery } from '../../redux/team.endpoints'
import { useGetAllTribesQuery } from '../../redux/tribe.endpoints'
import {
  useEditUserMutation,
  useGetUserByIdQuery,
} from '../../redux/user.endpoints'
import { EditUserDto } from '../../types/User'
import { BackendError } from '../../types/Errors'

import { Engagement } from '../../types/Engagement'
import { useLazyGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { useLazyGetAllExperienceLevelQuery, useLazyGetAllTechnologiesQuery, useLazyGetUserTechStackByUserIdQuery } from '../../redux/techstack.endpoints'
import { TechStackList } from '../../types/TechStacks'

interface Props {
  companyId: number
}

const EditEmployee = ({ companyId }: Props) => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const [engagements, setEngagements] = useState([] as Engagement[])
  const [editUser, editUserResult] = useEditUserMutation()
  const { data: user, isSuccess } = useGetUserByIdQuery(userId || '', {
    skip: !userId,
  })
  const { data: tribes, isSuccess: tribesSuccess } = useGetAllTribesQuery({
    companyId,
    pageSize: 0,
    pageNumber: 0,
    sortBy: 'id',
    searchQuery: '',
    sortType: 'DESC',
  })
  const { data: teams, isSuccess: teamsSuccess } =
    useGetAllTeamsQuery(companyId)
  const [getUserTechStackByUserId, GetUserTechStackByUserIdResponse] = useLazyGetUserTechStackByUserIdQuery();
  const [selectedTechStackList, setSelectedTechStackList] = useState<TechStackList[]>([])
  
  useEffect(() => {
    if (!userId) {
      navigate('/employees')
    }else{
      getUserTechStackByUserId({userId})
    }
  }, [userId])

  const [getEngagements, { data, isSuccess: engagementsSuccess }] =
    useLazyGetAllEngagementsQuery()
  const [getAllExperienceLevel, getAllExperienceLevelResponse] = useLazyGetAllExperienceLevelQuery();
  const [getAllTechnologies, getAllTechnologiesResponse] = useLazyGetAllTechnologiesQuery();

  useEffect(() => {
    if (data && data !== engagements) {
      setEngagements(data)
    }
  }, [data])

  useEffect(() => {
    getEngagements({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    getAllExperienceLevel({
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      sortType: 'DESC',
      search: '',
    });
    getAllTechnologies({
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      sortType: 'DESC',
      search: '',
    });
  }, [])

  useEffect(() => {
    if (editUserResult && editUserResult.isError) {
      const editUserError = editUserResult.error as BackendError
      if (editUserError.data.error) {
        toast.error(editUserError.data.error, {
          toastId: 'edit-user-error',
        })
      } else {
        toast.error(`An error occurred when updating member.`, {
          toastId: 'edit-user-error',
        })
      }
    } else if (editUserResult && editUserResult.isSuccess) {
      toast.success(`Member updated successfully!`, {
        toastId: 'edit-user-success',
      })
      navigate('/members')
    }
  }, [editUserResult])

  const handleEditUser = async (args: EditUserDto) => {
    editUser(args)
  }

  useEffect(() => {
    if(GetUserTechStackByUserIdResponse && GetUserTechStackByUserIdResponse.data) {
      const selectedTechStackOfUser = GetUserTechStackByUserIdResponse.data.map((techStack: any) => {
        return {
          idTechnology: techStack.technology.id,
          idExperienceLevel: techStack.experienceLevel.id
        }
      });
      setSelectedTechStackList(selectedTechStackOfUser);
    }
  }, [getAllExperienceLevelResponse, getAllTechnologiesResponse, getUserTechStackByUserId]);

  return (
    <>
      <Helmet>
        <title>Time Off - Edit Member</title>
      </Helmet>
      <main className="container-fluid scrollable">
        <div className={`row pt-3 ${isDesktop || isTablet ? 'h-100' : ''}`}>
          <div className="col-8 offset-2 d-flex flex-column align-items-center justify-content-center bg-lazuli-white pb-5">
            <p className="align-self-start font-30 fw-bold">Edit Member</p>
            {tribesSuccess &&
            teamsSuccess &&
            isSuccess &&
            engagementsSuccess ? (
              <EmployeeForm
                handleEditSubmit={handleEditUser}
                displayErrorBanner={editUserResult && editUserResult.isError}
                loading={editUserResult && editUserResult.isLoading}
                companyId={companyId}
                user={user}
                tribes={tribes || []}
                teams={teams || []}
                engagements={engagements || []}
                technologyList={getAllTechnologiesResponse.data || []}
                experienceLevel={getAllExperienceLevelResponse.data || []}
                selectedTechStackListByUser={selectedTechStackList || []}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default EditEmployee
