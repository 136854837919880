import React from 'react'

interface Props {
    fitContainer?: boolean
}

const Loader : React.FunctionComponent = ({ fitContainer }: Props) => {
    return (
        <div
            className={`w-100 ${
                fitContainer ? 'h-100' : 'full-height'
            } d-flex justify-content-center align-items-center`}
        >
            <div className="spinner-border renolink-spinner" role="status">
                <span className="visually-hidden" aria-label="loader">
                    Loading...
                </span>
            </div>
        </div>
    )
}

export default Loader
