import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { User } from '../../types/User'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TeamUtilization from './TeamUtilization'
import AverageBenchCost from './AverageBenchCost'
import usePersistentState from '../../hooks/usePersistentState'
import '../../css/teamUtilizationBase.scss'

interface Props {
  profile: User
  companyId: number
}

const TeamUtilizationBase = ({ profile, companyId }: Props) => {
  const [key, setKey] = usePersistentState(
    'TeamUtilizationBase_lastViewedIndex',
    0,
  )

  const handleTabSelect = (selectedKey: string | null) => {
    setKey(selectedKey ? parseInt(selectedKey) : -1)
  }

  const activeTabContent = useMemo(() => {
    switch (key) {
      case 0:
        return <TeamUtilization profile={profile} companyId={companyId} />
      case 1:
        return <AverageBenchCost 
          profile={profile} 
          companyId={companyId}
          generalTitle="Average Bench Cost"
          dashboardTitle="Cost Summary"
          totalOrganizationTitle="Delivery Org Bench Cost"
          totalPerTribeNumberTitle="Cost Per Tribe"
          tableTitle="Costs Per Week"
          selectedKey={key}
        />
      case 2:
        return <AverageBenchCost
          profile={profile}
          companyId={companyId} 
          generalTitle="Engagement Revenue"
          dashboardTitle="Revenue Summary"
          totalOrganizationTitle="Delivery Org Revenue"
          totalPerTribeNumberTitle="Revenue per Tribe"
          tableTitle="Revenue per Week"
          selectedKey={key}
        />
      case 3:
        return <AverageBenchCost 
          profile={profile} 
          companyId={companyId} 
          generalTitle="NET Revenue Forecast"
          dashboardTitle="NET Revenue Summary"
          totalOrganizationTitle="Delivery Org NET Revenue"
          totalPerTribeNumberTitle="NET Revenue per Tribe"
          tableTitle="NET Revenue per Week"
          selectedKey={key}
        />
      default:
        return null
    }
  }, [key, profile, companyId])

  return (
    <div className="container my-4 min-height">
      <Helmet title="Time Off - Team Utilization" />
      <div>
        <Link
          className="no-decoration normal-link"
          style={{ color: '#6C757D' }}
          to="/"
        >
          Home{' '}
        </Link>
        /
        <Link
          className="no-decoration normal-link"
          style={{ color: '#6C757D' }}
          to="/teamUtilization"
        >
          {' '}
          Team Utilization{' '}
        </Link>
      </div>
      <h1 className="mt-4">Team Utilization</h1>
      <Tabs
        id="team-utilization-tabs"
        className="mb-3 tabs-class"
        onSelect={handleTabSelect}
        activeKey={key.toString()}
        unmountOnExit={true}
      >
        <Tab eventKey="0" title="Team Utilization Over Time">
          {activeTabContent}
        </Tab>
        <Tab eventKey="1" title="Average Bench Cost">
          {activeTabContent}
        </Tab>
        <Tab eventKey="2" title="Engagement Revenue">
          {activeTabContent}
        </Tab>
        <Tab eventKey="3" title="NET Revenue Forecast">
          {activeTabContent}
        </Tab>
      </Tabs>
    </div>
  )
}

export default TeamUtilizationBase
