import { BaseGetForTablePayload } from '../types/Common'
import {
  CreateEngagementDto,
  Engagement,
  GetEngagementByIdDto,
  UpdateEngagementDto,
} from '../types/Engagement'
import { timeoffApi } from './timeoff.api'

const engagementEndpoints = timeoffApi.injectEndpoints({
  endpoints: build => ({
    createEngagement: build.mutation<Engagement, CreateEngagementDto>({
      query: data => ({
        url: `/engagement`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['EngagementsData'],
    }),
    updateEngagement: build.mutation<Engagement, UpdateEngagementDto>({
      query: data => ({
        url: `/engagement/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['EngagementsData'],
    }),
    getAllEngagements: build.query<Engagement[], BaseGetForTablePayload>({
      query: payload =>
        `/engagement?pageSize=10&pageNumber=${payload.pageNumber}&sortBy=${payload.sortBy}&sortType=${payload.sortType}&search=${payload.searchQuery}&companyId=${payload.companyId}`,
      providesTags: ['EngagementsData'],
    }),
    getEngagementById: build.query<Engagement, GetEngagementByIdDto>({
      query: query => `/engagement/${query.companyId}/${query.id}`,
      providesTags: ['EngagementsData'],
    }),
  }),
})

export const {
  useCreateEngagementMutation,
  useUpdateEngagementMutation,
  useGetAllEngagementsQuery,
  useGetEngagementByIdQuery,
  useLazyGetEngagementByIdQuery,
  useLazyGetAllEngagementsQuery,
} = engagementEndpoints
